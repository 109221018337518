import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { v4 as uuidv4 } from "uuid";

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import errorIcon from '@/media/airplane_crash_icon.png';

import styles from './ReportIssueModal.module.scss';
import { updateIsModalShown } from '../../Buttons/ReportIssueButton/reportIssueSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowLeft, faArrowRight, faArrowRightFromBracket, faArrowTurnDown, faBan, faBox, faBurst, faCheckCircle, faCircleDot, faCircleExclamation, faEllipsis, faPaperPlane, faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ReportPad } from './components/ReportPad';
import { IconButton } from '../../Buttons/iconButton/IconButton';
import { hubType } from '@/app/stores/hubSlice';
import { postCreateAlertData, usePostCreateAlertMutation } from '@/features/api/copApiEndpoints/alertsAPI';
import DelayingAppearance from '../LoadingUI';
import { Input, Textarea } from '@mui/joy';

type reportItem = {
    icon: IconDefinition;
    name: string;
    action: (props: any) => void;
}
type apiCallStatus = 'ERROR' | 'SUCCESS' | 'LOADING' | undefined;

export default function ReportIssueModal({
    open,
    handleClose,
    issue,
  }: {
    open: boolean;
    handleClose: () => void;
    issue: 'Hub' | 'Checklist';
  }) {
    const mainState = useAppSelector((state) => state.main)
    const hubState = useAppSelector((state) => state.hub)
    const groundHandlingState = useAppSelector((state) => state.groundHandling)
    const [showHub, setShowHub] = useState<boolean>(false);  
    const [showTextArea, setShowTextArea] = useState<boolean>(false);  
    const [displayResult, setDisplayResult] = useState<apiCallStatus>(undefined);  
    const [selectedHub, setSelectedHub] = useState<string | null | undefined>(mainState.whoAmI?.defaultHubId);  
    const [selectedIssue, setSelectedIssue] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [postCreateAlert, postCreateAlertResult] = usePostCreateAlertMutation();

    // Display result after submission
    useEffect(() => {
      if (postCreateAlertResult.isError) { setDisplayResult('ERROR') } 
      else if (postCreateAlertResult.isLoading) { setDisplayResult('LOADING') }
      else if (postCreateAlertResult.isSuccess) { setDisplayResult('SUCCESS') }
    }, [postCreateAlertResult]);

    // List of potential issues
    const landingPadOption = {
        icon: faBan,
        name: 'Landing pad',
        action: () => {
        // subscribeToPushNotifications();
        },
    };
    const accidentOption = {
        icon: faBurst,
        name: 'Air accident',
        action: () => {
        // subscribeToPushNotifications();
        },
    };
    const payloadOption = {
        icon: faBox,
        name: 'Payload',
        action: () => {
        // subscribeToPushNotifications();
        },
    };
    const otherOption = {
      icon: faEllipsis,
      name: 'Other',
      action: () => {
      // subscribeToPushNotifications();
      },
  };
    const reportItems: reportItem[] = [landingPadOption, accidentOption, payloadOption, otherOption];
    // Function to chunk the list into subarrays of two items each
    const chunkArray = (arr: hubType[] | reportItem[], chunkSize: number) => {
      const chunkedArray: any[][] = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          chunkedArray.push(arr.slice(i, i + chunkSize));
      }
      return chunkedArray;
    };
    // Chunk the list into subarrays with two items each
    const chunkedReportItems = chunkArray(reportItems, 2);
    const chunkedHubsArray = chunkArray(hubState.hubList, 2);
    // Set the selected issue and show the hub selection panel
    const onIssueClick = (item: reportItem) => {
      setShowHub(true)
      setSelectedIssue(item.name)
    }
    // Submit the alert through API call
    const handleSubmit = () => {
      const body: postCreateAlertData = {
        hubId: selectedHub ?? '',
        type: selectedIssue ?? '',
        message: message ?? '',
      }
      postCreateAlert(body)
    }
    const handleChecklistSubmit = () => {
      const currentChecklist = groundHandlingState.ongoingGroundHandlingOperation.index.currentList;
      const currentTask = groundHandlingState.ongoingGroundHandlingOperation.index.currentTask;
      const body: postCreateAlertData = {
        hubId: selectedHub ?? '',
        type: selectedIssue ?? '',
        orderId: groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId,
        message: message ?? '',
      }
      postCreateAlert(body)
    }

    if (issue == 'Hub') {
      return (
        <Modal
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            {(displayResult == undefined) && <div className={styles.reportIssueModal}>
              <div className={styles.header}>
                {!showHub && <div style={{color: 'rgb(255,105,85)'}}>What happens ?</div>}
                {showHub && <div style={{display: 'flex', flexDirection:'column', gap: '15px'}}> <div style={{color: 'rgb(255,105,85)' }}>At which hub ?</div>  <div style={{fontSize:'12px', color: 'rgb(6, 95, 217)' }}> Type of issue : {selectedIssue} </div> </div>}
              </div>
              <div className={styles.content}>
                  {!showHub ? (
                    <div style={{ display: 'flex', flexDirection:'column', gap: '15px' }}>
                      {chunkedReportItems.map((row: reportItem[], rowIndex: number) => (
                        <div key={rowIndex} style={{ display: 'flex', gap: '20px' }}>
                          {row.map((item: reportItem, itemIndex: number) => (
                          <div key={itemIndex}>
                            {item.name == selectedIssue ? (
                              <div style={{ position: 'relative' }}>
                                <div key={uuidv4()} className={styles.selectedHub}> 
                                  <FontAwesomeIcon size='1x' icon={item.icon}/>
                                  <div className={styles.card_name}> {item.name} </div>
                                </div>
                                <FontAwesomeIcon style={{position:'absolute', left:'85%', top:'6px', color:'rgb(6, 95, 217)'}} size='1x' icon={faCheckCircle}/>
                              </div>
                            ):(
                              <div key={uuidv4()} className={styles.reportCard} onClick={() => setSelectedIssue(item.name)}> 
                                <FontAwesomeIcon size='1x' icon={item.icon}/>
                                <div className={styles.card_name}> {item.name} </div>
                              </div>
                            )}       
                          </div>
                          ))}
                        </div>
                      ))}
                      <div style={{borderTop: '1px solid rgb(200, 200, 200)', display: 'flex', flexDirection:'column', gap: '10px'}}>
                        <div style={{paddingTop: '15px', fontSize: '12px', color: 'rgb(117, 117, 117)'}}>Add description (optional)</div>
                        <Textarea onChange={(event) => setMessage(event?.target.value)} minRows={1} maxRows={3} sx={{fontSize: '12px'}}/>
                      </div>
                    </div>
                  ):(
                    <div style={{ display: 'flex', flexDirection:'column', gap: '15px' }}>
                      {chunkedHubsArray.map((row: hubType[], rowIndex: number) => (
                        <div key={uuidv4()} style={{ display: 'flex', gap: '20px' }}>
                          {row.map((item: hubType, itemIndex: number) => (
                            <>
                              {item.id == selectedHub ? (
                                <div style={{ position: 'relative' }}>
                                  <div key={uuidv4()} className={styles.selectedHub}> {item.name} </div>
                                  <FontAwesomeIcon style={{position:'absolute', left:'85%', top:'6px', color:'rgb(6, 95, 217)'}} size='1x' icon={faCheckCircle}/>
                                </div>
                              ):(
                                <div key={uuidv4()} className={styles.reportCard} onClick={() => setSelectedHub(item.id)}> {item.name} </div>
                              )}       
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
              </div>
              <div className={styles.footer}>
                {!showHub ? 
                  (
                    <>
                      <IconButton
                        icon={faArrowRightFromBracket}
                        content='Exit'
                        onClick={handleClose}
                        noBorder={true}
                        rotate='rotate180'
                        color='unset_blue'
                      />
                      <IconButton
                        icon={faArrowRight}
                        content='Next'
                        onClick={() => setShowHub(true)}
                        reversed={true}
                        disabled={selectedIssue == undefined}
                      />
                    </>
                  ):(
                    <>
                      <IconButton
                        icon={faArrowLeft}
                        content='Previous'
                        onClick={() => setShowHub(false)}
                        noBorder={true}
                        color='unset_blue'
                      />
                      <IconButton
                        icon={faPaperPlane}
                        content='Submit'
                        onClick={handleSubmit}
                        reversed={true}
                      />
                    </>
                  )
                }
              </div>
            </div>}
            {(displayResult == 'SUCCESS') && <div className={styles.reportIssueSuccess}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faCheckCircle} size='xl'/> Alert succesfully placed !
              </div>
              <div className={styles.footer}>
                < IconButton
                  icon={faXmark}
                  content='Close'
                  onClick={handleClose}
                  reversed={true}
                />
              </div>
            </div>}
            {(displayResult == 'LOADING') && <div className={styles.reportIssueLoading}>
              <DelayingAppearance color='rgb(6, 95, 217)' />
            </div>}
            {(displayResult == 'ERROR') && <div className={styles.reportIssueError}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faCircleExclamation} size='xl'/> Something went wrong...
              </div>
              <div className={styles.footer}>
                < IconButton
                  icon={faRotateRight}
                  content='Try again'
                  onClick={handleClose}
                  reversed={true}
                />
              </div>
            </div>}
          </>
        </Modal>
      );
    } else {
      return (
        <Modal
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            {(displayResult == undefined) && <div className={styles.reportIssueModal}>
              <div className={styles.header} style={{color: 'rgb(255,105,85)'}}>
                Alert submission !
              </div>
              <div className={styles.content}>
                <div style={{ display: 'flex', flexDirection:'column', gap: '15px' }}>
                  {chunkedReportItems.map((row: reportItem[], rowIndex: number) => (
                        <div key={uuidv4()} style={{ display: 'flex', gap: '20px' }}>
                          {row.map((item: reportItem, itemIndex: number) => (
                            <>
                              {item.name == selectedIssue ? (
                                <div style={{ position: 'relative' }}>
                                  <div key={uuidv4()} className={styles.selectedHub}> 
                                    <FontAwesomeIcon size='1x' icon={item.icon}/>
                                    <div className={styles.card_name}> {item.name} </div>
                                  </div>
                                  <FontAwesomeIcon style={{position:'absolute', left:'85%', top:'6px', color:'rgb(6, 95, 217)'}} size='1x' icon={faCheckCircle}/>
                                </div>
                              ):(
                                <div key={uuidv4()} className={styles.reportCard} onClick={() => setSelectedIssue(item.name)}> 
                                  <FontAwesomeIcon size='1x' icon={item.icon}/>
                                  <div className={styles.card_name}> {item.name} </div>
                                </div>
                              )}       
                            </>
                          ))}
                        </div>
                  ))}
                  <div style={{borderTop: '1px solid rgb(200, 200, 200)', display: 'flex', flexDirection:'column', gap: '10px'}}>
                    <div style={{paddingTop: '15px', fontSize: '12px', color: 'rgb(117, 117, 117)'}}>Add description (optional)</div>
                    <Textarea onChange={(event) => setMessage(event?.target.value)} minRows={1} maxRows={3} sx={{fontSize: '12px'}}/>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <IconButton
                  icon={faArrowRightFromBracket}
                  content='Exit'
                  onClick={handleClose}
                  noBorder={true}
                  rotate='rotate180'
                  color='unset_blue'
                />
                < IconButton
                  icon={faPaperPlane}
                  content='Submit'
                  onClick={handleChecklistSubmit}
                  reversed={true}
                />
              </div>
            </div>}
            {(displayResult == 'SUCCESS') && <div className={styles.reportIssueSuccess}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faCheckCircle} size='xl'/> Alert succesfully placed !
              </div>
              <div className={styles.footer}>
                < IconButton
                  icon={faXmark}
                  content='Close'
                  onClick={handleClose}
                  reversed={true}
                />
              </div>
            </div>}
            {(displayResult == 'LOADING') && <div className={styles.reportIssueLoading}>
              <DelayingAppearance color='rgb(6, 95, 217)' />
            </div>}
            {(displayResult == 'ERROR') && <div className={styles.reportIssueError}>
              <div className={styles.header}>
                <FontAwesomeIcon icon={faCircleExclamation} size='xl'/> Something went wrong...
              </div>
              <div className={styles.footer}>
                < IconButton
                  icon={faRotateRight}
                  content='Try again'
                  onClick={handleClose}
                  reversed={true}
                />
              </div>
            </div>}
          </>
        </Modal>
      );
    }
  }
