import React from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';

export function Reporting() {
  return (
    <MainLayout>
      <p>Reporting page</p>
    </MainLayout>
  );
}
