export function mapGroundHandlingOperationType (type: string): string {

    switch (type) {
        case "START_DELIVERY":
            return "Cargo flight preparation";
        case "END_DELIVERY":
            return "Cargo flight completion";
        case "START_EMPTY":
            return "Empty flight preparation";
        case "END_EMPTY":
            return "Empty flight completion";
        default:
            return "";
    }
}