import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from 'react-spring';

import { useAppSelector } from '@/app/hooks';
import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { IconButton } from '../../sharedComponents/Buttons/iconButton/IconButton';
import { ProductOgcTab } from './components/productTab/ProductOgcTab';
import AddProductCard from './components/addProduct/AddProductCard';

// Styles
import styles from './Products.module.scss';

export function Products() {
  const productState = useAppSelector((state) => state.product);

  const [isAddProductOpen, setIsAddProductOpen] = useState(false);

  // Define button props
  const handleClick = async () => {
    setIsAddProductOpen(true);
  };

  const props3 = useSpring({
    // config: { duration: 500, easing: (t) => t * (2 - t) },
    // from: {opacity: 0, marginTop: -10000 },
    // to: { opacity: 1, marginTop: 0 },
    // transition: '0.5s',
    opacity: isAddProductOpen ? 1 : 0,
    transform: `translateX(${isAddProductOpen ? 0 : '-100%'})`,
    // transform: isAddProductOpen ? 'scale(1)' : 'scale(0)',
    config: { tension: 200, friction: 15 },
  });
  

  return (
    <MainLayout>
      <div className={styles.body}>
        <div className={`${styles.functionBar} ${isAddProductOpen ? styles.disabled : ''}`}>
          <IconButton
            icon={faPlus}
            content="Add product"
            onClick={handleClick}
          />
        </div>
        <animated.div
            className={styles.addProductCardWrapper}
            style={props3}
          >
          { isAddProductOpen && (
            <AddProductCard
              open={isAddProductOpen}
              onClose={() => setIsAddProductOpen(false)}
            />
          )}
        </animated.div>
        <div className={`${styles.productTable} ${isAddProductOpen ? styles.disabled : ''}`}>
          <ProductOgcTab title="Product type" dataSet={productState.productList} />
        </div>
      </div>
    </MainLayout>
  );
}


{/* <MainLayout>
      <div className={styles.body}>
        <div className={`${styles.functionBar} ${isAddProductOpen ? styles.disabled : ''}`}>
          <IconButton
            icon={faPlus}
            content="Add product"
            onClick={handleClick}
          />
        </div>
        <div
          className={`${styles.addProductCardWrapper} ${
            isAddProductOpen ? styles.open : styles.closed
          }`}
        >
          <AddProductCard
            open={isAddProductOpen}
            onClose={() => setIsAddProductOpen(false)}
          />
        </div>
        <div className={`${styles.productTable} ${isAddProductOpen ? styles.disabled : ''}`}>
          <ProductTab title="Product type" dataSet={productState.productList} />
        </div>
      </div>
    </MainLayout> */}