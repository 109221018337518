import { productType_ogc } from "@/app/stores/productSlice";
import { PayloadReturn } from "../api/copApiEndpoints/payloadAPI";

type packagesType = {
    packaging?: {
        id: string;
    };
    content?: {
        type: string;
        id: string;
    };
}[];

const productMapping = (payload: PayloadReturn | undefined ) => {
    const productDetails: { productId: string, name: string, count: number, container: string }[] = [];

    payload?.items.forEach((item) => {
        productDetails.push({productId: item.product.id, name: item.product.name, count: item.amount, container: item.containerType.name})
    });
    
    return productDetails;
}


export default productMapping;