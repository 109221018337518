import React from 'react';
import { Box, styled } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridSortModel,
} from '@mui/x-data-grid';

// Styles
import styles from './ModalTable.module.scss';

// Overlay when there is no deliveries to display
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay({ message = 'No data' }: { message: string }) {
  return (
    <StyledGridOverlay>
      <Box
        sx={{
          mt: 1,
          fontFamily: styles.headerFontFamily,
          fontSize: styles.overlayFontSize,
        }}
      >
        {message}
      </Box>
    </StyledGridOverlay>
  );
}

// Style adaptation of the column menu
const StyledGridColumnMenu = styled(GridColumnMenu)({
  background: 'white',
  color: 'black',
  borderRadius: 4,
  border: 'solid',
  borderWidth: 1,
  borderColor: styles.panelBorderColor,
});

export function CustomColumnMenuComponent(props: GridColumnMenuProps) {
  const { hideMenu, currentColumn, color, ...other } = props;
  return (
    <StyledGridColumnMenu
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      {...other}
    />
  );
}

// Type definitions
export type dataType = {};
export type TableProps = {
  columns: GridColDef[];
  data: dataType[];
  colTools: boolean;
  toolbar?: React.JSXElementConstructor<any> | null;
  tableHeight?: string;
  initState: GridSortModel | undefined;
  handleMouseEnterRow?: (e: any) => void;
  handleMouseLeaveRow?: (e: any) => void;
  handleChooseRow?: (e: any) => void;
};

// Component function
export function ModalTable(props: TableProps) {
  const {
    columns,
    data,
    colTools,
    toolbar,
    tableHeight,
    initState,
    handleMouseEnterRow,
    handleMouseLeaveRow,
    handleChooseRow,
  } = props;

  return (
    <Box
      height={tableHeight ? tableHeight : 'auto'}
      width="100%"
      padding="0px 0.001px"
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
          color: styles.textColor,
        },
      }}
    >
      <DataGrid
        autoHeight={tableHeight ? false : true}
        rows={data}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: initState,
          },
        }}
        hideFooter
        headerHeight={30}
        components={{
          Toolbar: toolbar,
          ColumnMenu: CustomColumnMenuComponent,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        columnVisibilityModel={{
          // Hide column id, the other columns will remain visible
          id: false,
        }}
        disableColumnMenu={colTools}
        disableColumnFilter={false}
        disableColumnSelector={false}
        disableSelectionOnClick={true}
        sx={{
          '& .MuiInputBase-root': {
            fontFamily: styles.headerFontFamily,
            color: styles.textColor,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
            fontSize: styles.headerFontSize,
            fontFamily: styles.headerFontFamily,
          },
          '& .MuiDataGrid-columnHeader: focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
          '& .MuiDataGrid-virtualScroller': {
            fontFamily: styles.rowFontFamily,
          },
          '& .MuiDataGrid-row': {
            borderRadius: styles.rowBorderRadius,
            // fontSize: styles.rowFontSize,
          },
          '& .MuiDataGrid-row: hover': { backgroundColor: styles.rowBgColor },
          '& .MuiDataGrid-cell': { border: 'none' },
          '& .MuiDataGrid-cell: focus-within': { outline: 'none !important' },
        }}
        componentsProps={{
          row: {
            onMouseEnter: handleMouseEnterRow,
            onMouseLeave: handleMouseLeaveRow,
            onClick: handleChooseRow,
          },
        }}
      />
    </Box>
  );
}
