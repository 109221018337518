import React from 'react';
import Modal from '@mui/material/Modal';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateError } from '@/app/mainSlice';
import errorIcon from '@/media/airplane_crash_icon.png';

import styles from './ErrorModal.module.scss';

// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function ErrorModal(props: { props_BasicModal: string }) {
  const mainState = useAppSelector((state) => state.main);
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(updateError({ id: props.props_BasicModal, value: false }));

  switch (props.props_BasicModal) {
    case '1': {
      return (
        <Modal
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={mainState.error.error_api.value}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={styles.errorModal}>
            <div className={styles.header}>
              <img src={errorIcon} width="250px" alt="" />
              Something went wrong
            </div>
            <div className={styles.content}>
              <div id={styles.p}> But don't worry, it is not a crash ! </div>
              Please refresh the page or contact the customer support team.
            </div>
            <div className={styles.footer}>
              Error code: {props.props_BasicModal}
            </div>
          </div>
        </Modal>
      );
    }
    default: {
      return <div></div>;
    }
  }
}
