import { GroundHandlingOperation } from "@/app/stores/dataSlice/groundHandlingSlice";
import { FillingPatternReturn, GroundOperationReturn } from "../copApiEndpoints/groundHandlingAPI";
import { FillingPattern } from "@/app/stores/dataSlice/groundHandlingSlice";


export function getGroundOperationAdapterAPI(data: GroundOperationReturn[]): GroundHandlingOperation[] {
    // This function maps the fetched data from getGroundHandlingOperations to the redux state groundHandlingOperations.
    return (data.map((item: GroundOperationReturn) => {
        return {
            type: item.groundHandlingType,
            startTime: item.groundHandlingStartTime,
            scheduledTime: item.scheduledGroundHandlingTime,
            orderId: item.orderId,
            orderRef: item.orderReference,
            droneId: item.drone,
            packages: item.packages,
            departureHubId: item.otherHub,
        };
      })
    );
}

export function getFillingPatternAdapterAPI(data: FillingPatternReturn[]): FillingPattern {

  const positionValues = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8'];
  const productLocation: FillingPattern = [];
  for (const position of positionValues) {
    productLocation.push({position: position, id: undefined, empty: true, color: ''});
  }

  data.map((item: FillingPatternReturn) => {
    switch (item.location) {
      case "A1":
        productLocation[0].id = item.productId;
        productLocation[0].empty = false;
        break;
      case "A2":
        productLocation[1].id = item.productId;
        productLocation[1].empty = false;
        break;
      case "A3":
        productLocation[2].id = item.productId;
        productLocation[2].empty = false;
        break;
      case "A4":
        productLocation[3].id = item.productId;
        productLocation[3].empty = false;
        break;
      case "A5":
        productLocation[4].id = item.productId;
        productLocation[4].empty = false;
        break;
      case "A6":
        productLocation[5].id = item.productId;
        productLocation[5].empty = false;
        break;
      case "A7":
        productLocation[6].id = item.productId;
        productLocation[6].empty = false;
        break;
      case "A8":
        productLocation[7].id = item.productId;
        productLocation[7].empty = false;
        break;
      case "B1":
        productLocation[8].id = item.productId;
        productLocation[8].empty = false;
        break;
      case "B2":
        productLocation[9].id = item.productId;
        productLocation[9].empty = false;
        break;
      case "B3":
        productLocation[10].id = item.productId;
        productLocation[10].empty = false;
        break;
      case "B4":
        productLocation[11].id = item.productId;
        productLocation[11].empty = false;
        break;
      case "B5":
        productLocation[12].id = item.productId;
        productLocation[12].empty = false;
        break;
      case "B6":
        productLocation[13].id = item.productId;
        productLocation[13].empty = false;
        break;
      case "B7":
        productLocation[14].id = item.productId;
        productLocation[14].empty = false;
        break;
      case "B8":
        productLocation[15].id = item.productId;
        productLocation[15].empty = false;
        break;
      default:
    }    
  });

  return productLocation;
}