import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { DeliveryCard } from '@/features/sharedComponents/Cards/DeliveryCard/DeliveryCard';
import { ReactComponent as DroneIcon } from '../../../../../media/drone_icon_v2.svg';

// Styles
import styles from './ComingDeliveries.module.scss';
import { sortComingDelivery } from '@/features/functions/sortComingDelivery';

export function ComingDeliveries() {
  const deliveryState = useAppSelector((state) => state.delivery);
  const cardList = sortComingDelivery(deliveryState.comingDeliveries);

  return (
    <div className={`${styles.card_section} ${styles.comingdeliveries}`}>
      <div className={styles.header_wrapper}>
        <div>Coming deliveries</div>
      </div>
      <div className={styles.body_wrapper}>
        {cardList.length > 0 ? (
          cardList.map((card, index) => (
            <DeliveryCard key={index} {...card} />
          ))
        ) : (
          <div className={styles.empty_result}>
            <DroneIcon width="100" height="100" />
            <div>There are no incoming deliveries.</div>
          </div>
        )}
      </div>
    </div>
  );
}
