import { GridColDef } from '@mui/x-data-grid';

export const productColumns: GridColDef[] = [
  {
    field: 'productId',
    flex: 0.5,
    headerName: '#UN',
    valueGetter: (params) => {
      return params.row.unNumber || null;
    },
  },
  {
    field: 'productName',
    headerName: 'Product name',
    flex: 1,
    sortable: true,
    valueGetter: (params) => {
      return params.row.name || null;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.8,
    valueGetter: (params) => {
      return params.row.amount || null;
    },
  },
  {
    field: 'containers',
    headerName: 'Container',
    flex: 1.2,
    valueGetter: (params) => {
      return params.row.container || null;
    },
  },
];

export const deliveryInfoColumns: GridColDef[] = [
  {
    field: 'departurePort',
    headerName: 'Departure',
    flex: 1,
    valueGetter: (params) => {
      return params.row.departure;
    },
  },
  {
    field: 'arrivalPort',
    headerName: 'Arrival',
    flex: 1,
    valueGetter: (params) => {
      return params.row.arrival;
    },
  },
  {
    field: 'timeOfDeparture',
    headerName: 'Time of Departure',
    flex: 1.8,
    valueGetter: (params) => {
      return params.row.deliveryTime
        ? new Date(params.row.deliveryTime as string).toLocaleDateString(
            'en-us',
            {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }
          )
        : '';
    },
    renderCell: (params) => {
      if (params.row.asap) return "ASAP";
      const value = params.row.deliveryTime
        ? new Date(params.row.deliveryTime as string).toLocaleDateString(
            'en-us',
            {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }
          )
        : '';
      return value ? (
        value
      ) : (
        <span style={{ color: 'red' }}>Not yet chosen</span>
      );
    },
  },
];
