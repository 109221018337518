import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateError } from '@/app/mainSlice';
import { useGetComingDeliveriesQuery, useGetOrdersQuery, useGetPastDeliveryQuery, useGetPlannedDeliveryQuery } from '@/features/api/copApiEndpoints/orderAPI';
import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { CardList } from './components/cardList/CardList';
import { DeliveryTab } from './components/deliveryTab/DeliveryTab';

// Styles
import styles from './Deliveries.module.scss';

export function Deliveries() {
  const [active, setActive] = useState<'past' | 'planned' | ''>('');
  const deliveryState = useAppSelector((state) => state.delivery);
  const dispatch = useAppDispatch();

  // move useGetComingDeliveriesQuery only in required page due to frequent query polling
  // TODO: change pollingInterval to 1000ms after done
  const { isError: comingDelFetchingErr } = useGetComingDeliveriesQuery({limit: '5'}, {pollingInterval: (active == 'past' || active == 'planned') ? 0 : 3000});
  const { isError: plannedDelFetchingErr } = useGetPlannedDeliveryQuery({}, {pollingInterval: (active == 'past' || active == 'planned') ? 0 : 3000});
  const { isError: pastDelFetchingErr } = useGetPastDeliveryQuery({}, {pollingInterval: (active == 'past' || active == 'planned') ? 0 : 3000});
  // const { isError: ordersFetchingErr } = useGetOrdersQuery({
  //   timeStart: new Date(Date.now() - (100* 24 * 60 * 60 * 1000)).toISOString(),
  //   timeEnd: new Date(Date.now() + (100* 24 * 60 * 60 * 1000)).toISOString(),
  // }, {pollingInterval: (active == 'past' || active == 'planned') ? 0 : 3000});

  useEffect(() => {
    if (comingDelFetchingErr || plannedDelFetchingErr || pastDelFetchingErr) {
      dispatch(updateError({ id: '1', value: false }));
    }
  }, [comingDelFetchingErr, dispatch, pastDelFetchingErr, plannedDelFetchingErr]);

  // const sortedPlannedDelivery = deliveryState.plannedDeliveries?.sort(
  //   (delA, delB) => Date.parse(delB.eta) - Date.parse(delA.eta)
  // );
  // const sortedPastDelivery = deliveryState.pastDeliveries?.sort(
  //   (delA, delB) => new Date(delB.eta).getTime() - new Date(delA.eta).getTime()
  // );

  return (
    <MainLayout>
      <div className={styles.body}>
        {active === '' && (
            <CardList />
        )}
        {active !== 'past' && (
          <div
            key="planned-deliveries"
            className={styles['planned-deliveries']}
          >
            <DeliveryTab
              title="Planned deliveries"
              dataSet={
                active === 'planned'
                  ? deliveryState.deliveryList?.filter((data) => {return data.status != 'Canceled' && data.status != 'Completed' && data.status != 'Aborted'})
                  : deliveryState.plannedDeliveries?.slice(0, 10)
              }
              handleExpand={() => setActive('planned')}
              handleShrink={() => {
                setActive('');
              }}
              height={active === 'planned' ? '100%' : undefined}
            />
          </div>
        )}
        {active !== 'planned' && (
          <div key="past-deliveries" className={styles['past-deliveries']}>
            <DeliveryTab
              title="Past deliveries"
              dataSet={
                active === 'past'
                  ? deliveryState.pastDeliveries
                  : deliveryState.pastDeliveries.slice(0, 10)
              }
              handleExpand={() => setActive('past')}
              handleShrink={() => {
                setActive('');
              }}
              height={active === 'past' ? '100%' : undefined}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
}
