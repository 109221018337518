import Cookies from 'js-cookie';

import { loginRequest, msalInstance } from '@/authConfig';

export async function renewTokenSilently() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const silentResult = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    if (silentResult && silentResult.accessToken) {
      Cookies.set('accessToken', silentResult.accessToken, {
        secure: true,
        sameSite: 'strict',
        expires: silentResult.expiresOn?.getTime() || undefined,
      });
      console.log('Successfully renewed token!');
    }
  }
}

export function isTokenExpired() {
  const expires = Cookies.get('expires');
  if (!expires) {
    return true;
  }
  const expiresDate = new Date(expires);
  return new Date() > expiresDate;
}
