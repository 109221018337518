import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import Modal from '@mui/material/Modal';

import styles from './StopGroundHandlingModal.module.scss';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { faArrowRight, faArrowRightFromBracket, faArrowTurnDown, faArrowTurnUp, faBan, faTurnUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '@/app/hooks';
import { resetGroundHandlingOperation } from '@/app/stores/dataSlice/groundHandlingSlice';

export default function StopGroundHandlingModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleStopPreparation = useCallback(() => {
    dispatch(resetGroundHandlingOperation());
    navigate('/hubs');
  }, [navigate]);

  return (
    <Modal
      className={styles.modalWrapper}
      open={open}
      aria-labelledby="compliance-result-modal"
      aria-describedby="compliance-result-modal"
    >
      <div className={styles.cardModal}>
        <div className={styles.header}>
          <p>Exit Preparation</p>
        </div>
        <div className={styles.content}>
          <p>Are you sure you would like to stop preparation?</p>
        </div>
        <div className={styles.footer}>
          < IconButton
            icon={faArrowRightFromBracket}
            content='Exit preparation'
            onClick={handleStopPreparation}
            rotate="rotate180"
            color='unset'
            noBorder={true}
          />
          < IconButton
            icon={faArrowRight}
            content='Continue'
            onClick={handleClose}
            reversed={true}
          />
        </div>
      </div>
    </Modal>
  );
}
