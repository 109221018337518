import { goodsItem } from '@/app/stores/dataSlice/orderingSlice';
import { productType_ogc, containerType_ogc } from '@/app/stores/productSlice';
import { goodListPayload } from '@/features/api/copApiEndpoints/orderAPI';

type validGoodlist = {
  id: number;
  payload: {
    product: productType_ogc;
    container: containerType_ogc;
    quantity: number;
  };
};

export const isValidGoodList = (
  goodlist: goodsItem[]
): [boolean, goodListPayload[], validGoodlist[]] => {
  const validPayload: goodListPayload[] = [];
  const validGoodlist: validGoodlist[] = [];
  goodlist.forEach((item) => {
    const { product, container, quantity } = item.payload;
    // ignore all product with at least 1 empty field
    if (container && product && quantity) {
      validPayload.push({
        productId: product.id,
        containerId: container.id,
        amount: quantity,
      });
      validGoodlist.push({
        id: item.id,
        payload: {
          product,
          container,
          quantity,
        },
      });
    }
  });
  if (validPayload.length === 0) {
    return [false, validPayload, validGoodlist];
  } else {
    return [true, validPayload, validGoodlist];
  }
};
