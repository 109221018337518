import React, { useState } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateAsapTrue, updateDateTime } from '@/app/stores/dataSlice/orderingSlice';
import BasicDateTimePicker from '@/features/sharedComponents/Inputs/DateTimePicker/DateTimePicker';

// Styles
import styles from './OrderTimePicker.module.scss';

// export type hubType = { //  !!! TYPE TO BE DEFINED IN THE HUB PAGE !!!
//   id: number,
//   name: string,
//   picture: string,
// }

// export type OrderTimePickerProps = {
//   id: string, // departure or arrival
//   hubList: hubType[], // hub list
// }

export function OrderTimePicker() {
  const dispatch = useAppDispatch();
  // const { id, hubList } = props;
  const [selectedOption, setSelectedOption] = useState('');

  // Define DateTimePicker props
  let styleClass;
  if (selectedOption === 'option1') {
    styleClass = {};
  } else if (selectedOption === 'option2') {
    styleClass = {
      color: styles.inputColor,
      backgroundColor: styles.backgroundColor,
    };
  } else {
    styleClass = {};
  }

  return (
    <div className={styles.card_orderTime}>
      <div className={styles.card_header}>
        Select your suitable time of departure
      </div>
      <div className={styles.card_wrapper}>
        <div
          className={`${
            selectedOption === 'option1'
              ? styles.card_option1Selected
              : styles.card_option1
          }`}
          onClick={() => {
            setSelectedOption('option1');
            dispatch(updateAsapTrue(true));
          }}
        >
          As soon as possible
        </div>
        or
        <div className={styles.card_option2}>
          <BasicDateTimePicker
            styleClass={styleClass}
            setSelectedOption={() => {
              setSelectedOption('option2');
            }}
            setInputValue={(inputValue: Date) => {
              dispatch(updateDateTime(inputValue.toISOString()));
            }}
          />
        </div>
      </div>
    </div>
  );
}
