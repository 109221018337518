import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { orderAPI } from '@/features/api/copApiEndpoints/orderAPI';

import { hubType } from '../hubSlice';

/* ------------------------------------ TYPES ------------------------------------ */

export type newOrder = {
  hubs: {
    departure: hubType | undefined;
    arrival: hubType | undefined;
  };
  payload: {
    product: productType_ogc;
    container: containerType_ogc;
    quantity: number;
  };
};

export type dashboardState = {
  newAsapOrder: newOrder; // New order to be placed
  statusCounts: {
    scheduled?: number;
    loading?: number;
    unloading?: number;
    'in flight'?: number;
    completed?: number;
    canceled?: number;
  };
};

const initialState: dashboardState = {
  newAsapOrder: {
    hubs: {
      departure: undefined,
      arrival: undefined,
    },
    payload: {
      product: {
        id: '',
        un: '',
        name: '',
        psn: '',
        primary_class: '',
        subsidiary_class: [],
        packing_group: '',
        hazards: [], //{ prim_label: '', sec_label: '' },
      },
      container: { id: '', name: '' },
      quantity: 0,
    },
  },
  statusCounts: {
    scheduled: 0,
    loading: 0,
    unloading: 0,
    'in flight': 0,
    completed: 0,
    canceled: 0,
  },
};

/* ------------------------------------- SLICE ------------------------------------ */

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    placeOrder: (state, action: PayloadAction<newOrder>) => {
      state.newAsapOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orderAPI.endpoints.getOrderCounts.matchFulfilled,
      (state, action) => {
        state.statusCounts = action.payload;
      }
    );
  },
});

export const { placeOrder } = dashboardSlice.actions;

export default dashboardSlice.reducer;
