import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  addGoodsListItem,
  updateGoodsList,
} from '@/app/stores/dataSlice/goodsListSlice';
import {
  goodsItem,
  resetPayload,
  updateOrderGoodsList,
  updatePayloadId,
} from '@/app/stores/dataSlice/orderingSlice';
import { goodListPayload } from '@/features/api/copApiEndpoints/orderAPI';
import { useCreatePayLoadMutation } from '@/features/api/copApiEndpoints/payloadAPI';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';

import ComplianceModal from './components/ComplianceModal';
import { GoodsCard } from './components/GoodsCard';

// Styles
import styles from './GoodsList.module.scss';
import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';

export function GoodsList() {
  // Get access to redux states & dispatch
  const goodsListState = useAppSelector((state) => state.goodsList);
  const newOrderState = useAppSelector((state) => state.ordering.newOrder);
  const dispatch = useAppDispatch();
  const [isShowCompliance, setIsShowCompliance] = useState<boolean>(false);
  const [query, setQuery] = useState<LoadingStatus>('idle');
  const [errMsg, setErrMsg] = useState<string>('');

  const [createPayload, createPayLoadResult] = useCreatePayLoadMutation();

  useEffect(() => {
    if (createPayLoadResult.isLoading) {
      setQuery('progress');
    }
    if (createPayLoadResult.isSuccess) {
      setQuery('success');
    }
    if (createPayLoadResult.isError) {
      setQuery('failure');
    }
  }, [createPayLoadResult]);

  // Get component width dynamically
  const [w, setW] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setW(window.innerWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update new Order payloadId when receiving result from createPayload
  useEffect(() => {
    if (createPayLoadResult.isSuccess && createPayLoadResult.data) {
      dispatch(updatePayloadId(createPayLoadResult.data));
    }
  }, [createPayLoadResult, dispatch]);

  // Handle click compliance
  const handleClick = async () => {
    setIsShowCompliance(true);
    setQuery('progress');

    // CREATE PAYLOAD LOGIC

    // TODO: create logic and UI to warn user about productCard that has at least 1 empty field.

    let goodListPayloads: goodListPayload[] = [];
    let goodListForNewOrder: goodsItem[] = [];
    goodsListState.productCardList.forEach((product) => {
      // ignore all product with at least 1 empty field
      if (
        product.payload?.container &&
        product.payload?.product &&
        product.payload?.quantity
      ) {
        goodListPayloads.push({
          productId: product.payload.product.id,
          containerId: product.payload.container.id,
          amount: product.payload.quantity,
        });
        goodListForNewOrder.push({
          id: product.id,
          payload: product.payload,
        });
      }
    });

    if (goodListPayloads.length > 0) {
      createPayload({ items: goodListPayloads });
      dispatch(updateOrderGoodsList(goodListForNewOrder));
      dispatch(updateGoodsList(goodListForNewOrder));
    } else {
      setQuery('failure');
    }
  };

  // Add card button
  const addCard = () => {
    if (goodsListState.productCardList.length < 10) {
      dispatch(addGoodsListItem());
    }
  };

  const handleClose = () => {
    setIsShowCompliance(false);
    setQuery('idle');
  };

  const handleEdit = () => {
    dispatch(resetPayload());
  };

  return (
    <>
      <div
        className={`${styles.card_section} ${styles.goodsList} ${
          newOrderState.payload ? styles.disabled : null
        }`}
      >
        <div className={styles.header_wrapper}>
          <div>Select your products...</div>
          {!newOrderState.payload && (
            <IconButton
              icon={faCircleCheck}
              content="Check compliance"
              onClick={handleClick}
            />
          )}
        </div>
        <div className={styles.body_wrapper}>
          <div
            className={`${styles.card_goods_add} ${
              newOrderState.payload ? styles.disabled : null
            }`}
            onClick={addCard}
          >
            <div className={styles.icon_wrapper}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <AnimatePresence>
            {goodsListState.productCardList.map((card) => (
              <motion.div
                className={`${newOrderState.payload ? styles.disabled : null}`}
                key={card.id}
                id={`goodCard-id-${card.id}`}
                initial={{ opacity: 0, x: '-100' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{
                  opacity: 0,
                  scale: 0.3,
                  transition: {
                    duration: 0.5,
                  },
                }}
                layout
              >
                <GoodsCard id={card.id} title="Product item" />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        <ComplianceModal
          open={isShowCompliance}
          handleClose={handleClose}
          query={query}
          setQuery={setQuery}
          result={createPayLoadResult}
        />
      </div>
      {newOrderState.payload && (
        <div className={styles.editButton}>
          <IconButton
            icon={faCircleCheck}
            content="Edit"
            onClick={handleEdit}
          />
        </div>
      )}
    </>
  );
}
