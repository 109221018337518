import { hubType } from '@/app/stores/hubSlice';
import { useGetGroundHandlingCountsQuery, useGetHubStatusQuery } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { ReactComponent as PinIcon } from '@/media/icons/fluent-pin.svg';
import { useSpring, animated } from 'react-spring';

import styles from './CompactHubCard.module.scss';
import { useState } from 'react';

type CompactHubCardProps = {
  hub: hubType;
  setPinnedHub: (hub: hubType) => void;
};

type Package = {
  packaging: {
    id: string;
  };
  content: {
    type: string;
    id: string;
  };
};

type GroundOperation = {
  groundHandlingType: string;
  groundHandlingStartTime: string;
  groundHandlingStatus: string;
  packages: Package[];
  drone: string;
  flightStatus: string;
  orderId: string;
  orderReference: string;
  scheduledGroundHandlingTime: string;
  otherHub: string;
};

// TODO: move these variable to style variable
const hubCounters = [
  { name: 'Ready', color: styles.readyColor },
  { name: 'Ongoing', color: styles.ongoingColor },
  { name: 'On time', color: 'black' },
  { name: 'Adapted', color: 'black' },
];

export const hubStatusColorMapping = {
  CLEAR: styles.greenStatus,
  INCOMING: styles.orangeStatus,
  TAL: styles.redStatus,
};

export function CompactHubCard({ hub, setPinnedHub }: CompactHubCardProps) {
  const [show, setShown] = useState(false);

  const { data } = useGetGroundHandlingCountsQuery(
    { hubId: hub.id },
  );
  const { data: hubStatus } = useGetHubStatusQuery({ hubId: hub.id }, {pollingInterval: 1000, refetchOnFocus: true});
  const statusColor = hubStatusColorMapping[hubStatus?.status as 'CLEAR' | 'INCOMING' | 'TAL'] ?? 'white';

  const readyRecords = data?.READY ?? 0;
  const onGoingRecords = data?.STARTED ?? 0;
  const onTimeRecords = data?.ON_TIME ?? 0;
  const adaptedRecords = data?.ADAPTED ?? 0;

  const props3 = useSpring({visibility: show ? 'visible' : 'hidden'});

  return (
    <div 
      className={styles.pinnedHubCard}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className={styles.hubGeneralInfo}>
        <div>
          <img className={styles.coverImg} src={hub.picture} alt={hub.name} />
        </div>
        <div className={styles.generalInfo}>
          <div className={styles.hubStatus} style={{ color: statusColor }} />
          <span>{hub.name}</span>
        </div>
      </div>
      <div className={styles.compactHubOperationInfo}>
        <div className={styles.pillContainer}>
          {hubCounters.map((counter) => (
            <div
              key={counter.name}
              className={styles.statusPill}
              style={{ color: counter.color }}
            >
              <div
                className={styles.statusName}
                style={{ 
                  color: counter.name == 'Ready' ? styles.readyColor : (counter.name == 'Ongoing' ? styles.ongoingColor :'black'),
                  fontFamily: counter.name == 'Ready' ? styles.boldFontFamily : (counter.name == 'Ongoing' ? styles.boldFontFamily : styles.defaultFontFamily) 
                }}
              >
                {counter.name}
              </div>
              <div 
                className={styles.statusNum}
                style={{ 
                  backgroundColor: counter.name == 'Ready' ? styles.readyBgColor : (counter.name == 'Ongoing' ? styles.ongoingBgColor :''),
                }}
              >
                {(() => {
                  switch (counter.name) {
                    case 'Ready':
                      return readyRecords;
                    case 'Ongoing':
                      return onGoingRecords;
                    case 'On time':
                      return onTimeRecords;
                    case 'Adapted':
                      return adaptedRecords;
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.iconBar}>
        <animated.div style={props3}>
          <PinIcon
            className={styles.pinIcon}
            color="dimgrey"
            onClick={() => setPinnedHub(hub)}
          />
        </animated.div>
      </div>
    </div>
  );
}
