import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { hubsAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { hubAPI } from '@/features/api/copApiEndpoints/hubAPI';

export type hubType = {
  id: string;
  name: string;
  location: string;
  lng?: number;
  lat?: number;
  companyId: string;
  picture: string;
};

type HubSlice = {
  hubList: hubType[];
};

const initialState: HubSlice = {
  hubList: [],
};

const hubSlice = createSlice({
  name: 'hubs',
  initialState,
  reducers: {
    resetHubState: () => {
      return initialState;
    },
    updateHubList: (state, action: PayloadAction<hubType[]>) => {
      state.hubList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hubAPI.endpoints.getHubs.matchFulfilled,
      (state, action) => {
        state.hubList = hubsAPI(action.payload);
      }
    );
  },
});

export const { resetHubState, updateHubList } = hubSlice.actions;

export default hubSlice.reducer;
