/* ----------------------------------- IMPORTS ----------------------------------- */
import { copAPI } from '@/features/api/copAPI';
import { companyAPI } from '@/features/api/copApiEndpoints/companyAPI';
import { userAPI } from '@/features/api/copApiEndpoints/userAPI';
import { subscribeToPushNotifications, unsubscribeFromPushNotifications } from '@/pushNotif/pushNotifications';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* ------------------------------------ TYPES ------------------------------------ */
type user = {
  id: number; // ADLC ref
  name: string[]; // Name + abbrev
  adminRights: { name: string; right: boolean }[]; // Admin rights (user mgmt, product mgmt)
};

type profile = {
  id: number; // ADLC ref
  name: string; // Profile name
  profileRights: { name: string; right: boolean }[]; // Rights associated to the profile
};

export type whoAmI = {
  id: string;
  email: string;
  name: string;
  // position: string;
  companyId: string;
  defaultHubId: string | null;
};

type company = {
  id: string; // ADLC ref
  name: string; // Company name
  img_url: string; // Logo file path
  location: string;
};

type error = {
  id: string;
  value: boolean;
};

type mainState = {
  isLoggedIn: boolean;
  user: user;
  profile: profile;
  company: company;
  status: 'idle' | 'loading' | 'failed'; // Async call status
  error: {
    error_api: error;
  };
  whoAmI: whoAmI | undefined;
  pushNotifications: boolean;
};

const initialState: mainState = {
  isLoggedIn: false,
  user: { id: 1, name: ['', ''], adminRights: [] },
  profile: { id: 1, name: 'Manager', profileRights: [] },
  company: { id: '', name: '', img_url: '', location: '' },
  status: 'idle',
  error: {
    error_api: { id: '1', value: false },
  },
  whoAmI: undefined,
  pushNotifications: true,
};

/* ------------------------------------- SLICE ------------------------------------ */
export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    resetMainState: (state) => {
      state = initialState;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    updateError: (state, action: PayloadAction<error>) => {
      switch (action.payload.id) {
        case '1': {
          state.error.error_api.value = action.payload.value;
          break;
        }
        // Add other error updates (missing hub info, ...)
      }
    },
    updateDefaultHubId: (state, action: PayloadAction<string>) => {
      if (state.whoAmI) {
        state.whoAmI.defaultHubId = action.payload;
      }
    },
    updatePushNotifications: (state, action: PayloadAction<boolean>) => {
      state.pushNotifications = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(copAPI.endpoints.whoAmI.matchFulfilled, (state, action) => {
        state.whoAmI = action.payload;
      })
      .addMatcher(companyAPI.endpoints.getCompany.matchFulfilled, (state, action) => {
        state.company = action.payload;
      })
      .addMatcher(userAPI.endpoints.updateUser.matchFulfilled, (state, action) => {
        state.whoAmI = action.payload;
      })
      .addMatcher(copAPI.endpoints.signIn.matchFulfilled, () => {
        subscribeToPushNotifications()
      })
      .addMatcher(copAPI.endpoints.signOut.matchFulfilled, () => {
        unsubscribeFromPushNotifications()
      })
  },
});

export const { resetMainState, login, logout, updateError, updateDefaultHubId, updatePushNotifications } = mainSlice.actions;

export default mainSlice.reducer;
