import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutsideSelectors } from '@/hooks/useOnClickOutsideSelectors';
import styles from './DropDownMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updatePushNotifications } from '@/app/mainSlice';
import { subscribeToPushNotifications, unsubscribeFromPushNotifications } from '@/pushNotif/pushNotifications';

type DropDownMenuProps = {
  menuItems: DropDownMenuItem[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DropDownMenuItem =
  | {
      icon?: IconDefinition;
      name: string;
      action: (props: any) => void;
    }
  | 'seperator';

export const DropDownMenu = ({
  menuItems,
  isOpen,
  setIsOpen,
}: DropDownMenuProps) => {
  // useOnClickOutsideSelectors(['drop-down-menu', '.menu-item'], () => setIsOpen(false));
  const main = useAppSelector((state) => state.main);
  const dispatch = useAppDispatch();

  const [showRight, setShowRight] = React.useState(main.pushNotifications); 

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  const handleSwitch = () => {
    console.log('main.pushNotification :', main.pushNotifications)
    if (main.pushNotifications) {unsubscribeFromPushNotifications()}
    else { subscribeToPushNotifications()}
    dispatch(updatePushNotifications(!main.pushNotifications))
  }
  // useEffect(() => {
  //     if (main.pushNotifications) {subscribeToPushNotifications()}
  //     else {unsubscribeFromPushNotifications()}
  // }, [main.pushNotifications]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles['drop-down-menu']}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: -20,
            transition: {
              duration: 0.1,
            },
          }}
        >
          {menuItems.map((menuItem, idx) => {
            if (typeof menuItem === 'object') {
              if (menuItem.name == 'Push notifications'){
                return (
                  <div
                    key={idx}
                    className={styles['menu-item']}
                  >
                    {menuItem.icon && <FontAwesomeIcon icon={menuItem.icon} />}
                    {menuItem.name}
                    <AntSwitch className={styles['ant-switch']} inputProps={{ 'aria-label': 'ant design' }} onChange={handleSwitch} checked={main.pushNotifications}/>
                  </div>
                );
              } else {
                return (
                  <div
                    key={idx}
                    className={styles['menu-item']}
                    onClick={menuItem.action}
                  >
                    {menuItem.icon && <FontAwesomeIcon icon={menuItem.icon} />}
                    {menuItem.name}
                  </div>
                );
              }
            } else {
              return <hr key={idx} className={styles['solid-separator']} />;
            }
          })}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
