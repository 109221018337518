import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { hubsAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { hubAPI } from '@/features/api/copApiEndpoints/hubAPI';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';

import { hubType } from '../hubSlice';
import { containerType_ogc, productType_ogc } from '../productSlice';

/* ------------------------------------ TYPES ------------------------------------ */
export type goodsItem = {
  id: number;
  payload: {
    product: productType_ogc | null;
    container: containerType_ogc | null;
    quantity: number | null;
  };
};

export type orderType = {
  goodsList: goodsItem[];
  payload?: PayloadReturn;
  hubs: { departure: hubType | undefined; arrival: hubType | undefined };
  dateTime: string;
  asap?: boolean;
};

export type orderingState = {
  // TO POST
  newOrder: orderType;
};

// InitialState --> !!! REPLACE TEST DATA BY EMPTY VARIABLE WHEN CONNECTED TO API,

const initPayload = { product: null, container: null, quantity: null };
const initialState: orderingState = {
  newOrder: {
    goodsList: [
      { id: 0, payload: initPayload },
      { id: 1, payload: initPayload },
      { id: 2, payload: initPayload },
      { id: 3, payload: initPayload },
    ],
    hubs: { departure: undefined, arrival: undefined },
    dateTime: '',
  },
};

/* ------------------------------------- SLICE ------------------------------------ */
export const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    updatePayloadId: (state, action: PayloadAction<PayloadReturn>) => {
      state.newOrder.payload = action.payload;
    },
    updateOrderGoodsList: (state, action: PayloadAction<goodsItem[]>) => {
      state.newOrder.goodsList = action.payload;
    },
    updateHubs: (
      state,
      action: PayloadAction<{ id: string; hub: hubType }>
    ) => {
      const hubID = action.payload.id;
      const hub = action.payload.hub;
      switch (hubID) {
        case 'departure': {
          state.newOrder.hubs.departure = hub;
          break;
        }
        case 'arrival': {
          state.newOrder.hubs.arrival = hub;
          break;
        }
      }
    },
    updateDateTime: (state, action: PayloadAction<string>) => {
      state.newOrder.dateTime = action.payload;
      state.newOrder.asap = undefined;
    },
    updateAsapTrue: (state, action: PayloadAction<boolean>) => {
      state.newOrder.asap = action.payload;
    },
    resetOrder: (state) => {
      state.newOrder = initialState.newOrder;
    },
    resetPayload: (state) => {
      delete state.newOrder.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hubAPI.endpoints.getHubs.matchFulfilled,
      (state, action) => {
        state.newOrder.hubs.departure = hubsAPI(action.payload)[0] || undefined;
        state.newOrder.hubs.arrival = hubsAPI(action.payload)[1] || hubsAPI(action.payload)[0] || undefined;
      }
    );
  },
});

export const {
  updateOrderGoodsList,
  resetOrder,
  updateHubs,
  updateDateTime,
  updateAsapTrue,
  updatePayloadId,
  resetPayload,
} = orderingSlice.actions;

export default orderingSlice.reducer;
