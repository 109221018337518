import React from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';

export function Help() {
  return (
    <MainLayout>
      <p>Help page</p>
    </MainLayout>
  );
}
