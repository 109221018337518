import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IconButton.module.scss';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import CircularProgress from '@mui/joy/CircularProgress/CircularProgress';

export type IconButtonProps = {
  icon?: IconDefinition,
  content: string,
  onClick: () => void;
  isLoading?: boolean,
  disabled?: boolean,
  reversed?: boolean,
  rotate?: string, // degrees
  color?: 'unset' | 'unset_white' | 'unset_white_blue' | 'unset_blue' | 'blue' | 'red' | 'green' | 'mobileMenu',
  noBorder?: boolean,
  noPadding?: boolean,
  size?: SizeProp,
  reducedGap?: boolean,
}

export function IconButton(props: IconButtonProps) {
  const { icon, content, onClick, isLoading, disabled, reversed, rotate, color, noBorder, noPadding, size, reducedGap } = props;

  return (
    <div>
      { reversed === true ? (
        <div 
          className={`
            ${styles.button_icon} 
            ${disabled ? styles.disabled_button : ''} 
            ${color ? styles[color] : ''}
            ${noBorder ? styles.no_border: ''}
            ${noPadding ? styles.no_padding: ''}
            ${reducedGap ? styles.reduced_gap: ''}`} 
          onClick={onClick}
        >
          {isLoading ? (
            <>
              <CircularProgress sx={{ color: 'black' }} />
            </>
          ):(
            <>
              {content}
              {icon && <FontAwesomeIcon 
                icon={icon} 
                className={rotate ? styles[rotate] : ""}
                size={size ? size : 'sm'}
              />}
            </>
          )}
        </div>
      ) : (
        <div 
          className={`
            ${styles.button_icon} 
            ${disabled ? styles.disabled_button : ''} 
            ${color ? styles[color] : ''}
            ${noBorder ? styles.no_border: ''}
            ${noPadding ? styles.no_padding: ''}
            ${reducedGap ? styles.reduced_gap: ''}`} 
          onClick={onClick}>
          {icon && <FontAwesomeIcon 
            icon={icon} 
            className={rotate ? styles[rotate] : ""}
            size={size ? size : 'sm'}
          />}     
          {content}
        </div>
      )}
    </div>
  );
}
