const hubGroundOperationTypeMapping: Record<string, string> = {
  START_EMPTY: 'Prepare empty flight',
  START_DELIVERY: 'Prepare delivery flight',
  END_DELIVERY: 'Complete delivery flight',
};

export const mapOperationType = (value: string | undefined): string =>
  value ? hubGroundOperationTypeMapping[value] ?? '---' : '---';

const hubGroundOperationStatus: Record<string, string> = {
  ON_TIME: 'On time',
  ADAPTED: 'Now', // handling manually for customized case
  READY: 'Ready',
  STARTED: 'Ongoing',
  DONE: 'Done',
  CANCELLED: 'Cancelled',
};

export const mapOperationStatus = (value: string | undefined): string =>
  value ? hubGroundOperationStatus[value] ?? '---' : '---';
