import { faAsterisk, faBan, faBox, faCloudDownload, faDownload, faFileArrowDown, faFileExport, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Delivery } from '@/app/stores/deliverySlice';
import {
  useDeleteOrderMutation,
  useGetPlannedDeliveryQuery,
} from '@/features/api/copApiEndpoints/orderAPI';
import DelayingAppearance from '@/features/sharedComponents/Modal/LoadingUI';
import { ReactComponent as ADLCLogo } from '@/media/adlc_logo_only.svg';

import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';
import styles from './DeliveryCardModal.module.scss';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetProductQuery } from '@/features/api/copApiEndpoints/productAPI';
import { useGetPayloadQuery } from '@/features/api/copApiEndpoints/payloadAPI';
import { capitalizeWords } from '@/features/functions/capitalizeWords';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import EditPayload from '../editPayload/EditPayload';
import Tooltip from '@mui/joy/Tooltip';
import AutocompleteSI from '@/features/sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';
import { productType_ogc } from '@/app/stores/productSlice';
import EditRef from '../editRef/EditRef';
import { downloadPdf } from '@/features/functions/downloadPdfFile';

export default function DeliveryCardModal({
  open,
  handleClose,
  delivery,
}: {
  open: boolean;
  handleClose: () => void;
  delivery: Delivery;
}) {
  const navigate = useNavigate();
  const productState = useAppSelector((state) => state.product);
  const [product, setProduct] = useState<productType_ogc>();

  const [modalState, setModalState] = useState<'NORMAL' | 'EDIT_SHIPMENT' | 'EDIT_REF'>('NORMAL');
  const [query, setQuery] = useState<LoadingStatus>('idle');
  const [errMsg, setErrMsg] = useState<string>('');
  const [showEditShipment, setShowEditShipment] = useState<boolean>(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false);
  const [isShowEditOption, setIsShowEditOption] = useState<boolean>(false);
  const [deleteOrder, deleteOrderResult] = useDeleteOrderMutation();
  const { refetch: refetchDeliveryList } = useGetPlannedDeliveryQuery({});

  const { isError: payloadErr, data: payloadData  } = useGetPayloadQuery(delivery.payload.id);


  useEffect(() => {
    if (deleteOrderResult.isSuccess) {
      setIsShowConfirmation(false);
      setQuery('success');
      refetchDeliveryList();
      handleClose();
    } else if (deleteOrderResult.isError && deleteOrderResult.error) {
      if ('data' in deleteOrderResult.error) {
        const errData = deleteOrderResult.error.data as { message: string };
        if ('message' in errData) {
          const errorMessage = errData.message;
          setErrMsg(errorMessage);
        }
      }
      setQuery('failure');
      setIsShowConfirmation(false);
      // TODO: add failure - error message modal
      refetchDeliveryList();
      handleClose();
    }
  }, [deleteOrderResult, handleClose, refetchDeliveryList]);

  const handlePrepareFlight = useCallback(() => {
    navigate(`/deliveries/preparation/${`START_DELIVERY`}`);
  }, [navigate]);
  const handleCancelFlight = useCallback(() => {
    setQuery('progress');
    deleteOrder(delivery.id);
  }, [deleteOrder, delivery.id]);

  // Detailed information
  const generalInfo = (({ no, ordered_by, prepared_by, unloaded_by }) => ({
    no,
    ordered_by,
    prepared_by,
    unloaded_by,
  }))(delivery);
  const productInfo = (({ mass, items }) => (
    {
      mass: `${Number(mass.toFixed(2))} kg`,
      product1: `${items[0].amount} x ${capitalizeWords(items[0].product.name)}`,
      product2: (items.length > 1) ? `${items[1].amount} x ${capitalizeWords(items[1].product.name)}` : '---',
      product3: (items.length > 2) ? `${items[2].amount} x ${capitalizeWords(items[2].product.name)}` : '---',

    }
  ))(delivery.payload);

  const handleDownload = (pdfUrl: string | undefined) => {
    if (pdfUrl != undefined) {
      fetch(pdfUrl)
        .then(response => response.blob())
        .then(blob => {
          // Create a temporary anchor element
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          // link.setAttribute('download', Transport ); // Set the filename for the download
          document.body.appendChild(link);
          link.click();
          // Clean up resources
          if (link.parentNode) {link.parentNode.removeChild(link)}
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading PDF:', error));
      }
  };

  return (
      <Modal
        className={styles.modalWrapper}
        aria-labelledby="delivery-detail-modal"
        aria-describedby="delivery-detail-modal"
        open={open}
        onClose={handleClose}
      >
        <>
          {modalState == 'NORMAL' && <div className={styles.cardModal}>
            {isShowConfirmation ? (
              <Confirmation
                handleCancelFlight={handleCancelFlight}
                cancelFlightCancellation={() => setIsShowConfirmation(false)}
                delivery={delivery}
                query={query}
              />
            ) : (
              <>
                <div className={styles.header}>
                  <div
                    className={styles.statusHeader}
                    style={{
                      color: (() => {
                        switch (delivery.status) {
                          case 'Pending':
                            return styles.statusColorPending;
                          case 'Scheduled':
                            return styles.statusColorScheduled;
                          case 'Approved':
                            return styles.statusColorApproved;
                          case 'Coming':
                            return styles.statusColorComing;
                          case 'Ready':
                            return styles.statusColorReady;
                          case 'Loading':
                            return styles.statusColorLoading;
                          case 'Loaded':
                            return styles.statusColorLoaded;
                          case 'In Flight':
                            return styles.statusColorInflight;
                          case 'Landed':
                            return styles.statusColorReadyToUnload;
                          case 'Unloading':
                            return styles.statusColorUnloading;
                          case 'Canceled':
                            return styles.statusColorCancelled;
                          case 'Completed':
                            return styles.statusColorCompleted;
                          case 'Aborted':
                            return styles.statusColorAborted;
                          default:
                            break;
                        }
                      })(),
                    }}
                  >
                    <div className={styles.dot}></div>
                    {delivery.status}
                  </div>
                  <div className={styles.close_icon} onClick={handleClose} >
                    <FontAwesomeIcon size='1x' icon={faXmark}/>
                  </div>
                </div>
                <div className={styles.flight_route}>
                  <div className={styles.hub_info}>
                    <div>
                      {delivery.etr
                        ? new Date(delivery.etr).toLocaleDateString()
                        : null}
                    </div>
                    <div className={styles.hub_name}>{delivery.departure}</div>
                    <div>
                      {delivery.etr
                        ? new Date(delivery.etr).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        : 'Wait for '}{' '}
                      ETR
                    </div>
                  </div>
                  <div>
                    <ADLCLogo width={'40px'} height={'40px'} color={styles.colorBlue} />
                  </div>
                  <div className={styles.hub_info}>
                    <div>
                      {delivery.eta
                        ? new Date(delivery.eta).toLocaleDateString()
                        : null}
                    </div>
                    <div className={styles.hub_name}>{delivery.arrival}</div>
                    <div>
                      {delivery.eta
                        ? new Date(delivery.eta).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        : 'Wait for '}{' '}
                      ETA
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.info_card}>
                    <div className={styles.info_card_header}>
                      General information
                    </div>
                    <div className={styles.info_card_content}>
                      {Object.entries(generalInfo).map(([field, value]) => (
                        <DeliveryDetails key={field} field={field} value={value} edit={showEditShipment} productList={productState.productList} setProduct={setProduct}/>
                      ))}
                    </div>
                  </div>
                  <div className={styles.card_shipment_wrapper} >
                    <div className={styles.info_card_shipment}>
                      <div className={styles.info_card_header_shipment}>
                        <div className={styles.title}> Shipment </div>
                      </div>
                      <div className={styles.info_card_content_shipment}>
                        {Object.entries(productInfo).map(([field, value]) => (
                          <DeliveryDetails key={field} field={field} value={value} edit={showEditShipment} productList={productState.productList} setProduct={setProduct}/>
                        ))} 
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>
                  <IconButton content='Cancel' 
                    onClick={() => setIsShowConfirmation(true)} 
                    icon={faBan} 
                    disabled={
                      delivery.status === 'In flight' ||
                      delivery.status === 'Completed' ||
                      delivery.status === 'Canceled'  ||
                      delivery.status === 'Pending'
                    }
                    color='unset'
                    noBorder={true}
                  />
                  <div className={styles.rightButtons}>
                    <div className={(delivery.status === 'In flight' ||
                        delivery.status === 'Completed' ||
                        delivery.status === 'Canceled'  ||
                        delivery.status === 'Pending'
                        ) ? styles.disabledButton : styles.editButtonWrapper} 
                      onMouseEnter={() => setIsShowEditOption(true)} 
                      onMouseLeave={() => setIsShowEditOption(false)}
                    >
                      {isShowEditOption && <div className={styles.hiddenButton}>
                          <IconButton content='Shipment' 
                            onClick={() => setModalState('EDIT_SHIPMENT')} 
                            icon={faBox} 
                            color='unset_blue'
                            noBorder={true}
                            reducedGap={true}
                          />
                          <IconButton content='Ref'
                            onClick={() => setModalState('EDIT_REF')} 
                            icon={faAsterisk} 
                            color='unset_blue'
                            noBorder={true}
                            reducedGap={true}
                          />
                        </div>
                      }
                      <div className={isShowEditOption ? styles.editButton_hover : styles.editButton}> <FontAwesomeIcon icon={faPen} size='sm'/> </div>
                    </div>
                    <div className={delivery.pdfUrl ? styles.downloadButton : styles.disabledDownloadButton} onClick={() => downloadPdf(delivery.pdfUrl)}> <FontAwesomeIcon icon={faDownload} size='sm'/> </div>
                  </div>
                </div>
                {errMsg ? <div>{errMsg}</div> : null}
              </>
            )}
          </div>}
          {modalState == 'EDIT_SHIPMENT' && <div className={styles.cardModal}>
            <div className={styles.header}>
              <div> Update your shipment... </div>
              <div className={styles.close_icon} onClick={handleClose}>
                <FontAwesomeIcon size='1x' icon={faXmark}/>
              </div>
            </div>
            <div className={styles.editPayload_wrapper}>
              <EditPayload orderId={delivery.id}/>
            </div>
          </div>}
          {modalState == 'EDIT_REF' && <div className={styles.cardModal}>
            <div className={styles.header}>
              <div> Update your reference(s)... </div>
              <div className={styles.close_icon} onClick={handleClose}>
                <FontAwesomeIcon size='1x' icon={faXmark}/>
              </div>
            </div>
            <div className={styles.editPayload_wrapper}>
              <EditRef orderId={delivery.id} currentRefs={delivery.no}/>
            </div>
          </div>}
        </>
      </Modal>
  );
}

function DeliveryDetails({ field, value, edit, productList, setProduct }: { field: string; value: string | string[], edit: boolean, productList: productType_ogc[], setProduct: (input: productType_ogc) => void }) {
  let displayedField = field; 
  if (field =='ordered_by') {displayedField = 'ordered by'}
  else if (field == 'no') {displayedField = 'Ref'}
  else if (field == 'prepared_by') {displayedField = 'prepared by'}
  else if (field == 'unloaded_by') {displayedField = 'unloaded by'}
  else if (field == 'product1') {displayedField = 'product 1'}
  else if (field == 'product2') {displayedField = 'product 2'}
  else if (field == 'product3') {displayedField = 'product 3'}

    return (
      <>
        {edit && <div className={styles.itemDetail}>
          <div className={styles.itemKey}>{displayedField} </div>
          {(field == 'product1' || field == 'product2' || field == 'product3') ? (
            <AutocompleteSI
              autocompleteParam={{
                  id: 'customized-hook-single-name-input',
                  multiple: false,
                  options: productList,
                  getOptionLabel: (option) => option.name || '',
                  getOptionDisabled: undefined,
                  width: '120px',
              }}
              setInputValue={(inputValue: productType_ogc) => setProduct(inputValue)}
            />
          ):(
          <Tooltip title={value}>
            <div className={styles.itemValue}>{value}</div>
          </Tooltip>
          )}
        </div>}
        {!edit && <div className={styles.itemDetail}>
          <div className={styles.itemKey}>{displayedField} </div>
          <Tooltip title={value}>
            <div className={styles.itemValue}>
              {(Array.isArray(value) && value.every((item: any) => typeof item === 'string')) ? (
                <>
                  {value.map((item, idx) => (
                    <div key={idx}> {item} </div>
                  ))}
                </>
              ):(<> {value} </>)}
            </div>
          </Tooltip>
        </div>}
      </>
    );
}

function Confirmation({
  handleCancelFlight,
  cancelFlightCancellation,
  delivery,
  query,
}: {
  handleCancelFlight: () => void;
  cancelFlightCancellation: () => void;
  delivery: Delivery;
  query: LoadingStatus;
}) {
  return (
    <>
      <div className={styles.confirmation_header}>
        Flight Cancellation Confirmation
      </div>
      <div className={styles.confirmation_content}>
        <div>
          Are you absolutely sure you want to cancel your flight no.
          {delivery.id}?
        </div>
        {query === 'progress' && <DelayingAppearance color="black" />}
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.cancelButton}
          onClick={handleCancelFlight}
          disabled={query === 'progress'}
        >
          Confirm
        </Button>
        <Button
          className={styles.cancelCancellation}
          onClick={cancelFlightCancellation}
          disabled={query === 'progress'}
        >
          I misclicked
        </Button>
      </div>
    </>
  );
}
