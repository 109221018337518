// Define a type for the variables
type LetterNumber = {
    letter: string;
    number: number;
};

type InputError = "InvalidFormat" | "InvalidNumber" | "NoInput" | null;

// Function to convert the string
export function convertChecklistProgress(inputString: string): [LetterNumber, number, InputError] {
    if (!inputString) {
        return [{ letter: 'A', number: 1 }, 1 , "NoInput"];
    }

    const firstChar = inputString[0];
    const remainingChars = inputString.substring(1);

    // Check if the first character is a letter
    if (!/[a-zA-Z]/.test(firstChar)) {
        return [null as any, NaN, "InvalidFormat"];
    }

    const letter = firstChar.toUpperCase();
    const number = parseInt(letter, 36) - 9; 
    const numericPart = parseInt(remainingChars);

    // Check if the remaining characters form a valid number
    if (isNaN(number)) {
        return [null as any, NaN, "InvalidNumber"];
    }

    return [{ letter, number }, numericPart, null];
}

// Test the function
// const inputString = "A123";
// const [letterNumber, numericPart, error] = convertChecklistProgress(inputString);

// if (error) {
//     console.log("Error:", error);
// } else {
//     console.log("Letter:", letterNumber.letter);
//     console.log("Number:", letterNumber.number);
//     console.log("Numeric Part:", numericPart);
// }
