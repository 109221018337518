export function capitalizeWords(str: string): string {
    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word, and convert the rest to lowercase
    const capitalizedWords = words.map(word => {
        if (word.length === 0) {
            return word; // Return empty string as is
        }
        // Check if the word is fully capitalized
        if (word.toUpperCase() === word) {
            // Convert the word to lowercase and capitalize the first letter
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
            // Capitalize the first letter and leave the rest unchanged
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
    });

    // Join the capitalized words back into a single string
    return capitalizedWords.join(' ');
}