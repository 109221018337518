import React, { Dispatch, SetStateAction } from 'react';
import Modal from '@mui/material/Modal';
import {
  faArrowRight,
  faCircleCheck,
  faCircleRight,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppSelector } from '@/app/hooks';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';
import { productColumns } from '@/features/sharedComponents/Modal/OrderConfirmModal/columnModalTable';
import DelayingAppearance from '@/features/sharedComponents/Modal/LoadingUI';
import { ModalTable } from '@/features/sharedComponents/Table/ModalTable';

import styles from './ComplianceModal.module.scss';
import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';

type Result = {
  requestId?: string | undefined;
  data?: PayloadReturn;
  error?: any;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
};

export default function ComplianceModal({
  open,
  handleClose,
  query,
  setQuery,
  result,
}: {
  query: LoadingStatus;
  open: boolean;
  handleClose: () => void;
  setQuery: Dispatch<SetStateAction<LoadingStatus>>;
  result: Result;
}) {
  const newOrderState = useAppSelector((state) => state.ordering.newOrder);
  const displayGoodList = result.data?.items?.map((item, idx) => ({
    id: idx,
    unNumber: item.product.unNumber,
    name: item.product.name,
    container: item.containerType.name,
    amount: item.amount,
  }));

  return (
    <Modal
      className={styles.modalWrapper}
      open={open}
      aria-labelledby="compliance-result-modal"
      aria-describedby="compliance-result-modal"
    >
      <div className={styles.cardModal}>
        <div className={styles.header}>
          <p>Compliance Check Result</p>
        </div>
        <div className={styles.content}>
          {query === 'progress' && <DelayingAppearance/>}
          {query === 'success' && newOrderState.payload && displayGoodList && (
            <>
              <div className={styles.succMsg}>
                <div>
                  <FontAwesomeIcon icon={faCircleCheck} size="2x" />
                </div>
                <div>Your products are compliant with ADLC drone transportation.</div>
              </div>
              <div>
                Product(s)
              </div>
              {console.log(displayGoodList)}
              <ModalTable
                columns={productColumns}
                colTools
                data={displayGoodList}
                tableHeight={displayGoodList.length > 4 ? '238px' : undefined}
                initState={[{ field: 'eta', sort: 'desc' }]}
              />
            </>
          )}
          {result.isError && result.error && (
            <div
              // style={{
              //   fontSize: '1.5rem',
              //   textAlign: 'center',
              //   color: 'red',
              // }}
              className={styles.errMsg}
            >
              {result.error.data.message.message}
            </div>
          )}
        </div>
        <div className={styles.footer}>
          {(query === 'success' || query === 'failure') && (
            <>
              {query === 'success' ? (
                <IconButton
                  icon={faArrowRight}
                  content="Continue"
                  onClick={handleClose}
                  reversed={true}
                />
              ):(
                <IconButton
                  icon={faRotateRight}
                  content="Try again"
                  onClick={handleClose}
                  reversed={true}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

              // <button className={styles.normalButton} onClick={handleClose}>
              //   <span>{query === 'success' ? 'Continue' : 'Try again'}</span>
              //   {query === 'success' && (
              //     <FontAwesomeIcon icon={faCircleRight} color="green" size="xl" />
              //   )}
              // </button>
