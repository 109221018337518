import * as React from 'react';
import { useState } from 'react';

import { subscribeToPushNotifications, unsubscribeFromPushNotifications } from "../../../pushNotif/pushNotifications";

import { logout, resetMainState } from '@/app/mainSlice';
import { resetSidebarState } from '../Sidebar/sidebarSlice';
import { resetDeliveryState } from '@/app/stores/deliverySlice';
import { resetProductState } from '@/app/stores/productSlice';
import { resetHubState } from '@/app/stores/hubSlice';
import { resetContainerState } from '@/app/stores/containerSlice';
import { resetGroundHandlingState } from '@/app/stores/dataSlice/groundHandlingSlice';
import { updateShowMobileMenu } from '@/features/sharedComponents/Sidebar/mobileSidebarSlice'
import { msalInstance } from '@/authConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DropDownMenu, DropDownMenuItem } from '../DropDownMenu/DropDownMenu';

// Styles
import styles from './Topbar.module.scss';
import { IconButton } from '../Buttons/iconButton/IconButton';
import { MobileDigitalClock } from '../DigitalClock/MobileDigitalClock';
import { faCircleQuestion, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRightFromBracket,
  faBars,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

const accountMenuItems: DropDownMenuItem[] = [
  // {
  //   icon: faUser,
  //   name: 'Profile',
  //   action: () => {
  //     console.log('direct to Profile page');
  //   },
  // },
  // 'seperator',
  // {
  //   icon: faCircleQuestion,
  //   name: 'Help Center',
  //   action: () => {
  //     console.log('direct to Help Center page');
  //   },
  // },
  // 'seperator',
];

export function Topbar() {
  const sidebar = useAppSelector((state) => state.sidebar);
  const dispatch = useAppDispatch();
  const userName = useAppSelector((state) => state.main.whoAmI?.name)?.split(' ');
  const initials =
    (userName && userName.length > 1)
      ? `${userName[0][0]}${userName[userName.length -1][0]}`
      : (userName && userName.length > 0)
      ? `${userName[0][0]}`
      : '?';

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const handleLogout = () => {
    dispatch(resetMainState());
    dispatch(resetSidebarState());
    dispatch(resetDeliveryState());
    dispatch(resetProductState());
    dispatch(resetHubState());
    dispatch(resetContainerState());
    dispatch(resetGroundHandlingState());
    dispatch(logout());
  }

  const reportIssueOption = {
    icon: faArrowRightFromBracket,
    name: 'Report issue',
    action: () => {
      const accounts = msalInstance.getAllAccounts();
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
      };
      msalInstance.logoutRedirect(logoutRequest);
      handleLogout();
    },
  };

  const signOutOption = {
    icon: faArrowRightFromBracket,
    name: 'Log Out',
    action: () => {
      const accounts = msalInstance.getAllAccounts();
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
      };
      msalInstance.logoutRedirect(logoutRequest);
      handleLogout();
    },
  };

  const subscribeOption = {
    icon: faBell,
    name: 'Push notifications',
    action: () => {
      // subscribeToPushNotifications();
    },
  };

  // const unsubscribeOption = {
  //   icon: faBell,
  //   name: 'Unsubscribe from notifications',
  //   action: () => {
  //     unsubscribeFromPushNotifications();
  //   },
  // };

  const openMobileMenu = () => {
    dispatch(updateShowMobileMenu(true))
  }

  const userAccountMenuItems = [...accountMenuItems, signOutOption, subscribeOption];

  return (
    <div className={styles.topbar}>
      <div className={styles.title}> 
        <div className={styles.mobileMenu}>
          <IconButton
            icon={faBars}
            content=""
            onClick={openMobileMenu}
            rotate="rotate180"
            noBorder={true}
            color='unset_blue'
          />
        </div>
        {sidebar.activePage} 
        <div className={styles.mobileClock}>
          <MobileDigitalClock/>
        </div>
      </div>
      <div className={styles.content_wrapper}>
        {/* <div className={styles.button_circle}>
          <FontAwesomeIcon icon={faBell} />
        </div> */}
        <div
          className={`${styles.button_circle} user-account-btn`}
          onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
        >
          {initials}
        </div>
      </div>
      <DropDownMenu
        isOpen={isAccountMenuOpen}
        setIsOpen={setIsAccountMenuOpen}
        menuItems={userAccountMenuItems}
      />
    </div>
  );
}
