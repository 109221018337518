import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

import { BasicButton } from '@/features/sharedComponents/Buttons/basicButton/BasicButton';

import styles from './TakeOffModal.module.scss';

export default function TakeOffModal({
  isLoading,
  open,
}: {
  isLoading: boolean;
  open: boolean;
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/deliveries');
  };

  return (
    <Modal
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      open={open}
      aria-labelledby='compliance-result-modal'
      aria-describedby='compliance-result-modal'
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={styles.takeoffModal}>
          <div className={styles.header}>
            <p>Flight Preparation</p>
          </div>
          <div className={styles.content}>
            <FontAwesomeIcon icon={faCircleCheck} size="3x" color="green" />
            <div>
              <h2>Thanks!</h2>
              <p>The drone will take-off</p>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '5px',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: 'red',
              }}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <span>Please stay outside of the take-off pad</span>
            </div>
          </div>
          <div className={styles.footer}>
            <BasicButton
              content="Return to Delivery Page"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}
