import React, { useState, useRef ,useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './LongPressButton.module.scss';
import ReactCurvedText from "react-curved-text";
import {ReactComponent as AdlcIcon} from "@/media/adlc_logo_icon_w.svg";

import { useAppDispatch, useAppSelector } from '@/app/hooks';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { GroundHandlingActionParams, usePostEndGroundHandlingOperationMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';

interface CircularButtonProps {
  duration: number; // Duration in milliseconds for a complete circle (e.g., 1000ms for 1 second)
  onLongPress: () => void; // Callback function when the long press completes
}

const LongPressButton: React.FC<CircularButtonProps> = ({ duration, onLongPress }) => {
  const groundHandlingState = useAppSelector((state) => state.groundHandling);
  const groundHandlingOperationType = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.type;
  const orderId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId;
  const [postEndGroundHandlingOperation, postEndGroundHandlingOperationResult] = usePostEndGroundHandlingOperationMutation(); 

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [takeoff, setTakeoff] = useState(false);
  const [show, setShown] = useState(false);

  const handleMouseDown = () => {
    setLoading(true);

    intervalRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (360/4 / (duration / 100)); // Adjust the step size based on the desired smoothness
        if (newProgress >= 120) {
          clearInterval(intervalRef.current!);
          setLoading(false);
          setProgress(0);
          onLongPress();
        }
        return newProgress;
      });
    }, 100); // Adjust the interval as needed
  };

  const handleMouseUp = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setLoading(false);
      setProgress(0);
      setTakeoff(true)
    }
  };

  const props3 = useSpring({
    transform: show ? 'scale(1.03)' : 'scale(1)',
    boxShadow: show
      ? '0 10px 10px rgb(0 0 0 / 50%)'
      : '0 2px 10px rgb(0 0 0 / 8%)',
  });

  const CompleteGroundHandlingOperation = () => {
    // console.log('test Complete ground handling !')
    // const paramsMutation: GroundHandlingActionParams = {orderId: orderId, type: groundHandlingOperationType};
    // postEndGroundHandlingOperation(paramsMutation);
  }

  return (
    <> 
      <animated.div
            className={styles.takeOffButton}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
            onClick= {CompleteGroundHandlingOperation}
      >
        {loading && (
          <div style={{position: 'relative', pointerEvents: 'none'}}>
            <div style={{position: 'absolute', left: '-75px', top: '-75px'}}>
              <ReactCurvedText width={150}
                height={150}
                cx={75}
                cy={75}
                rx={50}
                ry={50}
                startOffset={20}
                reversed={true}
                textPathProps={{"fill": "white"}}
                textProps={{"style": {"fontSize": "14", "fontWeight": "bold", "fontFamily": "MuseoSansCyrl-700"},  "textLength": "120px"}}
                text="READY FOR"
              />
            </div>
            <div style={{position: 'absolute', left: '-75px', top: '-75px'}}>
              <ReactCurvedText width={150}
                height={150}
                cx={75}
                cy={75}
                rx={60}
                ry={60}
                startOffset={35}
                textPathProps={{"fill": "white"}}
                textProps={{"style": {"fontSize": "14", "fontWeight": "bold", "fontFamily": "MuseoSansCyrl-700"},  "textLength": "120px"}}
                text="TAKE-OFF"
              /> 
            </div>
            <div style={{position: 'absolute', left: '-30px', top: '-30px'}}>
              <CircularProgress
                size={60} // Adjust the size as needed
                thickness={4} // Adjust the thickness as needed
                variant="determinate"
                value={progress}
                color='error'
                style={{ position: 'absolute' }}
              />
            </div>
            <div style={{position: 'absolute', left: '-10px', top: '-10px'}}>
                <div style={{width:'20px', height: '20px', display:'flex', alignItems:'center', justifyContent:'center'}}>  <AdlcIcon /> </div>
            </div>
          </div>
        )}
        {!loading && (
          // <div className={styles.text_wrapper}>
          //   Ready for Take-Off
          // </div>)
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', left: '-75px', top: '-75px'}}>
              <ReactCurvedText width={150}
                height={150}
                cx={75}
                cy={75}
                rx={50}
                ry={50}
                startOffset={20}
                reversed={true}
                textPathProps={{"fill": "white"}}
                textProps={{"style": {"fontSize": "14", "fontWeight": "bold", "fontFamily": "MuseoSansCyrl-700"},  "textLength": "120px"}}
                text="READY FOR"
              />
            </div>
            <div style={{position: 'absolute', left: '-75px', top: '-75px'}}>
              <ReactCurvedText width={150}
                height={150}
                cx={75}
                cy={75}
                rx={60}
                ry={60}
                startOffset={35}
                textPathProps={{"fill": "white"}}
                textProps={{"style": {"fontSize": "14", "fontWeight": "bold", "fontFamily": "MuseoSansCyrl-700"},  "textLength": "120px"}}
                text="TAKE-OFF"
              /> 
            </div>
            <div style={{position: 'absolute', left: '-10px', top: '-10px'}}>
              <div style={{width:'20px', height: '20px', display:'flex', alignItems:'center', justifyContent:'center'}}>  <AdlcIcon /> </div>
            </div>
          </div>
          )
        }
      </animated.div>
    </>
  );
};

export default LongPressButton;
