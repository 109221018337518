import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { v4 as uuidv4 } from "uuid";
import { updateOrderBody, useGetOrdersQuery, useGetPlannedDeliveryQuery, useUpdateOrderMutation } from '@/features/api/copApiEndpoints/orderAPI';

import styles from './EditPayload.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCircleCheck, faCircleMinus, faCirclePlus, faCircleXmark, faMinus, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { GoodsList } from '@/features/pages/Ordering/components/goodsList/GoodsList';
import { GoodsCard } from '@/features/pages/Ordering/components/goodsList/components/GoodsCard';
import AutocompleteSI, { InputWrapper } from '@/features/sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';
import ProductCard from '@/features/sharedComponents/Cards/ProductCard/ProductCard';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';
import DelayingAppearance from '@/features/sharedComponents/Modal/LoadingUI';
import { useGetFillingPatternQuery } from '@/features/api/copApiEndpoints/groundHandlingAPI';

export default function EditPayload({
    orderId,
    checklist
  }: {
    orderId: string;
    checklist?: boolean;
  }) {
    const productState = useAppSelector((state) => state.product);
    const containerState = useAppSelector((state) => state.container);
    const initialSize = 1;
    const [product, setProduct] = useState<productType_ogc[]>(Array(initialSize).fill(undefined));
    const [container, setContainer] = useState<containerType_ogc[]>(Array(initialSize).fill(undefined));
    const [quantity, setQuantity] = useState<number[]>(Array(initialSize).fill(undefined));
    const [payloadInvalid, setPayloadInvalid] = useState<boolean[]>(Array(initialSize).fill(false));
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const { refetch: refetchGetOrders } = useGetOrdersQuery({});
    const { refetch: refetchGetFillingPattern } = useGetFillingPatternQuery({orderId: orderId});
    const [putUpdateOrder, putUpdateOrderResults] = useUpdateOrderMutation(); 
    const [query, setQuery] = useState<LoadingStatus>('idle');
    const [errMsg, setErrMsg] = useState<string>('');

    useEffect(() => {
        if (putUpdateOrderResults.isSuccess && putUpdateOrderResults.data) {
          setQuery('success');
          refetchGetOrders();
          refetchGetFillingPattern();
        } else if (putUpdateOrderResults.isLoading) {
            setQuery('progress');
        } else if (putUpdateOrderResults.isError && putUpdateOrderResults.error) {
          if ('data' in putUpdateOrderResults.error) {
            const errData = putUpdateOrderResults.error.data as { message:  {message: string} };
            if ('message' in errData) {
              const errorMessage = errData.message;
              if ('message' in errorMessage) {
                setErrMsg(errorMessage.message);
              }
            }
          }
          setQuery('failure');
        }
    }, [putUpdateOrderResults, refetchGetOrders]);

    useEffect(() => { // CHECK VALIDITY OF INPUTS
        for (let i = 0; i < product.length; i++) {
            if (product[i] != undefined) {
                if (product[i] == undefined || container[i] == undefined || quantity[i] == undefined) {
                    const updatedList = payloadInvalid;
                    updatedList[i] = true;
                    setPayloadInvalid(updatedList)
                }
            }
        }
        const condition1 = (payloadInvalid.length == 0);
        const condition2 = payloadInvalid.every((item) => item == true);
        setDisableSubmit((condition1 || !condition2));
    }, [product]);

    const addProduct = () => {
        const newSize = product.length + 1; // Specify the new desired size
        const updatedProduct = [...product, ...Array(newSize - product.length).fill(undefined)];
        const updatedContainer = [...container, ...Array(newSize - product.length).fill(undefined)];
        const updatedQuantity = [...quantity, ...Array(newSize - product.length).fill(undefined)];
        const updatedPayloadInvalid = [...payloadInvalid, ...Array(newSize - product.length).fill(false)];
        setProduct(updatedProduct); setContainer(updatedContainer); setQuantity(updatedQuantity); setPayloadInvalid(updatedPayloadInvalid);
    };
    const deleteProduct = () => {
        const updatedProduct = [...product.slice(0, -1)];
        const updatedContainer = [...container.slice(0, -1)];
        const updatedQuantity = [...quantity.slice(0, -1)];
        const updatedPayloadInvalid = [...payloadInvalid.slice(0, -1)];
        setProduct(updatedProduct); setContainer(updatedContainer); setQuantity(updatedQuantity); setPayloadInvalid(updatedPayloadInvalid);
    };
    const handleSubmit = () => {
        const payloadItems: {amount: number, containerId: string, productId: string}[] = [];
        for (let i = 0; i < product.length; i++) {
            payloadItems.push({amount: quantity[i], containerId: container[i]?.id, productId: product[i]?.id})
        }
        const body: updateOrderBody = {payload: {items: payloadItems}};
        putUpdateOrder({orderId: orderId, body: body});
    }
    
    return (
        <>
        {query == 'idle' ? ( 
            <div className={styles.wrapper}>
                <div className={styles.title_wrapper}>
                    <div className={styles.titleItem}> # </div>
                    <div className={styles.titleItem}> Name </div>
                    <div className={styles.titleItem}> Container </div>
                    <div className={styles.titleItem}> Quantity </div>
                </div>
                <>
                    {product.map((item, idx) => (
                        <ProductItem 
                            key={idx}
                            idx={idx}
                            productList={productState.productList}
                            containerList={containerState.containerList}
                            setProductProp={(idx: number, input: productType_ogc) => {
                                const updatedList = [...product];
                                updatedList[idx] = input;
                                console.log('In Product', input)
                                setProduct(updatedList);
                            }}
                            setContainerProp={(idx: number, input: containerType_ogc) => {
                                const updatedList = [...container];
                                updatedList[idx] = input;
                                console.log('In Container', input)
                                setContainer(updatedList);
                                console.log('In Container: list: ', updatedList)
                            }}
                            setQuantityProp={(idx: number, input: number) => {
                                const updatedList = [...quantity];
                                updatedList[idx] = input;
                                console.log('In Quantity', input)
                                setQuantity(updatedList);
                            }}
                        />
                    ))}
                </>
                <div className={styles.footer_wrapper}>
                    <div className={styles.listOperations}>
                        <IconButton content='' 
                            onClick={() => addProduct()} 
                            icon={faCirclePlus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='lg'
                            disabled={product.length == 10}
                        />
                        <IconButton content='' 
                            onClick={() => deleteProduct()} 
                            icon={faCircleMinus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='lg'
                            disabled={product.length == 1}
                        />
                    </div>
                    <IconButton content='' 
                        onClick={() => handleSubmit()} 
                        icon={faPaperPlane}
                        disabled={disableSubmit}
                    />
                </div>
            </div>
            ):(
                <div className={styles.wrapper}>
                    {query === 'progress' && <DelayingAppearance />}
                    <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                    >
                    {query === 'success' && (
                        <div className={styles.succMsg}>
                            <span> <FontAwesomeIcon icon={faCircleCheck} size="1x" /> </span>
                            <span> Your shipment has been adapted !</span>
                        </div>
                    )}
                    {query == 'failure' && 
                        <div className={styles.errMsg}>
                            <span> <FontAwesomeIcon icon={faCircleXmark} size="1x" /> </span>
                            {errMsg}
                        </div>}
                    </div>
                </div>
            )}
        </>
    );
}

function ProductItem({ 
    idx,
    productList, containerList, 
    setProductProp, setContainerProp, setQuantityProp
}:{ 
    idx: number;
    productList: productType_ogc[];
    containerList: containerType_ogc[];
    setProductProp: (idx: number, input: productType_ogc) => void;
    setContainerProp: (idx: number, input: containerType_ogc) => void;
    setQuantityProp: (idx: number, input: number) => void;
}) {

    return (
        <div className={styles.productItem_wrapper}>
            <div className={styles.productItem_number}> {idx+1} </div>
            <div className={styles.body_item}>
                <AutocompleteSI 
                    autocompleteParam={{
                        id: 'customized-hook-single-name-input',
                        multiple: false,
                        options: productList,
                        getOptionLabel: (option) => option.name || '',
                        getOptionDisabled: undefined,
                    }}
                    setInputValue={(inputValue: productType_ogc) => setProductProp(idx, inputValue)}
                />
            </div>
            <div className={styles.body_item}>
                <AutocompleteSI
                autocompleteParam={{
                    id: 'customized-hook-single-dimension-input',
                    multiple: false,
                    options: containerList,
                    getOptionLabel: (option) => option.name,
                    getOptionDisabled: undefined,
                }}
                setInputValue={(inputValue: containerType_ogc) => setContainerProp(idx, inputValue)}
                />
            </div>
            <div className={styles.body_item}>
                <InputWrapper>
                <input
                    type="number"
                    min="0"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.valueAsNumber < 0 || Number.isNaN(e.target.valueAsNumber)) 
                            {e.target.value = ''; setQuantityProp(idx, 0);
                            } else {setQuantityProp(idx, e.target.valueAsNumber)}
                        }}
                />
                </InputWrapper>
            </div>
        </div>
    );
}

