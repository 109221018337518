import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { v4 as uuidv4 } from "uuid";
import { updateOrderBody, useGetOrdersQuery, useGetPlannedDeliveryQuery, useUpdateOrderMutation } from '@/features/api/copApiEndpoints/orderAPI';

import styles from './EditRef.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCircleCheck, faCircleMinus, faCirclePlus, faCircleXmark, faMinus, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { GoodsList } from '@/features/pages/Ordering/components/goodsList/GoodsList';
import { GoodsCard } from '@/features/pages/Ordering/components/goodsList/components/GoodsCard';
import AutocompleteSI, { InputWrapper } from '@/features/sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';
import ProductCard from '@/features/sharedComponents/Cards/ProductCard/ProductCard';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';
import DelayingAppearance from '@/features/sharedComponents/Modal/LoadingUI';
import TextField from '@mui/joy/TextField/TextField';
import Input from '@mui/joy/Input/Input';

export default function EditRef({
    currentRefs,
    orderId,
  }: {
    currentRefs: string[];
    orderId: string;
  }) {
    const initialSize = 1;
    const [ref, setRef] = useState<string[]>(Array(initialSize).fill(undefined));
    const [refInvalid, setRefInvalid] = useState<boolean[]>(Array(initialSize).fill(false));
    
    const { refetch: refetchPlannedDeliveryList } = useGetPlannedDeliveryQuery({});
    const [putUpdateOrder, putUpdateOrderResults] = useUpdateOrderMutation(); 
    const [query, setQuery] = useState<LoadingStatus>('idle');
    const [errMsg, setErrMsg] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);


    useEffect(() => { // UPDATE RESULTS OF API CALL
        if (putUpdateOrderResults.isSuccess && putUpdateOrderResults.data) {
          setQuery('success');
          refetchPlannedDeliveryList();
        } else if (putUpdateOrderResults.isLoading) {
            setQuery('progress');
        } else if (putUpdateOrderResults.isError && putUpdateOrderResults.error) {
          if ('data' in putUpdateOrderResults.error) {
            const errData = putUpdateOrderResults.error.data as { message:  {message: string} };
            if ('message' in errData) {
              const errorMessage = errData.message;
              if ('message' in errorMessage) {
                setErrMsg(errorMessage.message);
              }
            }
          }
          setQuery('failure');
        }
    }, [putUpdateOrderResults, refetchPlannedDeliveryList]);

    useEffect(() => { // CHECK MAX CHARACTER OF NEW REFS
        let counter = Array(ref.length).fill(0);
        for (let i = 0; i < ref.length; i++) {
            if (ref[i] != undefined) {
                if (ref[i].length > 10) {
                    const updatedList = refInvalid;
                    updatedList[i] = true;
                    counter[i] = ref[i].length;
                    setRefInvalid(updatedList)
                } else if (ref[i].length <= 10) {
                    const updatedList = refInvalid;
                    updatedList[i] = false;
                    counter[i] = ref[i].length;
                    setRefInvalid(updatedList)
                } 
            }
        }
        const condition1 = (ref.length == 0);
        const condition2 = counter.every((num) => num > 0 && num <= 10);
        setDisableSubmit((condition1 || !condition2));
    }, [ref, setRef]);


    const addProduct = () => {
        const newSize = ref.length + 1; // Specify the new desired size
        const updatedRef = [...ref, ...Array(newSize - ref.length).fill(undefined)];
        const updatedRefInvalid = [...refInvalid, ...Array(newSize - ref.length).fill(false)];
        setRef(updatedRef); setRefInvalid(updatedRefInvalid);
    };
    const deleteProduct = () => {
        const updatedRef = [...ref.slice(0, -1)];
        const updatedRefInvalid = [...refInvalid.slice(0, -1)];
        setRef(updatedRef); setRefInvalid(updatedRefInvalid);
    };
    const handleSubmit = () => {
        const body: updateOrderBody = {clientRefs: ref};
        putUpdateOrder({orderId: orderId, body: body});
    }
    

    return (
        <>
        {query == 'idle' ? ( 
            <div className={styles.wrapper}>
                <div className={styles.title_wrapper}>
                    <div className={styles.titleItem}> Current Ref </div>
                    <div className={styles.titleItem}> New Ref <div className={styles.subTitle}>(max 10 ch)</div> </div>
                </div>
                <div className={styles.content_wrapper}>
                    <div className={styles.currentRefs}>
                        {currentRefs != undefined && currentRefs.map((item,idx) => (
                            <div key={idx}> {item} </div>
                        ))}
                    </div>
                    <div className={styles.newRefs}>
                        {ref.map((item, idx) => (
                            <Input
                                key={idx}
                                placeholder={`Ref ${idx+1}`}
                                onChange={(e) => {
                                    const updatedList = [...ref];
                                    updatedList[idx] = e.target.value.toUpperCase().trim();
                                    setRef(updatedList)
                                }}
                                error={refInvalid[idx]}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.footer_wrapper}>
                    <div className={styles.listOperations}>
                        <IconButton content='' 
                            onClick={() => addProduct()} 
                            icon={faCirclePlus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='lg'
                            disabled={ref.length == 3}
                        />
                        <IconButton content='' 
                            onClick={() => deleteProduct()} 
                            icon={faCircleMinus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='lg'
                            disabled={ref.length == 1}
                        />
                    </div>
                    <IconButton content='' 
                        onClick={() => handleSubmit()} 
                        icon={faPaperPlane}
                        disabled={disableSubmit}
                    />
                </div>
            </div>
            ):(
                <div className={styles.wrapper}>
                    {query === 'progress' && <DelayingAppearance />}
                    <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                    >
                    {query === 'success' && (
                        <div className={styles.succMsg}>
                            <span> <FontAwesomeIcon icon={faCircleCheck} size="1x" /> </span>
                            <span> Your reference has been adapted !</span>
                        </div>
                    )}
                    {query == 'failure' && 
                        <div className={styles.errMsg}>
                            <span> <FontAwesomeIcon icon={faCircleXmark} size="1x" /> </span>
                            {errMsg}
                        </div>}
                    </div>
                </div>
            )}
        </>
    );
}

function ProductItem({ 
    idx,
    productList, containerList, 
    product, setProduct, 
    container, setContainer,
    quantity, setQuantity
}:{ 
    idx: number;
    productList: productType_ogc[];
    containerList: containerType_ogc[];
    product: productType_ogc[] | undefined; setProduct: (input: productType_ogc[]) => void;
    container: containerType_ogc[] | undefined; setContainer: (input: containerType_ogc[]) => void;
    quantity: number[] | undefined; setQuantity: (input: number[]) => void;
}) {
    return (
        <div className={styles.productItem_wrapper}>
            <div className={styles.productItem_number}> {idx+1} </div>
            <div className={styles.body_item}>
                <AutocompleteSI 
                    autocompleteParam={{
                        id: 'customized-hook-single-name-input',
                        multiple: false,
                        // error: true,
                        options: productList,
                        getOptionLabel: (option) => option.name || '',
                        getOptionDisabled: undefined,
                    }}
                    setInputValue={(inputValue: productType_ogc) => {
                        if (product) {
                            const updatedList = [...product];
                            updatedList[idx] = inputValue;
                            setProduct(updatedList)
                        }
                    }}
                />
            </div>
            <div className={styles.body_item}>
                <AutocompleteSI
                autocompleteParam={{
                    id: 'customized-hook-single-dimension-input',
                    multiple: false,
                    options: containerList,
                    getOptionLabel: (option) => option.name,
                    getOptionDisabled: undefined,
                }}
                setInputValue={(inputValue: containerType_ogc) => {
                    if (container) {
                        const updatedList = [...container];
                        updatedList[idx] = inputValue;
                        setContainer(updatedList)
                    }
                }}
                />
            </div>
            <div className={styles.body_item}>
                <InputWrapper>
                <input
                    type="number"
                    min="0"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                        e.target.valueAsNumber < 0 ||
                        Number.isNaN(e.target.valueAsNumber)
                    ) {
                        e.target.value = '';
                        if (quantity) {
                            const updatedList = [...quantity];
                            updatedList[idx] = 0;
                            setQuantity(updatedList)
                        }
                    } else {
                        if (quantity) {
                            const updatedList = [...quantity];
                            updatedList[idx] = e.target.valueAsNumber;
                            setQuantity(updatedList)
                        }
                    }
                    }}
                />
                </InputWrapper>
            </div>
        </div>
    );
}

