import React from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';

export function Settings() {
  return (
    <MainLayout>
      <p>Settings page</p>
    </MainLayout>
  );
}
