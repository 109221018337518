import { useEffect } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateError } from '@/app/mainSlice';
import {
  useGetComingDeliveriesQuery,
  useGetPlannedDeliveryQuery,
} from '@/features/api/copApiEndpoints/orderAPI';
import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { BasicTab } from './components/basicTab/BasicTab';
import { ComingDeliveries } from './components/comingDeliveries/ComingDeliveries';
import { FastOrdering } from './components/fastOrdering/FastOrdering';
import { StatusSummary } from './components/statusSummary/StatusSummary';

import styles from './Dashboard.module.scss';

export function Dashboard() {
  const dispatch = useAppDispatch();

  // move useGetComingDeliveriesQuery only in required page due to frequent query polling
  // TODO: change pollingInterval to 1000ms after done
  const { isError: comingDelFetchingErr } = useGetComingDeliveriesQuery(
    {
      limit: '5',
    },
    { pollingInterval: 10000 }
  );

  const { isError: plannedDelFetchingErr } = useGetPlannedDeliveryQuery(
    {},
    { pollingInterval: 60000 }
  );

  useEffect(() => {
    if (comingDelFetchingErr || plannedDelFetchingErr) {
      dispatch(updateError({ id: '1', value: false }));
    }
  }, [comingDelFetchingErr, dispatch, plannedDelFetchingErr]);

  return (
    <MainLayout>
      <div className={styles.body}>
        <div id={styles.bloc1}>
          <ComingDeliveries />
        </div>
        <div id={styles.bloc2}>
          <StatusSummary />
        </div>
        <div id={styles.bloc3}>
          <BasicTab />
        </div>
        <div id={styles.bloc4}>
          <FastOrdering />
        </div>
      </div>
    </MainLayout>
  );
}
