import React, { useEffect, useRef, useState } from 'react';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from "uuid";

import { Table } from '@/features/sharedComponents/Table/Table';

import { productOgcColTable } from './productOgcColTab';

// Styles
import styles from './ProductOgcTab.module.scss';
import { productType_ogc } from '@/app/stores/productSlice';
import ProductCardModal from '../productModal/ProductCardModal';

// Types
export type ProductTabProps = {
  title: string;
  dataSet: productType_ogc[];
};

export function ProductOgcTab(props: ProductTabProps) {
  const { title, dataSet } = props;
  const [product, setProduct] = useState<productType_ogc | null>(null);
  const [isShowProductModal, setIsShowProductModal] = useState<boolean>(false);

  // Get component width dynamically
  const ref = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setW(ref.current!.offsetWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define Table props
  const CustomToolbar:
    | React.JSXElementConstructor<any>
    | null
    | undefined = () => {
    return (
      <div>
        <GridToolbarContainer className={styles.toolbar_wrapper}>
          <div>{title}</div>

          <GridToolbarQuickFilter />

          {/* <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport /> */}
        </GridToolbarContainer>
      </div>
    );
  };

  const handleChooseProduct = (event: React.MouseEvent<HTMLDivElement>) => {
    const rowId = event.currentTarget.dataset.id as string;
    const product = dataSet.find((prod) => prod.id === rowId);
    if (product) {
      setProduct(product);
      setIsShowProductModal(true);
    }
  };
  const handleClose = () => {
    setIsShowProductModal(false);
    setProduct(null);
  };
  
  return (
    <div ref={ref} className={styles.card_table}>
      <Table
        columns={productOgcColTable(w)}
        data={dataSet}
        colTools
        toolbar={CustomToolbar}
        tableHeight={'800px'}
        initState={[{ field: 'name', sort: 'asc' }]}
        handleChooseRow={handleChooseProduct}
      />

      {/* <div className={styles.button_square}>
        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} size="sm" />
      </div> */}
      {product && (
        <ProductCardModal
          key={uuidv4()}
          product={product}
          open={isShowProductModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
