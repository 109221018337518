function exportToCsv(header: string[], data: object[]) {
  // Combine header and data into a single array
  const allRows = [header, ...data.map(row => Object.values(row))];

  // Convert the array into CSV format
  const csvContent = "data:text/csv;charset=utf-8," +
    allRows.map(row => row.join(",")).join("\n");

  // Generate the file name with the current date
  const currentDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
  const fileName = `adlc_deliveries_${currentDate}.csv`;

  // Create a data URI for the CSV content
  const encodedUri = encodeURI(csvContent);

  // Create a temporary anchor element for downloading the CSV file
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);

  // Append the anchor element to the document body (required for Firefox)
  document.body.appendChild(link);

  // Trigger a click event on the anchor element to initiate the download
  link.click();
}

export default exportToCsv;
