import { copAPI } from '../copAPI';

export type DroneData = {
  reference: string;
  callSign: string;
  model: string;
};

export type putDroneBody = {
  hubId: string;
}

type putDroneParams = {
  reference: string
};

export const droneApi = copAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDrone: builder.query<DroneData | DroneData[], string>({
      query: (reference) => ({
        url: `./drones/${reference}`,
        method: 'GET',
      }),
    }),
    putDrone: builder.mutation({
      query: ({params, body}:{params: putDroneParams, body: putDroneBody}) => ({
        url: `./drones/${params.reference}`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
});

export const { useGetDroneQuery, usePutDroneMutation } = droneApi;
