import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownLong,
  faCircleDot,
  faLocationDot,
  faVial,
  faBox,
  faHashtag,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  updateFastOrderingHubs,
  updateFastOrderingPayload,
} from '@/app/stores/dataSlice/fastOrderingSlice';
import { hubType } from '@/app/stores/hubSlice';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import AutocompleteSI, {
  InputWrapper,
} from '@/features/sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';
import OrderConfirmModal from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';

// Styles
import styles from './FastOrdering.module.scss';

export function FastOrdering() {
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const productState = useAppSelector((state) => state.product);
  const hubState = useAppSelector((state) => state.hub);
  const containerState = useAppSelector((state) => state.container);
  const fastOrderingState = useAppSelector((state) => state.fastOrdering);
  const dispatch = useAppDispatch();

  // Define IconButton props
  const handleClick = () => {
    setIsShowConfirm(true);
  };

  const handleClose = () => {
    setIsShowConfirm(false);
  };

  return (
    <div className={`${styles.card_section} ${styles.fastordering}`}>
      <div className={styles.header_wrapper}>
        <div>Order asap</div>
        <IconButton icon={faPaperPlane} content="" onClick={handleClick} />
      </div>
      <div className={styles.body_wrapper}>
        {/* HUB SECTION */}
        <div className={styles.hub_wrapper}>
          <div className={styles.header_wrapper}>Select your route...</div>
          <div className={styles.body_wrapper}>
            <div className={styles.select_wrapper}>
              <div className={styles.depHubSelection}>
                <div className={styles.icon_wrapper}>
                  <FontAwesomeIcon icon={faCircleDot} size="sm" />
                  <div className={styles.abbrev}>DEP</div>
                </div>
                <AutocompleteSI
                  autocompleteParam={{
                    id: 'customized-hook-single-name-input',
                    defaultValue: null,
                    multiple: false,
                    options: hubState.hubList,
                    getOptionLabel: (option) => option.name,
                    getOptionDisabled: undefined,
                  }}
                  setInputValue={(inputHub: hubType) => {
                    dispatch(
                      updateFastOrderingHubs({ id: 'departure', hub: inputHub })
                    );
                  }}
                />
              </div>
              <div className={styles.arrow}>
                <FontAwesomeIcon icon={faArrowDownLong} />
              </div>
              <div className={styles.arrHubSelection}>
                <div className={styles.icon_wrapper}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <div className={styles.abbrev}> ARR</div>
                </div>
                <AutocompleteSI
                  autocompleteParam={{
                    id: 'customized-hook-single-name-input',
                    defaultValue: null,
                    multiple: false,
                    options: hubState.hubList.filter(
                      (item) => item !== fastOrderingState.hubs.departure
                    ),
                    getOptionLabel: (option) => option.name,
                    getOptionDisabled: undefined,
                  }}
                  setInputValue={(inputHub: hubType) => {
                    dispatch(
                      updateFastOrderingHubs({ id: 'arrival', hub: inputHub })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PRODUCT SECTION */}
        <div className={styles.product_wrapper}>
          <div className={styles.header_wrapper}>Select your product...</div>
          <div className={styles.body_wrapper}>
            <div className={styles.body_item}>
              <div className={styles.icon_wrapper}>
                <FontAwesomeIcon icon={faVial} />
              </div>
              <AutocompleteSI
                autocompleteParam={{
                  id: 'customized-hook-single-name-input',
                  defaultValue: null,
                  multiple: false,
                  options: productState.productList,
                  getOptionLabel: (option) => option.name,
                  getOptionDisabled: undefined,
                }}
                setInputValue={(inputValue: productType_ogc) => {
                  dispatch(
                    updateFastOrderingPayload({
                      payloadType: 'product',
                      payloadItem: inputValue,
                    })
                  );
                }}
              />
            </div>
            <div className={styles.body_item}>
              <div className={styles.icon_wrapper}>
                <FontAwesomeIcon icon={faBox} />
              </div>
              <AutocompleteSI
                autocompleteParam={{
                  id: 'customized-hook-single-dimension-input',
                  defaultValue: null,
                  multiple: false,
                  options: containerState.containerList,
                  getOptionLabel: (option) => option.name,
                  getOptionDisabled: undefined,
                }}
                setInputValue={(inputValue: containerType_ogc) => {
                  dispatch(
                    updateFastOrderingPayload({
                      payloadType: 'container',
                      payloadItem: inputValue,
                    })
                  );
                }}
              />
            </div>
            <div className={styles.body_item}>
              <div className={styles.icon_wrapper}>
                <FontAwesomeIcon icon={faHashtag} />
              </div>
              <InputWrapper>
                <input
                  type="number"
                  min="0"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      e.target.valueAsNumber < 0 ||
                      Number.isNaN(e.target.valueAsNumber)
                    ) {
                      e.target.value = '';
                      dispatch(
                        updateFastOrderingPayload({
                          payloadType: 'quantity',
                          payloadItem: 0,
                        })
                      );
                    } else {
                      dispatch(
                        updateFastOrderingPayload({
                          payloadType: 'quantity',
                          payloadItem: e.target.valueAsNumber,
                        })
                      );
                    }
                  }}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
      </div>
      {isShowConfirm && (
        <OrderConfirmModal
          open={isShowConfirm}
          handleClose={handleClose}
          ordertype="fastOrder"
        />
      )}
      {/* <div className={styles.footer_wrapper}>
        <div className={styles.order_button} onClick={handleClick}>
          Order ASAP
        </div>
      </div> */}
    </div>
  );
}
