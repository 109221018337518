import { Checklist } from "@/app/stores/dataSlice/groundHandlingSlice";

type JsonChecklistItem = {
  icon: string;
  description: string;
  details?: string;
}

type JsonAction = {
  step: number;
  title: string;
  items: JsonChecklistItem[];
  image?: string;
};

export type JsonChecklist = {
  title: string;
  actions: JsonAction[];
}

export type fetchedChecklistsData = { 
  checklists: JsonChecklist[];
}

async function fetchChecklistsJsonData(url: string): Promise<fetchedChecklistsData | null> {
  try {
    const response = await fetch(url);

    if (response.ok) {
      const data: fetchedChecklistsData = await response.json();
      return data;
    } else {
      console.error(`Failed to fetch data from ${url}. Status code: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return null;
  }
}
function mapToDesiredType(fetchedData: fetchedChecklistsData): Checklist[] {
  // Implement the mapping or transformation logic based on your needs
  // This is where you convert the original data into the desired output type
  const outputData: Checklist[] = fetchedData.checklists.map((item: JsonChecklist) => {
    return {
      title: item.title,
      actions: item.actions.map((item: JsonAction) => {
        return {
          step: item.step,
          title: item.title,
          items: item.items,
          image: item?.image,
        };
      }),
    };
  });
  return outputData;
}
export async function fetchCheclistsDataAndMap (url: string): Promise<Checklist[]> {
  try {
    const fetchedData = await fetchChecklistsJsonData(url);
    if (fetchedData !== null) {
      return mapToDesiredType(fetchedData);
    } else {
      // Handle the case where fetchChecklistsData returns null
      return [];
    }
  } catch (error) {
    // Handle errors here
    console.error("Error fetching and mapping data:", error);
    return [];
  }
}