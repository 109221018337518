import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';

import styles from './BasicTab.module.scss';

export function colTable(props_width: number): GridColDef[] {
  let width = props_width;

  const datetimeAdapter = (dateTime: string) => {
    const dateTimeObject = new Date(dateTime);
    const adaptedDate = dateTimeObject.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '.');
    const adaptedTime = dateTimeObject.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/\//g, '.');
    return [adaptedDate, adaptedTime];
  };

  const etrRenderCell = (params: GridRenderCellParams<string>) => {
    const localDate = new Date(params.row.etr ?? '');
    if (isNaN(localDate.getTime())) return <Box display="flex">---</Box>;
    return (
      <Box display="flex">
        {localDate.toLocaleDateString()} <br />
        {localDate.toLocaleTimeString()}
      </Box>
    );
  };

  const etaRenderCell = (params: GridRenderCellParams<string>) => {
    const localDate = new Date(params.row.eta ?? '');
    if (isNaN(localDate.getTime())) return <Box display="flex">---</Box>;
    return (
      <Box display="flex">
        {localDate.toLocaleDateString()} <br />
        {localDate.toLocaleTimeString()}
      </Box>
    );
  };

  const statusRenderCell = (params: GridRenderCellParams<string>) => {
    return (
      <Box
        width="100%"
        m="0"
        p="3px 10px"
        display="flex"
        justifyContent="center"
        borderRadius={styles.statusBorderRadius}
        color={() => {
          switch (params.value) {
            case 'Pending':
              return styles.statusColorPending;
            case 'Scheduled':
              return styles.statusColorScheduled;
            case 'Approved':
              return styles.statusColorApproved;
            case 'Coming':
              return styles.statusColorComing;
            case 'Ready':
              return styles.statusColorReady;
            case 'Loading':
              return styles.statusColorLoading;
            case 'Loaded':
              return styles.statusColorLoaded;
            case 'In Flight':
              return styles.statusColorInflight;
            case 'Landed':
              return styles.statusColorReadyToUnload;
            case 'Unloading':
              return styles.statusColorUnloading;
            case 'Canceled':
              return styles.statusColorCancelled;
            case 'Completed':
              return styles.statusColorCompleted;
            case 'Aborted':
              return styles.statusColorAborted;
            default:
              break;
          }
        }
        }
        bgcolor={() => {
          switch (params.value) {
            case 'Pending':
              return styles.statusBgColorPending;
            case 'Scheduled':
              return styles.statusBgColorScheduled;
            case 'Approved':
              return styles.statusBgColorApproved;
            case 'Coming':
              return styles.statusBgColorComing;
            case 'Ready':
              return styles.statusBgColorReady;
            case 'Loading':
              return styles.statusBgColorLoading;
            case 'Loaded':
              return styles.statusBgColorLoaded;
            case 'In Flight':
              return styles.statusBgColorInflight;
            case 'Landed':
              return styles.statusBgColorReadyToUnload;
            case 'Unloading':
              return styles.statusBgColorUnloading;
            case 'Canceled':
              return styles.statusBgColorCancelled;
            case 'Completed':
              return styles.statusBgColorCompleted;
            case 'Aborted':
              return styles.statusBgColorAborted;
            default:
              break;
          }
        }
        }
      >
        {params.value}
      </Box>
    );
  };

  if (width >= 975) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: false },
      { field: 'ordered_by', headerName: 'Ordered by', flex: 1.2, sortable: false },
      { field: 'prepared_by', flex: 1.2, headerName: 'Prepared by', sortable: false },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: false },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: false },
      { field: 'etr', headerName: 'ETR', flex: 1, type: 'dateTime', sortable: false, renderCell: etrRenderCell },
      { field: 'eta', headerName: 'ETA', flex: 1, type: 'dateTime', sortable: false, renderCell: etaRenderCell },
      { field: 'status', headerName: 'Status', flex: 1, sortable: false, renderCell: statusRenderCell },
    ];
  } else if (width >= 850) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: false },
      { field: 'ordered_by', headerName: 'Ordered by', flex: 1.2, sortable: false },
      { field: 'prepared_by', flex: 1.2, headerName: 'Prepared by', sortable: false },
      { field: 'departure', flex: 1.2, headerName: 'Departure', sortable: false },
      { field: 'arrival', flex: 1.2, headerName: 'Arrival', sortable: false },
      { field: 'eta', headerName: 'ETA', flex: 1, type: 'dateTime', sortable: false, renderCell: etaRenderCell },
      { field: 'status', headerName: 'Status', flex: 1, sortable: false, renderCell: statusRenderCell },
    ];
  } else if (width >= 775) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: false },
      { field: 'ordered_by', headerName: 'Ordered by', flex: 1.2, sortable: false },
      { field: 'prepared_by', flex: 1.2, headerName: 'Prepared by', sortable: false },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: false },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: false },
      { field: 'eta', headerName: 'ETA', flex: 1, type: 'dateTime', sortable: false, renderCell: etaRenderCell },
      { field: 'status', headerName: 'Status', flex: 1, sortable: false, renderCell: statusRenderCell },
    ];
  } else if (width >= 650) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: false },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: false },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: false },
      { field: 'etr', headerName: 'ETR', flex: 1, type: 'dateTime', sortable: false, renderCell: etrRenderCell },
      { field: 'eta', headerName: 'ETA', flex: 1, type: 'dateTime', sortable: false, renderCell: etaRenderCell },
      { field: 'status', headerName: 'Status', flex: 1, sortable: false, renderCell: statusRenderCell },
    ];
  } else {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: false },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: false },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: false },
      { field: 'eta', headerName: 'ETA', flex: 1, type: 'dateTime', sortable: false, renderCell: etaRenderCell },
      { field: 'status', headerName: 'Status', flex: 1, sortable: false, renderCell: statusRenderCell },
    ];
  }
}
