import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ordersAPI, ordersRtAPI, pastOrdersAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { orderAPI, orderStatusReturn } from '@/features/api/copApiEndpoints/orderAPI';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';

export type ComingDelivery = {
  id: string; // ADLC ref
  no: string[]; // Customer ref
  departure: string; // Departure hub
  arrival: string; // Arrival hub
  remaining_time: string | undefined; // Estimated delivery duration until landing
  progress: number; // Progress of the delivery (% of total delivery duration)
  status: string | undefined;
};

export type Delivery = {
  id: string; // ADLC ref
  no: string[]; // Customer ref
  ordered_by: string; // Name + Abbrev
  prepared_by: string; // Name + Abbrev
  unloaded_by: string; // Name + Abbrev
  departure: string; // Departure hub
  arrival: string; // Arrival hub
  etr: string; // Estimated time of readiness (=drone ready to be loaded)
  eta: string; // Estimated time of arrival (=drone landed at arrival hub)
  status: string | undefined; // Status of the order/delivery (in flight, ready, approved, ...)
  payload: PayloadReturn;
  pdfUrl?: string;
};

type modal = {
  id: string;
  value: boolean;
};

export type DeliverySlice = {
  deliveryList: Delivery[] | undefined; //General delivery data from order fetch
  comingDeliveries: ComingDelivery[]; // Deliveries that are ongoing
  plannedDeliveries: Delivery[] | undefined; // Deliveries that are planned
  pastDeliveries: Delivery[]; // Deliveries that are done
};

const initialState: DeliverySlice = {
  deliveryList: [],
  comingDeliveries: [],
  plannedDeliveries: [],
  pastDeliveries: [],
};

export const deliverySlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    resetDeliveryState: (state) => {
      state = initialState;
    },
    updateDeliveryList: (state, action: PayloadAction<Delivery[]>) => {
      state.deliveryList = action.payload;
    },
    updatePlannedDeliveries: (state, action: PayloadAction<Delivery[]>) => {
      state.plannedDeliveries = action.payload;
    },
    updateComingDeliveries: (
      state,
      action: PayloadAction<ComingDelivery[]>
    ) => {
      state.comingDeliveries = action.payload;
    },
    updatePastDeliveries: (state, action: PayloadAction<Delivery[]>) => {
      state.pastDeliveries = action.payload;
    },
  },
  // TODO: fix extraReducer when BE provides seperated API for each list
  extraReducers: (builder) => {
    builder.addMatcher(
      orderAPI.endpoints.getOrders.matchFulfilled,
      (state, action) => {
        state.deliveryList = ordersAPI(action.payload);
      }
    );
    builder.addMatcher(
      orderAPI.endpoints.getComingDeliveries.matchFulfilled,
      (state, action) => {
        const payload = action.payload.filter((item: any) => item?.status != 'pending' && item?.status != 'completed' && item?.status != 'canceled' && item?.status != 'aborted');
        state.comingDeliveries = ordersRtAPI(payload);
      }
    );
    builder.addMatcher(
      orderAPI.endpoints.getPlannedDelivery.matchFulfilled,
      (state, action) => {
        state.plannedDeliveries = ordersAPI(action.payload);
      }
    );
    builder.addMatcher(
      orderAPI.endpoints.getPastDelivery.matchFulfilled,
      (state, action) => {
        state.pastDeliveries = pastOrdersAPI(action.payload);
      }
    );
  },
});

export const {
  resetDeliveryState,
  updateComingDeliveries,
  updatePastDeliveries,
  updatePlannedDeliveries,
} = deliverySlice.actions;

export default deliverySlice.reducer;
