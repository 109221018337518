import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faCalendar,
  faBox,
  faIdBadge,
} from '@fortawesome/free-solid-svg-icons';

import { useAppSelector } from '@/app/hooks';

// Styles
import styles from './StatusSummary.module.scss';
import { useGetOrderCountsQuery } from '@/features/api/copApiEndpoints/orderAPI';

// Definition
const dayString = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const monthString = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const statusToGroupMapping: Record<string, string | undefined> = {
  Pending: 'Non-rank',
  Scheduled: 'Scheduled',
  Approved: 'Scheduled',
  'Ready to Load': 'Loading',
  Loading: 'Loading',
  Loaded: 'Loading',
  'In Flight': 'In Flight',
  'Ready to Unload': 'Unloading',
  Unloading: 'Unloading',
  Canceled: 'Canceled',
  Completed: 'Completed',
  Aborted: 'Canceled',
};

const mainStatusToClassMapping: Record<string, string> = {
  Scheduled: 'Scheduled',
  Loading: 'Loading',
  'In Flight': 'In Flight',
  Unloading: 'Unloading',
  Completed: 'Completed',
  Canceled: 'Canceled',
};

const mainStatusGroupArray = Object.keys(mainStatusToClassMapping);

export function StatusSummary() {
  // Get access to redux states & dispatch
  const mainState = useAppSelector((state) => state.main);
  const dashboardState = useAppSelector((state) => state.dashboard);
  const deliveryList = useAppSelector((state) => state.delivery.deliveryList);

  const { data: getOrderCounts } = useGetOrderCountsQuery({}, { pollingInterval: 10000 });
  
  // const mainStatusGroup = deliveryList.reduce((acc, delivery) => {
  //   const delStatus = delivery.status;
  //   let mappedStatus;
  //   if (delStatus != undefined) {mappedStatus = statusToGroupMapping[delStatus]}
  //   if (mappedStatus && mainStatusGroupArray.includes(mappedStatus)) {
  //     acc[mappedStatus] = (acc[mappedStatus] || 0) + 1;
  //   }
  //   return acc;
  // }, {
  //   Scheduled: 0,
  //   Loading: 0,
  //   'In Flight': 0,
  //   Unloading: 0,
  //   Completed: 0,
  //   Canceled: 0,
  // } as Record<string, number>);

  const mainStatusGroup = {
    Scheduled: dashboardState.statusCounts?.scheduled ?? 0,
    Loading: dashboardState.statusCounts?.loading ?? 0,
    'In Flight': dashboardState.statusCounts?.['in flight'] ?? 0,
    Unloading: dashboardState.statusCounts?.unloading ?? 0,
    Completed: dashboardState.statusCounts?.completed ?? 0,
    Canceled: dashboardState.statusCounts?.canceled ?? 0,
  } as Record<string, number>;

  // Get Date & Time in real-time
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${styles.card_section} ${styles.status_summary}`}>
      <div className={styles.header_wrapper}>
        <div> General information</div>
      </div>
      <div className={styles.body_wrapper}>
        <div className={`${styles.card_info} ${styles.statusbar}`}>
          <FontAwesomeIcon icon={faBox} size="lg" />
          <div className={styles.statusbar_body}>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.scheduled}`}> {mainStatusGroup.Scheduled} </div> Scheduled </div>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.loading}`}> {mainStatusGroup.Loading} </div> Loading </div>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.completed}`}> {mainStatusGroup.Completed} </div> Completed </div>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.unloading}`}> {mainStatusGroup.Unloading} </div> Unloading </div>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.inFlight}`}> {mainStatusGroup['In Flight']} </div> In Flight </div>
            <div className={styles.status}> <div className={`${styles.occurrence} ${styles.canceled}`}> {mainStatusGroup.Canceled} </div> Canceled </div>
          </div>
        </div>
        <div className={styles.genInfo}>
          <div className={`${styles.card_info} ${styles.profile}`}>
            <FontAwesomeIcon icon={faIdBadge} size="lg" />{' '}
            {mainState.profile.name || ''}
          </div>
          <div className={`${styles.card_info} ${styles.time}`}>
            <FontAwesomeIcon icon={faClock} size="lg" />
            {currentDate.getHours().toString().padStart(2, '0') +
              ':' +
              currentDate.getMinutes().toString().padStart(2, '0')}
          </div>
          <div className={`${styles.card_info} ${styles.date}`}>
            <FontAwesomeIcon icon={faCalendar} size="lg" />
            <div className={styles.date_body}>
              <div> {dayString[currentDate.getDay() - 1]} </div>
              <div>
                {currentDate.getDate().toString().padStart(2, '0') +
                  ' ' +
                  monthString[currentDate.getMonth()] +
                  ' ' +
                  currentDate.getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



// {mainStatusGroupArray.map((mainStatus) => (
//   <div className={styles.status} key={mainStatus}>
//     <div
//       className={`${styles.occurrence} `}
//     >
//       {/* {mainStatus} */}
//     </div>
//     {/* {mainStatusGroup.mainStatus} */}
//   </div>
// ))}