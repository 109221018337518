import React, { useState } from 'react';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '../iconButton/IconButton';
import ReportIssueModal from '../../Modal/ReportIssueModal/ReportIssueModal';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateIsModalShown } from './reportIssueSlice';


export function ReportIssueButton(props: {type: 'Hub' | 'Checklist'}) {
  const reportIssue = useAppSelector((state) => state.reportIssue);
  const dispatch = useAppDispatch();
  const [isShowReportIssue, setIsShowReportIssue] = useState<boolean>(false);
 
  // Define IconButton props
  const handleClick = () => {
    setIsShowReportIssue(true);
  };

  const handleClose = () => {
    setIsShowReportIssue(false);
  };

  const handleReportIssue = () => {
    dispatch(updateIsModalShown(true))
  };

  return (
    <div>
      <IconButton
        icon={faCircleExclamation}
        content="Report issue"
        onClick={handleClick}
        color="red"
      />
      {isShowReportIssue && (
        <ReportIssueModal
          open={isShowReportIssue}
          handleClose={handleClose}
          issue={props.type}
        />
      )}
    </div>
  );
}
