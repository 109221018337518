import {
    // faGear,
    // faCircleQuestion,
    IconDefinition,
    faBox,
    faCircleDot,
    faCross,
    // faChartSimple,
    faHouse,
    faReceipt,
    // faUser,
    faVial,
    faXmark,
  } from '@fortawesome/free-solid-svg-icons';
  import React, { useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  
  import { useAppDispatch, useAppSelector } from '@/app/hooks';
  import { ReactComponent as Logo } from '@/media/adlc_logo.svg';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { DigitalClock } from '../DigitalClock/DigitalClock';
  import { updateActivePage } from './sidebarSlice';
  
  // Styles
  import styles from './Sidebar.module.scss';
  import { updateShowMobileMenu } from '@/features/sharedComponents/Sidebar/mobileSidebarSlice'
import { IconButton } from '../Buttons/iconButton/IconButton';
  
  type NavItem = {
    id: string;
    link: string;
    icon: IconDefinition;
  };
  
  const menuItems: NavItem[] = [
    {
      id: 'Dashboard',
      link: '/',
      icon: faHouse,
    },
    {
      id: 'Ordering',
      link: '/ordering',
      icon: faReceipt,
    },
    {
      id: 'Deliveries',
      link: '/deliveries',
      icon: faBox,
    },
    {
      id: 'Hubs',
      link: '/hubs',
      icon: faCircleDot,
    },
    // {
    //   id: 'Reporting',
    //   link: '/reporting',
    //   icon: faChartSimple,
    // },
  ];
  
  const adminItems: NavItem[] = [
    // {
    //   id: 'Users',
    //   link: '/users',
    //   icon: faUser,
    // },
    {
      id: 'Products',
      link: '/products',
      icon: faVial,
    },
  ];
  
  const footerItems: NavItem[] = [
    // {
    //   id: 'Settings',
    //   link: '/settings',
    //   icon: faGear,
    // },
    // {
    //   id: 'Help',
    //   link: '/help',
    //   icon: faCircleQuestion,
    // },
  ];
  
  export function MobileSidebar() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const activePage = useAppSelector((state) => state.sidebar.activePage);
  
    const companyLogoUrl = useAppSelector((state) => state.main.company.img_url);
  
    const pathname = window.location.pathname;
    const mainPath = pathname.split('/')[1];
  
    useEffect(() => {
      const selectedItem = [...menuItems, ...adminItems, ...footerItems].find(
        (navItem) => navItem.link.includes(mainPath)
      );
      if (selectedItem?.id !== activePage && selectedItem?.id) {
        dispatch(updateActivePage(selectedItem?.id));
      }
    }, [activePage, dispatch, mainPath]);
  
    const navigateTo = (
      event: React.MouseEvent<HTMLImageElement>,
      route: string,
      page: string
    ) => {
      navigate(route);
      dispatch(updateActivePage(page));
    };
  
    const onClick =
      (arg1: string, arg2: string) =>
      (event: React.MouseEvent<HTMLImageElement>) => {
        navigateTo(event, arg1, arg2);
        dispatch(updateShowMobileMenu(false))
      };
  
    const hideMobileMenu = () =>{
      dispatch(updateShowMobileMenu(false))
    }
    return (
      <div className={styles.mobileSidebar}>
        {/* <div
          className={styles.header_wrapper}
          onClick={onClick('/', 'Dashboard')}
        >
          {companyLogoUrl ? (
            <img
              src={companyLogoUrl}
              alt="Logo"
              onClick={hideMobileMenu}
              className={styles.header_logo}
            />
          ) : (
            <Logo className={styles.header_logo} />
          )}
        </div> */}
        <div className={styles.body_wrapper}>
          <div className={styles.body_section}>
            <div className={`${styles.section_title} ${styles.item_link_label}`}>
              General
            </div>
            <div className={styles.section_nav}>
              {menuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.id}
                  menuItem={menuItem}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
          <div className={styles.body_section}>
            <div className={`${styles.section_title} ${styles.item_link_label}`}>
              Admin
            </div>
            <div className={styles.section_nav}>
              {adminItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.id}
                  menuItem={menuItem}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          {/* <DigitalClock /> */}
          <div className={styles.footer}>
            {/* {footerItems.map((menuItem) => (
              <MenuItem key={menuItem.id} menuItem={menuItem} onClick={onClick} />
            ))} */}
            <IconButton
              icon={faXmark}
              content=""
              onClick={() => dispatch(updateShowMobileMenu(false))}
              // rotate="rotate180"
              // noBorder={true}
              color='mobileMenu'
            />
          </div>
        </div>
      </div>
    );
  }
  
  function MenuItem({
    menuItem,
    onClick,
  }: {
    menuItem: NavItem;
    onClick: (
      arg1: string,
      arg2: string
    ) => (event: React.MouseEvent<HTMLImageElement>) => void;
  }) {
    const sidebar = useAppSelector((state) => state.sidebar);
  
    return (
      <div
        className={`${sidebar.activePage === menuItem.id && styles.active_item}`}
        onClick={onClick(menuItem.link, menuItem.id)}
      >
        {/* <FontAwesomeIcon icon={menuItem.icon} fixedWidth /> */}
        <span className={styles.item_link_label}>{menuItem.id}</span>
      </div>
    );
  }
  