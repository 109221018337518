import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';
import { hubType } from '../hubSlice';
import { goodsItem } from './orderingSlice';

export type fastOrderingState = {
  goodsList: goodsItem[];
  hubs: { departure: hubType | undefined; arrival: hubType | undefined };
  dateTime: string;
  payload?: PayloadReturn;
  asap: boolean;
};

// InitialState
const initPayload = { product: null, container: null, quantity: null };

const initialState: fastOrderingState = {
  hubs: { departure: undefined, arrival: undefined },
  goodsList: [{ id: 0, payload: initPayload }],
  dateTime: '',
  asap: true,
};

/* ------------------------------------- SLICE ------------------------------------ */
export const fastOrderingSlice = createSlice({
  name: 'fastOrdering',
  initialState,
  reducers: {
    updateFastOrderingHubs: (
      state,
      action: PayloadAction<{ id: string; hub: hubType }>
    ) => {
      const hubID = action.payload.id;
      const hub = action.payload.hub;
      switch (hubID) {
        case 'departure': {
          state.hubs.departure = hub;
          break;
        }
        case 'arrival': {
          state.hubs.arrival = hub;
          break;
        }
      }
    },
    updateFastOrderingPayload: (
      state,
      action: PayloadAction<{
        payloadType: string;
        payloadItem: productType_ogc | containerType_ogc | number;
      }>
    ) => {
      switch (action.payload.payloadType) {
        case 'product': {
          state.goodsList[0].payload.product = action.payload.payloadItem as productType_ogc;
          break;
        }
        case 'container': {
          state.goodsList[0].payload.container = action.payload.payloadItem as containerType_ogc;
          break;
        }
        case 'quantity': {
          state.goodsList[0].payload.quantity = action.payload.payloadItem as number;
          break;
        }
      }
    },
    resetFastOrdering: (state) => {
      state.dateTime = initialState.dateTime;
      state.goodsList = initialState.goodsList;
      state.hubs = initialState.hubs;
      state.payload = initialState.payload;
    },
  },
});

export const {
  updateFastOrderingHubs,
  updateFastOrderingPayload,
  resetFastOrdering,
} = fastOrderingSlice.actions;

export default fastOrderingSlice.reducer;
