import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faLocationDot,
  faCircleDot,
} from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateHubs } from '@/app/stores/dataSlice/orderingSlice';
import { hubType } from '@/app/stores/hubSlice';
import AutocompleteSI from '../../../../../../sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';

// Styles
import styles from './OrderHubPicker.module.scss';

export type OrderHubPickerProps = {
  id: string, // departure or arrival
};

export function OrderHubPicker(props: OrderHubPickerProps) {
  const dispatch = useAppDispatch();
  const orderingState = useAppSelector((state) => state.ordering);
  const hubState = useAppSelector((state) => state.hub);

  const { id } = props;
  const [selectHub, setSelectHub] = useState(false);

  const displayMenu = () => {
    setSelectHub(true);
  };

  const hideMenu = () => {
    setSelectHub(false);
  };

  let autoCompleteOptions = hubState.hubList;
  if (id == 'arrival') {
    autoCompleteOptions = hubState.hubList.filter(
      (item) => item !== orderingState.newOrder.hubs.departure
    )
  }

  return (
    <div className={styles.card_hubSel}>
      {selectHub !== true && (
        <div className={styles.card_edit}>
          <div className={styles.button_card_edit} onClick={displayMenu}>
            <FontAwesomeIcon icon={faPen} size="xs" />
          </div>
        </div>
      )}
      <div className={styles.card_wrapper}>
        {selectHub && (
          <div className={styles.card_menu}>
            <div className={styles.menu_title}>
              Select the adequate {id} location
            </div>
            <AutocompleteSI
              autocompleteParam={{
                id: 'customized-hook-single-name-input',
                defaultValue: null,
                multiple: false,
                options: autoCompleteOptions,
                getOptionLabel: (option) => option.name,
                getOptionDisabled: undefined,
              }}
              setInputValue={(inputValue: hubType) => {
                dispatch(updateHubs({ id, hub: inputValue }));
                hideMenu();
              }}
            />
          </div>
        )}
        <div className={styles.card_status}>
          {id === 'departure' && (
            <div className={styles.current_hub}>
              <FontAwesomeIcon icon={faCircleDot} size="sm" />
              {orderingState.newOrder.hubs.departure &&
                orderingState.newOrder.hubs.departure.name}
            </div>
          )}
          {id === 'arrival' && (
            <div className={styles.current_hub}>
              <FontAwesomeIcon icon={faLocationDot} />
              {orderingState.newOrder.hubs.arrival &&
                orderingState.newOrder.hubs.arrival.name}
            </div>
          )}
        </div>
        <div className={styles.card_bg}>
          {id === 'departure' && (
            <img
              className={styles.hub_image}
              src={
                orderingState.newOrder.hubs.departure &&
                orderingState.newOrder.hubs.departure.picture
              }
              alt="Departure hub"
            />
          )}
          {id === 'arrival' && (
            <img
              className={styles.hub_image}
              src={
                orderingState.newOrder.hubs.arrival &&
                orderingState.newOrder.hubs.arrival.picture
              }
              alt="Arrival hub"
            />
          )}
        </div>
      </div>
    </div>
  );
}
