import React, { useEffect, useState } from 'react';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DelayingAppearance from '@/features/sharedComponents/Modal/LoadingUI';
import { LoadingStatus } from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';
import {
  useCreateProductMutation,
  useGetProductsQuery,
} from '@/features/api/copApiEndpoints/productAPI';

import styles from './AddProductCard.module.scss';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { faAdd, faCancel, faCircleXmark, faPaperPlane, faPlus, faRotateForward } from '@fortawesome/free-solid-svg-icons';
import Input from '@mui/joy/Input/Input';
import FormLabel from '@mui/joy/FormLabel/FormLabel';
import FormControl from '@mui/joy/FormControl/FormControl';
import Select from '@mui/joy/Select/Select';
import Option from '@mui/joy/Option';

type ProductInputProps = {
  value: string | string[];
  label: string;
  inputType: React.InputHTMLAttributes<HTMLInputElement>['type'];
  onChange: (event: any) => void;
  required?: boolean;
};

export default function AddProductCard({ open, onClose }: {
  open: boolean;
  onClose: () => void;
}) {
  const { refetch: refetchProduct } = useGetProductsQuery({}, { skip: true });
  const [createProduct, createProductResult] = useCreateProductMutation();

  const [query, setQuery] = useState<LoadingStatus>('idle');
  const [err, setErr] = useState<string>('');
  const [unNumber, setUnNumber] = useState<string>();
  const [name, setName] = useState<string>();
  const [properShippingName, setProperShippingName] = useState<string>();
  const [density, setDensity] = useState<number | undefined>();
  const [primaryClass, setPrimaryClass] = useState<string>();
  const [subsidiaryClass, setSubsidiaryClass] = useState<string[]>();
  const [packingGroup, setPackingGroup] = useState<string>();

  useEffect(() => {
    if (createProductResult.isSuccess) {
      setQuery('success');
      // refetchProduct();
    }
    if (createProductResult.isError) {
      setQuery('failure');
      setErr((createProductResult.error as Record<any, any>).data.message);
    }
  },[
    createProductResult.data,
    createProductResult.error,
    createProductResult.isError,
    createProductResult.isSuccess,
    refetchProduct,
  ]);

  const resetInput = () => {
    setUnNumber('');
    setName('');
    setDensity(undefined);
    setPrimaryClass('');
    setSubsidiaryClass([]);
    setPackingGroup('');
    setErr('');
  };

  const handleClose = () => {
    // clear local state
    resetInput();
    onClose();
  };

  const handleAddProduct = () => {
    if (unNumber == undefined||
      name == undefined ||
      density == undefined ||
      primaryClass == undefined ||
      packingGroup == undefined ||
      properShippingName == undefined
    ) {
      setErr('Please input all required properties...');
    } else {
      setQuery('progress');
      setErr('');
      const newProduct = {
        name,
        density,
        hazards: subsidiaryClass
          ? [primaryClass].concat(subsidiaryClass)
          : [primaryClass],
        unNumber,
        packingGroup,
        properShippingName,
      };
      createProduct(newProduct);
    }
  };

  return (
    <div className={styles.addProductModal}>
      {query === 'idle' && (
        <>
          <div className={styles.header}>
            <div>
              <p>Add new product</p>
            </div>
            <div className={styles.buttonGroup}>
              < IconButton
                // icon={''}
                content='Cancel'
                onClick={handleClose}
                rotate="rotate180"
                // reversed={true}
                color='unset_blue'
                noBorder={true}
              />
              < IconButton
                icon={faPaperPlane}
                content=''
                onClick={handleAddProduct}
                reversed={true}
              />
            </div>
          </div>
          <div >
            <div className={styles.addProductBody}>
              <ProductInput
                label="UN n°"
                inputType="text"
                onChange={(e) => setUnNumber(e.target.value)}
                value={unNumber ? unNumber : ''}
                required
              />
              <ProductInput
                label="Name"
                inputType="text"
                onChange={(e) => setName(e.target.value)}
                value={name ? name : ''}
                required
              />
              <ProductInput
                label="Proper Shipping Name"
                inputType="text"
                onChange={(e) => setProperShippingName(e.target.value)}
                value={properShippingName ? properShippingName : ''}
                required
              />
              <ProductInput
                label="Density"
                inputType="number"
                onChange={(e) => {setDensity(parseInt(e.target.value))}}
                value={density?.toString() || ''}
                required
              />
              <ProductInput
                label="Primary class"
                inputType="text"
                onChange={(e) => setPrimaryClass(e)}
                value={primaryClass ? primaryClass : ''}
                required
              />
              <ProductInput
                label="Subsidiary class(es)"
                inputType="text"
                onChange={(e) => setSubsidiaryClass(e)}
                value={subsidiaryClass ? subsidiaryClass : []}
              />
              <ProductInput
                label="Packing group"
                inputType="text"
                onChange={(e) => setPackingGroup(e)}
                required
                value={packingGroup ? packingGroup : ''}
              />
            </div>
            {err ? <div className={styles.errorMsg}>{err}</div> : null}
          </div>
        </>
      )}
      {query === 'failure' && (
        <>
          <div className={styles.header}>
            <p>Add new product</p>
            <div className={styles.buttonGroup}>
              < IconButton
                  // icon={''}
                  content='Close'
                  onClick={handleClose}
                  rotate="rotate180"
                  // reversed={true}
                  color='unset_blue'
                  noBorder={true}
                />
                < IconButton
                  icon={faRotateForward}
                  content='Try again'
                  onClick={() => {
                    setQuery('idle');
                    setErr('');
                  }}
                  reversed={true}
                />
              </div>
          </div>
          <div className={styles.content}>
            <div className={styles.errMsg}>
              <span> <FontAwesomeIcon icon={faCircleXmark} size="1x" /> </span>
              {err}
            </div>
            {/* {err ? <div className={styles.errorMsg}>{err}</div> : null} */}
          </div>
          <div className={styles.footer}>
          </div>
        </>
      )}
      {query === 'success' && (
        <>
          <div className={styles.header}>
            <p>Add new product</p>
            <div className={styles.buttonGroup}>
              < IconButton
                  // icon={''}
                  content='Close'
                  onClick={handleClose}
                  rotate="rotate180"
                  // reversed={true}
                  color='unset_blue'
                  noBorder={true}
                />
                < IconButton
                  icon={faPlus}
                  content=''
                  onClick={() => {
                    setQuery('idle')
                  }}
                  reversed={true}
                />
              </div>
          </div>
          <div className={styles.content}>
            <div className={styles.succMsg}>
              <span> <FontAwesomeIcon icon={faCircleCheck} size="1x" /> </span>
              <span> Your product request will be treated !</span>
            </div>
          </div>
          <div className={styles.footer}>
          </div>
        </>
      )}
      {query === 'progress' && <DelayingAppearance/>}
    </div>
  );
}

const ProductInput = (props: ProductInputProps) => {
  const { label, inputType = 'text', onChange, required, value } = props;

  return (
    <div className={styles.productInput}>
      <FormControl>
        <FormLabel sx={{fontSize: styles.fontSize, fontFamily: styles.fontFamily}}>{label}{required ? '*' : ''}</FormLabel>
        {(label != 'Packing group' && label != 'Primary class' && label != 'Subsidiary class(es)') && <Input
          required
          sx={{width: '170px'}}
          size='sm'
          onChange={(e) => {onChange(e)}}
          endDecorator={label == 'Density' ? 'kg/L' : ''}
        />}
        {label == 'Packing group' && <Select 
        sx={{width: '170px'}} 
        size='sm' 
        placeholder='Select one...'
        onChange={(event: React.SyntheticEvent | null, newValue: string | null) => onChange(newValue)}
        >
          <Option value="1"> I </Option>
          <Option value="2"> II </Option>
          <Option value="3"> III </Option>
        </Select>}
        {(label == 'Primary class' || label == 'Subsidiary class(es)') && <Select 
          multiple={(label == 'Subsidiary class(es)') ? true : false}
          sx={{width: '170px'}} 
          size='sm'
          placeholder={(label == 'Subsidiary class(es)') ? "Select multiple..." : "Select one..."}
          onChange={(event: React.SyntheticEvent | null, newValue: string | string[] | null) => onChange(newValue)}
          >
          <Option value="1.1"> 1.1 </Option>
          <Option value="1.2"> 1.2 </Option>
          <Option value="1.3"> 1.3 </Option>
          <Option value="1.4"> 1.4 </Option>
          <Option value="1.5"> 1.5 </Option>
          <Option value="1.6"> 1.6 </Option>
          <Option value="2.1"> 2.1 </Option>
          <Option value="2.2"> 2.2 </Option>
          <Option value="2.3"> 2.3 </Option>
          <Option value="3"> 3 </Option>
          <Option value="4.1"> 4.1 </Option>
          <Option value="4.2"> 4.2 </Option>
          <Option value="4.3"> 4.3 </Option>
          <Option value="5.1"> 5.1 </Option>
          <Option value="5.2"> 5.2 </Option>
          <Option value="6.1"> 6.1 </Option>
          <Option value="8"> 8 </Option>
        </Select>}
      </FormControl>
    </div>
  );
};
