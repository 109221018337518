import React from 'react';

import styles from './BasicButton.module.scss';

export type BasicButtonProps = {
  content: string,
  onClick: () => void;
}

export function BasicButton(props: BasicButtonProps) {
  const { content, onClick } = props;

  return (
    <div className={styles.button_basic} onClick={onClick}>
      {content}
    </div>
  );
}