import LoadingUI from "../Modal/LoadingUI";

import styles from './AuthLoading.module.scss'

export function AuthLoading() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingUI color="black" />
      <div className={styles.message}>Checking your authentication...</div>
    </div>
  );
}
