import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { updateActivePage } from '@/features/sharedComponents/Sidebar/sidebarSlice';
import { Table } from '@/features/sharedComponents/Table/Table';

import { colTable } from './basicColTab';

// Styles
import styles from './BasicTab.module.scss';

export function BasicTab() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const deliveryState = useAppSelector((state) => state.delivery);

  // Get component width dynamically
  const ref = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);
  let columns: GridColDef<any, any, any>[] = colTable(w);

  useEffect(() => {
    const handleResize = () => {
      setW(ref.current!.offsetWidth);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      columns = colTable(w);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define Table props
  const CustomToolbar:
    | React.JSXElementConstructor<any>
    | null
    | undefined = () => {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarQuickFilter /> */}
      </GridToolbarContainer>
    );
  };

  // Define BasicButton props
  const handleClick = () => {
    navigate('/deliveries');
    dispatch(updateActivePage('Deliveries'));
  };

  const displayedPlannedDeliveries = deliveryState.plannedDeliveries
    ?.filter((delivery) => delivery.eta)
    ?.sort(
      (delA, delB) =>
        new Date(delA.eta).getTime() - new Date(delB.eta).getTime()
    );

  return (
    <div ref={ref} className={`${styles.card_section} ${styles.basicTable}`}>
      <div className={styles.header_wrapper}>
        <div>Your next deliveries...</div>
        <IconButton
          icon={faArrowUpRightFromSquare}
          content=""
          onClick={handleClick}
        />
      </div>
      <div className={styles.body_wrapper}>
        <Table
          columns={columns}
          data={displayedPlannedDeliveries}
          colTools
          toolbar={CustomToolbar}
          tableHeight="290px"
          initState={[{ field: 'eta', sort: 'asc' }]}
        />
      </div>
    </div>
  );
}
