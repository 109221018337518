import React, { useEffect, useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

import styles from './GroundHandlingOperation.module.scss';
import { v4 as uuidv4 } from "uuid";

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { faArrowLeft, faArrowRight, faArrowRightFromBracket, faCircleExclamation, faCircleQuestion, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Card from '../Checklist/Card';
import TakeOffModal from '../TakeOff/TakeOffModal';
import StopGroundHandlingModal from '../StopGroundHandling/StopGroundHandlingModal';
import { mapGroundHandlingOperationType } from '../../GroundHandlingFunctions';
import { ReactComponent as Logo } from '@/media/adlc_logo_icon_w.svg';
import { Topbar } from '@/features/sharedComponents/Topbar/Topbar';
import LongPressButton from '../TakeOff/LongPressButton';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import adlc_logo from '@/media/adlc_logo_horizontal.svg'
import { GroundHandlingActionParams, useGetFillingPatternQuery, usePostEndGroundHandlingOperationMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportIssueButton } from '@/features/sharedComponents/Buttons/ReportIssueButton/ReportIssueButton';


export function GroundHandlingOperation () {
    /* GroundHandlingOperation displays the checklist according to its current progress, and the current evolution in the ongoing Ground Handling Operation. */

    const groundHandlingState = useAppSelector((state) => state.groundHandling);
    const groundHandlingOperationType = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.type;
    const orderId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId;
    const dispatch = useAppDispatch();
    // const { operationType } = useParams();
    const [offsetCarousel, setOffsetCarousel] = useState<number>(4);
    const [checkedTasks, setCheckedTasks] = useState<string[]>([]);
    const [isShowTakeOffModal, setIsShowTakeOffModal] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isShowStopPrepareModal, setIsShowStopPrepareModal] = useState(false);
    let status = groundHandlingState.ongoingGroundHandlingOperation.status;
    let currentChecklistIndex = groundHandlingState.ongoingGroundHandlingOperation.index.currentList;
    let currentChecklist = groundHandlingState.ongoingGroundHandlingOperation.checklists[currentChecklistIndex-1];
    let amountChecklists = groundHandlingState.ongoingGroundHandlingOperation.checklists.length;
    const [show, setShown] = useState(false);
    const [takeOff, setTakeOff] = useState(false);
    const [postEndGroundHandlingOperation, postEndGroundHandlingOperationResult] = usePostEndGroundHandlingOperationMutation(); 
    const { isLoading: getFillingPatternLoading, isError: getFillingPatternErr } = useGetFillingPatternQuery({orderId: orderId});


    const handleReportIssue = () => {
      console.log('Some issues to report');
    };
    
    const handleSubmit = () => {
      setIsShowTakeOffModal(true);
      setIsSubmitLoading(true);
      // insert BE API code here
      setTimeout(() => setIsSubmitLoading(false), 1000);
    };

    // let cards = currentChecklist.actions.map((action) => {
    //   return {
    //       key: uuidv4(),
    //       content: (<Card action={action} />)
    //   }
    // });

    // useEffect(() => {
    //   currentChecklist = groundHandlingState.ongoingGroundHandlingOperation.checklists[currentChecklistIndex-1];
    //   cards = currentChecklist.actions.map((element) => {
    //         return {
    //             key: uuidv4(),
    //             content: (<Card 
    //               step={element.step}
    //               title={element.title}
    //               items={element.items}
    //               image={element?.image}
    //             />)
    //         }
    //       });
    // }, [groundHandlingState.ongoingGroundHandlingOperation.index]);


    const props3 = useSpring({
      transform: show ? 'scale(1.03)' : 'scale(1)',
      boxShadow: show
        ? '0 20px 25px rgb(0 0 0 / 25%)'
        : '0 2px 10px rgb(0 0 0 / 8%)',
    });

    const handleLongPress = () => {
      setTakeOff(true);
      const paramsMutation: GroundHandlingActionParams = {orderId: orderId, type: groundHandlingOperationType};
      postEndGroundHandlingOperation(paramsMutation);
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <IconButton
              icon={faArrowRightFromBracket}
              content=""
              onClick={() => setIsShowStopPrepareModal(true)}
              rotate="rotate180"
              noBorder={true}
              color='unset'
            />
            {mapGroundHandlingOperationType(groundHandlingOperationType)}
        </div>
        <div className={styles.subtitle}> 
          <div className={styles.info}>
            <div className={styles.item}>
              <div className={styles.item_title}>Order reference </div>
              <div className={styles.item_body}>{groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderRef}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.item_title}>Current phase </div>
              <div className={styles.item_body}>{currentChecklist.title}</div>
            </div>
          </div>
          <div className={styles.menuBar}>
            <ReportIssueButton type='Checklist'/>
          </div>
        </div>
        {(status[0] == undefined && status[1] == 'ONGOING') || (status[0] == 'ONGOING' && status[1] == 'ONGOING') ? 
        ( // DURING A GROUND HANDLING OPERATION
          <div className={styles.checklist_wrapper}>
            <div className={styles.checklist_body}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'max-content', height: '500px', margin: '0 auto' }}>
                <Card/>
              </div>
            </div>
          </div>
        ) : (// END OF GROUND HANDLING OPERATION
          <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', justifyContent: 'center', width: '100%', height: '400px'}}>
            {takeOff ? (// after TAKE OFF CONFIRMATION
              <>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                    <CircularProgress color="danger" sx={{ '--CircularProgress-size': '80px'}}>
                      <FontAwesomeIcon icon={faTriangleExclamation} color='rgb(190,0,0)' size='xl'/>
                    </CircularProgress>
                  </Box>
                  <div style={{
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    fontSize: '35px',
                    textAlign:'center',
                    color: 'rgb(190,0,0)',
                  }}> 
                    Standby for Take-Off ! 
                  </div>
              </>
            ):(// before TAKE OFF CONFIRMATION
              <>
                <div style={{position: 'absolute', left: 'center', top: 'center'}}>
                  <div style={{width: '260px', height: '260px', borderRadius: '50%', backgroundColor: 'rgb(255, 230, 230)'}}/>
                </div>
                <div style={{position: 'absolute', left: 'center', top: 'center'}}>
                  <div style={{width: '210px', height: '210px', borderRadius: '50%', backgroundColor: 'rgb(255, 196, 196)'}}/>
                </div>
                <div className={styles.button}>
                  <LongPressButton duration={1000} onLongPress={handleLongPress} />
                </div>
              </>
            )}
          </Box>
        )} 
        <TakeOffModal open={isShowTakeOffModal} isLoading={isSubmitLoading} />
        <StopGroundHandlingModal
          open={isShowStopPrepareModal}
          handleClose={() => setIsShowStopPrepareModal(false)}
        /> 
      </div>
    ) 
}