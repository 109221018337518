import { useState } from 'react';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '../authConfig';
import Cookies from "js-cookie";

type SignInState = {
  isLoading: boolean;
  isError: boolean | undefined;
  isSuccess: boolean | undefined;
  errorMessage: any;
  successMessage: any;
};

export function useSignIn(): [() => Promise<void>, SignInState] {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);

  const signIn = async () => {
    setIsLoading(true);
    setError(null);
    setResponse(null);
    try {
      const myMSALObj = new PublicClientApplication(msalConfig);
      await myMSALObj.initialize();
      const loginResponse = await myMSALObj.loginPopup(loginRequest);
      const accessToken = loginResponse?.accessToken;
      Cookies.set('accessToken', accessToken, {
        secure: true,
        sameSite: 'strict',
        expires: loginResponse.expiresOn || undefined
      });
      setResponse(loginResponse);
      setIsSuccess(true);
      console.log('Successfully logged in!');
    } catch (error) {
      setIsError(true);
      setError(error);
      console.log('Something went wrong when logging in!', error);
    } finally {
      setIsLoading(false);
    }
  };

  return [
    signIn,
    {
      isLoading,
      isError,
      isSuccess,
      errorMessage: error,
      successMessage: response,
    },
  ];
}

export async function renewToken() {
  const myMSALObj = new PublicClientApplication(msalConfig);
  await myMSALObj.initialize();
  try {
    const silentResult = await myMSALObj.acquireTokenSilent(loginRequest);
    const accessToken = silentResult?.accessToken;
    Cookies.set('accessToken', accessToken, {
      secure: true,
      sameSite: 'strict',
      expires: silentResult.expiresOn || undefined
    });
    console.log('Successfully renewed token!');
  } catch (err) {
    console.log('Failed to renew token silently', err);
    if (err instanceof InteractionRequiredAuthError) {
      myMSALObj.acquireTokenRedirect(loginRequest);
    }
  }
};