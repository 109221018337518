import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  color?: string;
};

export default function DelayingAppearance(props: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="hello_welcome"
    >
      <CircularProgress sx={{ color: props.color || 'white' }} />
    </Box>
  );
}
