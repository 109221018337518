import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';



// InitialState type
export type reportIssueState = {
  isModalShown: boolean;
}

const initialState: reportIssueState = {
  isModalShown: false,
};


export const reportIssueSlice = createSlice({
  name: 'reportIssue',
  initialState,
  reducers: {
    updateIsModalShown: (state, action: PayloadAction<boolean>) => {
      state.isModalShown = action.payload;
    },
  }
});

export const { updateIsModalShown } = reportIssueSlice.actions;

export default reportIssueSlice.reducer;

export {}