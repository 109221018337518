import { ComingDelivery } from "@/app/stores/deliverySlice";

export const sortComingDelivery = (data: ComingDelivery[]) => {
    return data.slice().sort((a, b) => {
      // Convert age strings to numbers, treat undefined as NaN
      const ageA = a.remaining_time !== undefined ? parseInt(a.remaining_time) : NaN;
      const ageB = b.remaining_time !== undefined ? parseInt(b.remaining_time) : NaN;
  
      // Handle NaN values (undefined or non-numeric strings)
      if (isNaN(ageA) && isNaN(ageB)) {
        return 0;
      } else if (isNaN(ageA)) {
        return 1; // Place undefined or non-numeric strings at the end
      } else if (isNaN(ageB)) {
        return -1; // Place undefined or non-numeric strings at the end
      }
  
      return ageA - ageB;
    });
  };