import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import styles from './Card.module.scss';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { 
  Action,
  Checklist,
  groundHandlingState, 
  updateOngoingGroundHandlingOperationIndex, 
  updateOngoingGroundHandlingOperationStatus } 
  from '@/app/stores/dataSlice/groundHandlingSlice';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { faArrowLeft, faArrowRight, faArrowUpRightFromSquare, faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { ChecklistProgressBody, ChecklistProgressParams, usePostChecklistProgressMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { GroundHandlingActionParams, usePostEndGroundHandlingOperationMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import ListCard from './ListCard';
import LongpressIconButton from '@/features/sharedComponents/Buttons/LongpressIconButton/LongpressIconButton';
import { Box, CircularProgress } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import image_src from '@/media/DSC01753-HD.jpg';

type CardProps = {action: Action};

const Card: React.FC = () => {
  const navigate = useNavigate();
  const [show, setShown] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const groundHandlingState = useAppSelector((state) => state.groundHandling);
  const groundHandlingOperationType = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.type;
  const orderId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId;
  const dispatch = useAppDispatch();
  const [postChecklistProgress, postChecklistProgressResult] = usePostChecklistProgressMutation(); // Use the hook here
  let currentChecklist = groundHandlingState.ongoingGroundHandlingOperation.index.currentList;
  let amountChecklists = groundHandlingState.ongoingGroundHandlingOperation.checklists.length;
  let currentTask = groundHandlingState.ongoingGroundHandlingOperation.index.currentTask;
  let amountTasks = groundHandlingState.ongoingGroundHandlingOperation.checklists[currentChecklist-1].actions.length;
  let groundHandlingOperation = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation;
  const [postEndGroundHandlingOperation, postEndGroundHandlingOperationResult] = usePostEndGroundHandlingOperationMutation(); 

  const checklist = groundHandlingState.ongoingGroundHandlingOperation.checklists[currentChecklist-1];
  const actionTest = checklist.actions[currentTask-1];

  const props3 = useSpring({
    transform: show ? 'scale(1.03)' : 'scale(1)',
    boxShadow: show
      ? '0 20px 25px rgb(0 0 0 / 25%)'
      : '0 2px 10px rgb(0 0 0 / 8%)',
  });

  const NextTask = () => {
    dispatch(
      updateOngoingGroundHandlingOperationIndex({ 
        currentList: currentChecklist,
        currentTask: currentTask + 1
      })
    ) 
    const params: ChecklistProgressParams = {orderId: groundHandlingOperation.orderId, type: groundHandlingOperation.type};
    const body: ChecklistProgressBody = {progress: `${String.fromCharCode(currentChecklist + 64)}${currentTask + 1}` };
    postChecklistProgress({ params, body }); // Use the mutation here
    setDisableButton(false)
  }
  const PreviousTask = () => {
    dispatch(
      updateOngoingGroundHandlingOperationIndex({ 
        currentList: currentChecklist,
        currentTask: currentTask - 1
      })
    ) 
    const params: ChecklistProgressParams = {orderId: groundHandlingOperation.orderId, type: groundHandlingOperation.type};
    const body: ChecklistProgressBody = {progress: `${String.fromCharCode(currentChecklist + 64)}${currentTask - 1}` };
    postChecklistProgress({ params, body }); // Use the mutation here
    setDisableButton(false)
  }
  const PreviousChecklist = () => {
    const prevTask = groundHandlingState.ongoingGroundHandlingOperation.checklists[currentChecklist-2].actions.length;
    dispatch(
      updateOngoingGroundHandlingOperationIndex({ 
        currentList: currentChecklist - 1,
        currentTask: prevTask
      })
    ) 
    const params: ChecklistProgressParams = {orderId: groundHandlingOperation.orderId, type: groundHandlingOperation.type};
    const body: ChecklistProgressBody = {progress: `${String.fromCharCode(currentChecklist-1 + 64)}${prevTask}` };
    postChecklistProgress({ params, body }); // Use the mutation here
    setDisableButton(false)
  }
  const CompleteList = () => {
    if (currentChecklist < amountChecklists) {
      dispatch(
        updateOngoingGroundHandlingOperationIndex({ 
          currentList: currentChecklist + 1,
          currentTask: 1
        })
      ) 
      const params: ChecklistProgressParams = {orderId: groundHandlingOperation.orderId, type: groundHandlingOperation.type};
      const body: ChecklistProgressBody = {progress: `${String.fromCharCode((currentChecklist+1) + 64)}${1}` };
      postChecklistProgress({ params, body }); // Use the mutation here
    } else {
      dispatch(
        updateOngoingGroundHandlingOperationStatus(['ONGOING', 'COMPLETED'])
      )
      if (groundHandlingOperationType == ('END_DELIVERY' || 'END_REPOSITION')) {
        const paramsMutation: GroundHandlingActionParams = {orderId: orderId, type: groundHandlingOperationType};
        postEndGroundHandlingOperation(paramsMutation);
        navigate('/hubs');
      }
    }
    setDisableButton(false)
  }
  const disableCardButton = () => {
    setDisableButton(true)
  }
  const activateCardButton = () => {
    setDisableButton(false)
  }

  return (
    <>
      <animated.div
        className={`${styles.card} ${styles[`list${currentChecklist}`]}`}
        style={props3}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        {!postChecklistProgressResult.isLoading && <><div className={styles.header}> 
          {actionTest.title} 
        </div>
        <div className={styles.content}><ListCard items={actionTest.items} orderId={orderId} disableCardButton={disableCardButton} activateCardButton={activateCardButton}/></div>
        <div className={styles.footer}>
          {actionTest?.image && <img className={styles.img} src={actionTest.image} alt=''/>}
          <div className={styles.btnn}>
              {currentChecklist > 1 ? (
                <>
                  {(currentTask == 1 && amountTasks != 1) &&<>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousChecklist}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton
                      icon={faArrowRight}
                      content="Next"
                      onClick={NextTask}
                      reversed={true}
                      disabled={disableButton}
                    />
                  </>}
                  {(currentTask == 1 && amountTasks == 1) &&<>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousChecklist}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton 
                      icon={faCheck}
                      content="Complete"
                      onClick={CompleteList}
                      reversed={false}
                      disabled={disableButton}
                    /> 
                  </>}
                  {(currentTask > 1 && currentTask < amountTasks) && <>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousTask}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton
                      icon={faArrowRight}
                      content="Next"
                      onClick={NextTask}
                      reversed={true}
                      disabled={disableButton}
                    />
                  </>}
                  {(currentTask > 1 && currentTask == amountTasks) && <>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousTask}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton 
                      icon={faCheck}
                      content="Complete"
                      onClick={CompleteList}
                      reversed={false}
                      disabled={disableButton}
                    /> 
                  </>}
                </>
              ):(
                <>
                  {(currentTask == 1 && amountTasks != 1) &&
                  <IconButton
                    icon={faArrowRight}
                    content="Next"
                    onClick={NextTask}
                    reversed={true}
                  />}
                  {(currentTask == 1 && amountTasks == 1) &&
                  <IconButton 
                    icon={faCheck}
                    content="Complete"
                    onClick={CompleteList}
                    reversed={false}
                  /> 
                  }
                  {(currentTask > 1 && currentTask < amountTasks) && <>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousTask}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton
                      icon={faArrowRight}
                      content="Next"
                      onClick={NextTask}
                      reversed={true}
                    />
                  </>}
                  {(currentTask > 1 && currentTask == amountTasks) && <>
                    <IconButton
                      icon={faArrowLeft}
                      content="Previous"
                      onClick={PreviousTask}
                      reversed={false}
                      color='unset'
                      noBorder={true}
                    />
                    <IconButton 
                      icon={faCheck}
                      content="Complete"
                      onClick={CompleteList}
                      reversed={false}
                    /> 
                  </>}
                </>
              )}
            </div>
        </div></>}
      </animated.div>
      {postChecklistProgressResult.isLoading && <> 
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <CircularProgress variant='solid' color="primary" sx={{ '--CircularProgress-size': '50px' }} />
        </Box>
      </>}
    </>
  );
};

export default Card;
