import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groundHandlingAPI, GroundHandlingCountsReturn } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { getFillingPatternAdapterAPI, getGroundOperationAdapterAPI } from '@/features/api/copApiAdapters/groundHandlingAdapterAPI';
import { fetchCheclistsDataAndMap } from '@/features/functions/downloadJsonFile';
import store from '@/app/store'; // Replace with the actual path
import { convertChecklistProgress } from '@/features/functions/convertChecklistProgress';

export type ActionItem = {
  icon: string;
  description: string;
  details?: string;
}

export type Action = {
    step: number;
    title: string;
    items: ActionItem[];
    image?: string;
};

export type Checklist = {
  title: string;
  actions: Action[];
}

type OngoingGroundHandlingOperationIndex = {
  currentList: number;
  currentTask: number;
};

export type GroundHandlingOperation = {
    type: string;
    startTime: string;
    scheduledTime: string;
    orderId: string;
    orderRef: string;
    droneId: string;
    packages: {}[];
    departureHubId: string;
};

type GroundHandlingOperationStatus = 'COMPLETED' | 'ONGOING' | undefined ;
export type FillingPattern = {position: string, id: string | undefined, empty: boolean, color: string}[];

type OngoingGroundHandlingOperation = {
  groundHandlingOperation: GroundHandlingOperation
  index: OngoingGroundHandlingOperationIndex;
  status: [GroundHandlingOperationStatus, GroundHandlingOperationStatus]; // Element 1 is previous status, Element 2 is current status
  checklistsURL: string;
  checklists: Checklist[];
  ongoingChecklist: Checklist;
}

export type groundHandlingState = {
  groundHandlingCounts: GroundHandlingCountsReturn;
  groundHandlingOperations: GroundHandlingOperation[];
  ongoingGroundHandlingOperation: OngoingGroundHandlingOperation;
  fillingPattern: FillingPattern; 
};

// InitialState
const initialGroundHandlingOperation = {
  type: "",
  startTime: "",
  scheduledTime: "",
  orderId: "",
  orderRef: "",
  droneId: "",
  packages: [],
  departureHubId: "",
}
const initialChecklist = {
  title: "Initial Checklist 1 Title",
  actions: [],
}
const initialChecklists = [
  {...initialChecklist, title: "Checklist 1"}, 
  {...initialChecklist, title: "Checklist 2"},
  {...initialChecklist, title: "Checklist 3"},
  {...initialChecklist, title: "Checklist 4"}
]
const positionValues = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8'];
const productLocation: FillingPattern = [];
for (const position of positionValues) {
  productLocation.push({position: position, id: undefined, empty: true, color: ''});
}

const initialState: groundHandlingState = {
  groundHandlingOperations: [],
  groundHandlingCounts: {},
  ongoingGroundHandlingOperation: {
    groundHandlingOperation: initialGroundHandlingOperation,
    index: {
      currentList: 1,
      currentTask: 1,
    },
    status: [undefined, undefined],
    checklistsURL: "",
    checklists: initialChecklists,
    ongoingChecklist: initialChecklist,
  },
  fillingPattern: productLocation,
};

/* ------------------------------------- SLICE ------------------------------------ */

export const groundHandlingSlice = createSlice({
  name: 'groundHandling',
  initialState,
  reducers: {
    resetGroundHandlingState: (state) => {
      state = initialState;
    },
    updateGroundHandlingOperations: (state, action: PayloadAction<GroundHandlingOperation[]>) => {
        state.groundHandlingOperations = action.payload;
    },
    updateGroundHandlingCounts: (state, action: PayloadAction<GroundHandlingCountsReturn>) => {
      state.groundHandlingCounts = action.payload;
    },
    updateOngoingGroundHandlingOperation: (state, action: PayloadAction<GroundHandlingOperation>) => {
      state.ongoingGroundHandlingOperation.groundHandlingOperation = action.payload;
    },
    updateOngoingGroundHandlingOperationIndex: (state, action: PayloadAction<OngoingGroundHandlingOperationIndex>) => {
        state.ongoingGroundHandlingOperation.index = action.payload;
    },
    updateOngoingGroundHandlingOperationStatus: (state, action: PayloadAction<[GroundHandlingOperationStatus, GroundHandlingOperationStatus]>) => {
        state.ongoingGroundHandlingOperation.status = action.payload;
    },
    updateOngoingGroundHandlingOperationChecklists: (state, action: PayloadAction<Checklist[]>) => {
      state.ongoingGroundHandlingOperation.checklists = action.payload;
    },
    updateOngoingGroundHandlingOperationOngoingChecklist: (state, action: PayloadAction<Checklist>) => {
      state.ongoingGroundHandlingOperation.ongoingChecklist = action.payload;
    },
    resetGroundHandlingOperation: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      groundHandlingAPI.endpoints.getGroundHandlingOperations.matchFulfilled,
      (state, action) => {
        state.groundHandlingOperations = getGroundOperationAdapterAPI(action.payload);
      }
    );
    builder.addMatcher(
      groundHandlingAPI.endpoints.getGroundHandlingCounts.matchFulfilled,
      (state, action) => {
        state.groundHandlingCounts = action.payload;
      }
    );
    builder.addMatcher(
      groundHandlingAPI.endpoints.getChecklistsURL.matchFulfilled,
      (state, action) => {
        async function fetchData() {
          const response = await fetchCheclistsDataAndMap(action.payload.url);
          store.dispatch(updateOngoingGroundHandlingOperationChecklists(response));
        }
        fetchData()
      }
    );
    builder.addMatcher(
      groundHandlingAPI.endpoints.getChecklistProgress.matchFulfilled,
      (state, action) => {
        const [letterNumber, numericPart, error] = convertChecklistProgress(action.payload.progress);
        const currentList = letterNumber.number;
        const currentTask = numericPart;
        state.ongoingGroundHandlingOperation.index = {currentList: currentList, currentTask: currentTask};
      }
    );
    builder.addMatcher(
      groundHandlingAPI.endpoints.getFillingPattern.matchFulfilled,
      (state, action) => {
        state.fillingPattern = getFillingPatternAdapterAPI(action.payload);
      }
    );
  },
});

export const {
  resetGroundHandlingState,
  updateGroundHandlingOperations,
  updateGroundHandlingCounts,
  updateOngoingGroundHandlingOperation,
  updateOngoingGroundHandlingOperationIndex,
  updateOngoingGroundHandlingOperationStatus,
  updateOngoingGroundHandlingOperationChecklists,
  updateOngoingGroundHandlingOperationOngoingChecklist,
  resetGroundHandlingOperation,
} = groundHandlingSlice.actions;

export default groundHandlingSlice.reducer;
