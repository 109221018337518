import { useState, useEffect, useRef, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { hubType } from '@/app/stores/hubSlice';
import {
  DroneData,
  useGetDroneQuery,
} from '@/features/api/copApiEndpoints/droneApi';
import {
  type GroundOperationReturn,
  useGetGroundHandlingOperationsQuery,
  useGetHubStatusQuery,
} from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { Table } from '@/features/sharedComponents/Table/Table';
import { ReactComponent as PinIcon } from '@/media/icons/fluent-pin.svg';
import { ReactComponent as ArrowIcon } from '@/media/icons/solar-arrow-up.svg';
import { hubStatusColorMapping } from '../compactHubCard/CompactHubCard';
import { hubDeliveryColTab } from './hubDeliveryColTab';

import styles from './DetailedHubCard.module.scss';

type DetailedHubCardProps = {
  hub: hubType;
};

export type ExtendedGroundOperation = GroundOperationReturn & {
  droneData?: DroneData;
  otherHubDetail?: hubType;
};

export function DetailedHubCard({ hub }: DetailedHubCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);

  const hubList = useAppSelector((state) => state.hub.hubList);
  const { data: hubStatus } = useGetHubStatusQuery({ hubId: hub.id }, {pollingInterval: 1000, refetchOnFocus: true});
  const statusColor = hubStatusColorMapping[hubStatus?.status as 'CLEAR' | 'INCOMING' | 'TAL'] ?? 'white';

  const { data: groundOperations } = useGetGroundHandlingOperationsQuery(
    { hubId: hub.id },
    { 
      pollingInterval: 1000,
      refetchOnFocus: true,
    }
  );

  const { data: droneData } = useGetDroneQuery(
    groundOperations?.[0]?.drone ?? '',
    { skip: !groundOperations?.[0]?.drone }
  );

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setW(ref.current!.offsetWidth);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayedOperation = useMemo(
    () =>
      groundOperations?.map((operation) => {
        let finalOperation: ExtendedGroundOperation = operation;
        if (droneData) {
          const referencedDrone = Array.isArray(droneData)
            ? droneData.find((drone) => drone.reference === operation.drone)
            : droneData.reference === operation.drone
            ? droneData
            : undefined;
          finalOperation = {
            ...finalOperation,
            droneData: referencedDrone,
          };
        }
        const otherHubDetail = hubList.find((hub) => hub.id === operation.otherHub);
        if (otherHubDetail) {
          finalOperation = {
            ...finalOperation,
            otherHubDetail,
          };
        }
        return finalOperation;
      }),
    [droneData, groundOperations, hubList]
  );

  return (
    <div className={styles.pinnedHubCard}>
      <div className={styles.hubGeneralInfo}>
        <div>
          <img className={styles.coverImg} src={hub.picture} alt={hub.name} />
        </div>
        <div className={styles.generalInfo}>
          <div className={styles.hubStatus} style={{ color: statusColor }} />
          <span>{hub.name}</span>
        </div>
      </div>
      <div className={styles.hubOperationInfo} ref={ref}>
        {displayedOperation && (
          <Table
            columns={hubDeliveryColTab({ props_width: w })}
            data={displayedOperation}
            colTools
            tableHeight={'100%'}
            initState={[{ field: 'eta', sort: 'desc' }]}
            getRowId={(row) => `${row.orderId}-${row.groundHandlingType}`}
          />
        )}
      </div>
      <div className={styles.iconBar}>
        <PinIcon className={styles.pinIcon}/>
        <ArrowIcon className={styles.arrowIcon}/>
      </div>
    </div>
  );
}
