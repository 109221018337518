import { Box } from '@mui/material';
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './DeliveryTab.module.scss';
import { ReactComponent as ArrowUpRight } from '../../../../../media/icons/fluent-arrow.svg';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

import { updateOngoingGroundHandlingOperation, updateOngoingGroundHandlingOperationStatus } from '@/app/stores/dataSlice/groundHandlingSlice';



type DeliveryTabProps = {
  props_width: number;
  hoverRowId: string | null;
};

export function deliveryColTable(props: DeliveryTabProps): GridColDef[] {
  let width = props.props_width;
  let hoverRowId = props.hoverRowId;

  const etrRenderCell = (params: GridRenderCellParams<string>) => {
    const localDate = new Date(params.row.etr ?? '');
    if (isNaN(localDate.getTime())) return <Box display="flex">---</Box>;
    return (
      <Box display="flex">
        {localDate.toLocaleDateString()} <br />
        {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </Box>
    );
  };

  const etaRenderCell = (params: GridRenderCellParams<string>) => {
    const localDate = new Date(params.row.eta ?? '');
    if (isNaN(localDate.getTime())) return <Box display="flex">---</Box>;
    return (
      <Box display="flex">
        {localDate.toLocaleDateString()} <br />
        {localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </Box>
    );
  };

  const StatusRenderCell = (params: GridRenderCellParams<string>) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handlePrepareFlight = () => {}; // FETCH GROUNDHANDLING OPERATION THROUGH API CALL Then Update Redux state and redirect to the correct page.
    // useCallback(
      // (e: React.MouseEvent<HTMLDivElement>) => {
      //   console.log(params)
      //   dispatch(
      //     updateOngoingGroundHandlingOperation({
      //       type: params.row?.groundHandlingType,
      //       startTime: params.row?.groundHandlingStartTime,
      //       scheduledTime: params.row?.scheduledGroundHandlingTime,
      //       orderId: params.row?.orderId,
      //       orderRef: params.row?.orderReference,
      //       droneId: params.row?.drone,
      //       packages: params.row?.packages,
      //       departureHubId: params.row?.otherHub,
      //     })
      //   )
      //   dispatch(
      //     updateOngoingGroundHandlingOperationStatus([undefined, 'ONGOING'])
      //   )
      //   e.stopPropagation();
      //   navigate(`/deliveries/groundHandling/START_DELIVERY`);
      // },
      // [dispatch, navigate, params.row?.groundHandlingType]
    // );

    return (
      <Box
        width="80%"
        m="0"
        p="3px 10px"
        display="flex"
        justifyContent="center"
        borderRadius={styles.statusBorderRadius}
        color={() => {
          switch (params.value) {
            case 'Pending':
              return styles.statusColorPending;
            case 'Scheduled':
              return styles.statusColorScheduled;
            case 'Approved':
              return styles.statusColorApproved;
            case 'Coming':
              return styles.statusColorComing;
            case 'Ready':
              return styles.statusColorReady;
            case 'Loading':
              return styles.statusColorLoading;
            case 'Loaded':
              return styles.statusColorLoaded;
            case 'In Flight':
              return styles.statusColorInflight;
            case 'Landed':
              return styles.statusColorReadyToUnload;
            case 'Unloading':
              return styles.statusColorUnloading;
            case 'Canceled':
              return styles.statusColorCancelled;
            case 'Completed':
              return styles.statusColorCompleted;
            case 'Aborted':
              return styles.statusColorAborted;
            default:
              break;
          }
        }}
        bgcolor={() => {
          switch (params.value) {
            case 'Pending':
              return styles.statusBgColorPending;
            case 'Scheduled':
              return styles.statusBgColorScheduled;
            case 'Approved':
              return styles.statusBgColorApproved;
            case 'Coming':
              return styles.statusBgColorComing;
            case 'Ready':
              return styles.statusBgColorReady;
            case 'Loading':
              return styles.statusBgColorLoading;
            case 'Loaded':
              return styles.statusBgColorLoaded;
            case 'In Flight':
              return styles.statusBgColorInflight;
            case 'Landed':
              return styles.statusBgColorReadyToUnload;
            case 'Unloading':
              return styles.statusBgColorUnloading;
            case 'Canceled':
              return styles.statusBgColorCancelled;
            case 'Completed':
              return styles.statusBgColorCompleted;
            case 'Aborted':
              return styles.statusBgColorAborted;
            default:
              break;
          }
        }}
        className={styles.statusCell}
      >
        {params.value}
        <div
          aria-label="button"
          className={`${styles.hmiButton} ${
            (params.value === 'Ready'|| params.value === 'Loading')  && params.id === hoverRowId
              ? styles.activeRow
              : null
          }`}
          onClick={handlePrepareFlight}
        >
          {params.value == 'Scheduled' && 
          <div className={styles.contentButton}> 
            Start 
            <ArrowUpRight /> 
          </div>}
          {params.value == 'Loading' && 
          <div className={styles.contentButton}> 
            Continue 
            <ArrowUpRight /> 
          </div>}
        </div>
      </Box>
    );
  };

  if (width >= 975) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: true },
      {
        field: 'ordered_by',
        headerName: 'Ordered by',
        flex: 1.2,
        sortable: true,
      },
      {
        field: 'prepared_by',
        flex: 1.2,
        headerName: 'Prepared by',
        sortable: true,
      },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: true },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: true },
      {
        field: 'etr',
        headerName: 'ETR',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etrRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'eta',
        headerName: 'ETA',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etaRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'status',
        headerName: 'Status',
        // flex: 1,
        sortable: true,
        renderCell: StatusRenderCell,
        width: 130,
      },
    ];
  } else if (width >= 850) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: true },
      {
        field: 'ordered_by',
        headerName: 'Ordered by',
        flex: 1.2,
        sortable: true,
      },
      {
        field: 'prepared_by',
        flex: 1.2,
        headerName: 'Prepared by',
        sortable: true,
      },
      {
        field: 'departure',
        flex: 1.2,
        headerName: 'Departure',
        sortable: true,
      },
      { field: 'arrival', flex: 1.2, headerName: 'Arrival', sortable: true },
      {
        field: 'eta',
        headerName: 'ETA',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etaRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'status',
        headerName: 'Status',
        // flex: 1,
        sortable: true,
        renderCell: StatusRenderCell,
        width: 130,
      },
    ];
  } else if (width >= 775) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: true },
      {
        field: 'ordered_by',
        headerName: 'Ordered by',
        flex: 1.2,
        sortable: true,
      },
      {
        field: 'prepared_by',
        flex: 1.2,
        headerName: 'Prepared by',
        sortable: true,
      },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: true },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: true },
      {
        field: 'eta',
        headerName: 'ETA',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etaRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'status',
        headerName: 'Status',
        // flex: 1,
        sortable: true,
        renderCell: StatusRenderCell,
        width: 130,
      },
    ];
  } else if (width >= 650) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: true },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: true },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: true },
      {
        field: 'etr',
        headerName: 'ETR',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etrRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'eta',
        headerName: 'ETA',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etaRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'status',
        headerName: 'Status',
        // flex: 1,
        sortable: true,
        renderCell: StatusRenderCell,
        width: 130,
      },
    ];
  } else {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'no', headerName: 'Ref', flex: 1, sortable: true },
      { field: 'departure', flex: 1, headerName: 'Departure', sortable: true },
      { field: 'arrival', flex: 1, headerName: 'Arrival', sortable: true },
      {
        field: 'eta',
        headerName: 'ETA',
        flex: 1,
        type: 'dateTime',
        sortable: true,
        renderCell: etaRenderCell,
        sortComparator: gridDateComparator,
      },
      {
        field: 'status',
        headerName: 'Status',
        // flex: 1,
        sortable: true,
        renderCell: StatusRenderCell,
        width: 130,
      },
    ];
  }
}
