import { copAPI } from '../copAPI';

type GroundOperationParams = {
  counts?: boolean;
  startTime?: string;
  endTime?: string;
  hubId: string;
};

type GroundHandlingCountsParams = {
  counts?: boolean;
  startTime?: string;
  endTime?: string;
  hubId: string;
};

export type BatteriesRefBody = {
  batteries: string[];
}
export type BatteriesRefParams = {
  droneRef: string;
}

export type MassBody = {
  mass: number;
}
export type MassParams = {
  orderId: string;
};

export type ChecklistProgressBody = {
  progress: string;
}
export type ChecklistProgressParams = {
  orderId: string;
  type: string;
};
export type ChecklistURLParams = {
  orderId: string;
  type: string;
};

export type GroundHandlingCountsReturn = {
  DONE?: number;
  STARTED?: number;
  READY?: number;
  CANCELED?: number;
  ON_TIME?: number;
  ADAPTED?: number;
}

export type ChecklistProgressReturn = {
  progress: string;
};
export type ChecklistURLReturn = {
  url: string;
};

export type GroundHandlingActionParams = {
  orderId: string;
  type: string;
};

type HubStatusParams = {
  hubId: string;
};

type FillingPatternParams = {
  orderId: string;
}

export type FillingPatternReturn = {
  location: string;
  productId: string;
  containerId: string;
};

type HubStatusReturn = {
  status: string;
};

export type GroundOperationReturn = {
  groundHandlingType: 'DONE' | 'STARTED' | 'READY' | 'CANCELED' | 'ON_TIME' | 'ADAPTED';
  groundHandlingStartTime: string;
  groundHandlingStatus: string;
  packages: {
    packaging: {
      id: string;
    };
    content: {
      type: string;
      id: string;
    };
  }[];
  drone: string;
  flightStatus: string;
  orderId: string;
  orderReference: string;
  scheduledGroundHandlingTime: string;
  otherHub: string;
};

export const groundHandlingAPI = copAPI.injectEndpoints({
  endpoints: (builder) => ({
    getGroundHandlingOperations: builder.query<GroundOperationReturn[], GroundOperationParams>({
      query: (params) => ({
        url: `./groundhandling/${params.hubId}`,
        method: 'GET',
        params: params,
      }),
    }),
    getGroundHandlingCounts: builder.query<GroundHandlingCountsReturn, GroundHandlingCountsParams>({
      query: (params) => ({
        url: `./groundhandling/${params.hubId}/counts`,
        method: 'GET',
        params: params,
      }),
    }),
    getChecklistProgress: builder.query<ChecklistProgressReturn, ChecklistProgressParams>({
      query: (params) => ({
        url: `./groundhandling/${params.orderId}/checklist/${params.type}`,
        method: 'GET',
        params: params,
      }),
    }),
    getChecklistsURL: builder.query<ChecklistURLReturn, ChecklistURLParams>({
      query: (params) => ({
        url: `./groundhandling/${params.orderId}/checklisturl/${params.type}`,
        method: 'GET',
        params: params,
      }),
    }),
    getHubStatus: builder.query<HubStatusReturn, HubStatusParams>({
      query: (params) => ({
        url: `./groundhandling/${params.hubId}/status`,
        method: 'GET',
        params: params,
      }),
    }),
    getFillingPattern: builder.query<FillingPatternReturn[], FillingPatternParams>({
      query: (params) => ({
        url: `./groundhandling/${params.orderId}/fillingPattern`,
        method: 'GET',
        params: params,
      }),
    }),
    postBatteriesRef: builder.mutation({
      query: ({params, body}:{params: BatteriesRefParams, body: BatteriesRefBody}) => ({
        url: `/groundhandling/${params.droneRef}/battery`,
        method: 'POST',
        body: body,
      }),
    }),
    postMass: builder.mutation({
      query: ({params, body}:{params: MassParams, body: MassBody}) => ({
        url: `/groundhandling/${params.orderId}/mass`,
        method: 'POST',
        body: body,
      }),
    }),
    postChecklistProgress: builder.mutation({
      query: ({params, body}:{params: ChecklistProgressParams, body: ChecklistProgressBody}) => ({
        url: `/groundhandling/${params.orderId}/checklist/${params.type}`,
        method: 'POST',
        params: params,
        body: body,
      }),
    }),
    postStartGroundHandlingOperation: builder.mutation({
      query: (params: GroundHandlingActionParams) => ({
        url: `/groundhandling/${params.orderId}/start/${params.type}`,
        method: 'POST',
        params: params,
      }),
    }),
    postEndGroundHandlingOperation: builder.mutation({
      query: (params: GroundHandlingActionParams) => ({
        url: `/groundhandling/${params.orderId}/end/${params.type}`,
        method: 'POST',
        params: params,
      }),
    }),
  }),
});

export const {
  useGetGroundHandlingOperationsQuery,
  useGetGroundHandlingCountsQuery,
  useGetChecklistProgressQuery,
  useGetChecklistsURLQuery,
  useGetHubStatusQuery,
  useGetFillingPatternQuery,
  usePostBatteriesRefMutation,
  usePostMassMutation,
  usePostChecklistProgressMutation,
  usePostEndGroundHandlingOperationMutation,
  usePostStartGroundHandlingOperationMutation,
} = groundHandlingAPI;
