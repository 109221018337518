import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowRight, faEye, faXmark, faRotateRight, faPaperPlane, faSignature, faFileSignature, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { fastOrderingState, resetFastOrdering } from '@/app/stores/dataSlice/fastOrderingSlice';
import { resetGoodsList } from '@/app/stores/dataSlice/goodsListSlice';
import { orderType, resetOrder } from '@/app/stores/dataSlice/orderingSlice';
import {
  goodListPayload,
  useCreateOrderMutation,
  useGetOrdersQuery,
} from '@/features/api/copApiEndpoints/orderAPI';
import { ModalTable } from '@/features/sharedComponents/Table/ModalTable';
import { isValidGoodList } from '@/helpers/validator';

import DelayingAppearance from '../LoadingUI';
import { deliveryInfoColumns, productColumns } from './columnModalTable';

import styles from './OrderConfirmModal.module.scss';
import { IconButton } from '../../Buttons/iconButton/IconButton';
import Input from '@mui/joy/Input/Input';
import Tooltip from '@mui/joy/Tooltip/Tooltip';

export type LoadingStatus = 'progress' | 'success' | 'idle' | 'failure'

type displayGoodList = {
  id: number;
  unNumber: string;
  name: string;
  container: string;
  amount: number;
};

export default function OrderConfirmModal({
  open,
  handleClose,
  ordertype = 'normalOrder',
}: {
  open: boolean;
  handleClose: () => void;
  ordertype: 'fastOrder' | 'normalOrder';
}) {
  const fastOrder = useAppSelector((state) => state.fastOrdering);
  const normalOrder = useAppSelector((state) => state.ordering.newOrder);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { refetch: refetchDeliveryList } = useGetOrdersQuery({});

  const newOrderState: fastOrderingState | orderType = ordertype === 'fastOrder' ? fastOrder : normalOrder;
  const mainState = useAppSelector((state) => state.main);
  const [query, setQuery] = useState<LoadingStatus>('idle');
  const [createOrder, createOrderResult] = useCreateOrderMutation();
  const [errMsg, setErrMsg] = useState<string>('');
  // const [clientRef, setClientRef] = useState<string>('');

  const initialSize = 1;
  const [ref, setRef] = useState<string[]>(Array(initialSize).fill(undefined));
  const [refInvalid, setRefInvalid] = useState<boolean[]>(Array(initialSize).fill(false));
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const signatureText = 'I hereby declare that the contents of this consignment are fully and accurately described above by the proper shipping name, and are classified, packaged, marked and labelled/placarded, and are in all respects in proper condition for transport according to applicable international and national governmental regulations. I declare that all of the applicable air transport requirements have been met.';

  useEffect(() => {
    if (createOrderResult.isSuccess && createOrderResult.data) {
      if (ordertype === 'normalOrder') {
        dispatch(resetOrder());
        dispatch(resetGoodsList());
      }
      if (ordertype === 'fastOrder') {
        dispatch(resetFastOrdering());
      }
      setQuery('success');
      refetchDeliveryList();
    } else if (createOrderResult.isError && createOrderResult.error) {
      if ('data' in createOrderResult.error) {
        const errData = createOrderResult.error.data as { message:  {message: string} };
        if ('message' in errData) {
          const errorMessage = errData.message;
          if ('message' in errorMessage) {
            setErrMsg(errorMessage.message);
          }
        }
      }
      refetchDeliveryList();
      setQuery('failure');
    }
  }, [createOrderResult, dispatch, ordertype, refetchDeliveryList]);

  useEffect(() => { // CHECK MAX CHARACTER OF NEW REFS
    let counter = Array(ref.length).fill(0);
    for (let i = 0; i < ref.length; i++) {
        if (ref[i] != undefined) {
            if (ref[i].length > 10) {
                const updatedList = refInvalid;
                updatedList[i] = true;
                counter[i] = ref[i].length;
                setRefInvalid(updatedList)
            } else if (ref[i].length <= 10) {
                const updatedList = refInvalid;
                updatedList[i] = false;
                counter[i] = ref[i].length;
                setRefInvalid(updatedList)
            } 
        }
    }
    const condition1 = (ref.length == 0);
    const condition2 = counter.every((num) => num > 0 && num <= 10);
    setDisableSubmit((condition1 || !condition2));
  }, [ref, setRef]);
  
  let displayGoodList: displayGoodList[] = [];
  let orderPayload: goodListPayload[] = [];
  if (newOrderState.payload) {
    newOrderState.payload?.items?.forEach((item, idx) => {
      displayGoodList.push({
        id: idx,
        unNumber: item.product.unNumber,
        name: item.product.name,
        container: item.containerType.name,
        amount: item.amount,
      });
      orderPayload.push({
        productId: item.product.id,
        containerId: item.containerType.id,
        amount: item.amount,
      });
    });
  } else if (ordertype === 'fastOrder') {
    const [isValid, validPayload, validGoodList] = isValidGoodList(
      newOrderState.goodsList
    );
    orderPayload = validPayload;
    if (isValid) {
      displayGoodList = validGoodList.map((item) => ({
        id: item.id,
        unNumber: item.payload.product.un,
        name: item.payload.product.name,
        container: item.payload.container.name,
        amount: item.payload.quantity,
      }));
    }
  }

  const handleConfirm = () => {
    setQuery('progress');
    if (
      newOrderState.hubs.arrival &&
      newOrderState.hubs.departure &&
      (newOrderState.dateTime || ordertype === 'fastOrder' || (newOrderState as orderType).asap) &&
      mainState.whoAmI &&
      ref
    ) {
      if (newOrderState.payload) {
        // If payloadId => send payloadId
        createOrder({
          clientRefs: ref,
          asap: ordertype === 'fastOrder' || (newOrderState as orderType).asap,
          companyId: mainState.company.id,
          userId: mainState.whoAmI.id,
          startId: newOrderState.hubs.departure.id,
          endId: newOrderState.hubs.arrival.id,
          timeDelivery: newOrderState.dateTime ? new Date(newOrderState.dateTime).toISOString() : new Date().toISOString(),
          payloadId: newOrderState.payload.id,
        });
      } else if (orderPayload.length) {
        // Else send payload (only validPayload)
        console.log('newOrderState: ', newOrderState)
        createOrder({
          clientRefs: ref,
          asap: ordertype === 'fastOrder' || (newOrderState as orderType).asap,
          companyId: mainState.company.id,
          userId: mainState.whoAmI.id,
          startId: newOrderState.hubs.departure.id,
          endId: newOrderState.hubs.arrival.id,
          timeDelivery: newOrderState.dateTime ? new Date(newOrderState.dateTime).toISOString() : new Date().toISOString(),
          payload: {
            items: orderPayload,
          },
        });
      } else {
        setErrMsg(
          'Sorry, the information you entered are incomplete. Please complete the info and try again',
        );
        setQuery('failure');
      }
    } else {
      setErrMsg(
        'Sorry, the information you entered are incomplete. Please complete the info and try again',
      );
      setQuery('failure');
    }
  };


  const addProduct = () => {
    if (ref.length <= 3) {
      const newSize = ref.length + 1; // Specify the new desired size
      const updatedRef = [...ref, ...Array(newSize - ref.length).fill(undefined)];
      const updatedRefInvalid = [...refInvalid, ...Array(newSize - ref.length).fill(false)];
      setRef(updatedRef); setRefInvalid(updatedRefInvalid);
    }
  };
  const deleteProduct = () => {
    if (ref.length > 1) {
      const updatedRef = [...ref.slice(0, -1)];
      const updatedRefInvalid = [...refInvalid.slice(0, -1)];
      setRef(updatedRef); setRefInvalid(updatedRefInvalid);
    }
  };
  const handleResult = () => {
    setQuery('idle');
    setErrMsg('');
    handleClose();
    setRef([]);
  };

  const deliveryInfo = [
    {
      id: 1,
      departure: newOrderState.hubs.departure?.name || '',
      arrival: newOrderState.hubs.arrival?.name || '',
      deliveryTime: newOrderState.dateTime || '',
      asap: newOrderState.asap || undefined,
    },
  ];

  return (
    <Modal
      className={styles.modalWrapper}
      open={open}
      aria-labelledby="order-confirm-modal"
      aria-describedby="order-confirm-modal"
    >
      {query === 'idle' ? (
        <div className={styles.cardModal}>
          <div className={styles.header}>
            <p>Order Confirmation</p>
            <div className={styles.close_icon} onClick={handleClose} >
              <FontAwesomeIcon size='1x' icon={faXmark}/>
            </div>
          </div>
          <div className={styles.content}>
            {newOrderState.payload && (
              <div>
                Payload: {newOrderState.payload?.id}
              </div>
            )}
            <div>
              Products
            </div>
            {displayGoodList.length ? (
              <ModalTable
                columns={productColumns}
                colTools
                data={displayGoodList}
                tableHeight={displayGoodList.length > 4 ? '238px' : undefined}
                initState={[{ field: 'eta', sort: 'desc' }]}
              />
            ) : ordertype === 'fastOrder' ? (
              <div className={styles.errorMsg}>
                Please add at least one product...
              </div>
            ) : (
              <div className={styles.errorMsg}>
                Please first select at least 1 product and check for compliance...
              </div>
            )}
            <div >
              Delivery info
            </div>
            {deliveryInfo[0].arrival ||
            deliveryInfo[0].departure ||
            deliveryInfo[0].deliveryTime ? (
              <ModalTable
                columns={deliveryInfoColumns}
                colTools
                data={deliveryInfo}
                initState={[{ field: 'eta', sort: 'desc' }]}
              />
            ) : (
              <div className={styles.errorMsg}>
                You have not selected delivery hubs and time of delivery
              </div>
            )}
            <div className={styles.orderRef}>
              <div className={styles.orderRefBody}>
                Order Reference*
                <div className={styles.refWrapper}>
                  {ref.map((item, idx) => (
                    <Input
                      key={idx}
                      size='sm'
                      required
                      sx={{width: '110px'}}
                      placeholder={`Ref ${idx+1}`}
                      onChange={(e) => {
                        const updatedList = [...ref];
                        updatedList[idx] = e.target.value.toUpperCase().trim();
                        setRef(updatedList)
                      }}
                      error={refInvalid[idx]}
                    />
                  ))}
                  <div className={styles.listOperations}>
                        <IconButton content='' 
                            onClick={() => addProduct()} 
                            icon={faCirclePlus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='sm'
                            disabled={ref.length == 3}
                        />
                        <IconButton content='' 
                            onClick={() => deleteProduct()} 
                            icon={faCircleMinus} 
                            color='unset_blue'
                            noBorder={true}
                            noPadding={true}
                            reducedGap={true}
                            size='sm'
                            disabled={ref.length == 1}
                        />
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <Tooltip title={signatureText} sx={{width: '550px', textAlign: 'center'}}>

          <div className={styles.footer}>
            <IconButton
              icon={faSignature}
              content="Approve & Sign"
              onClick={handleConfirm}
              reversed={true}
              disabled={disableSubmit}
            />
          </div>

          </Tooltip>
        </div>
      ) : (
        <div className={styles.cardModal}>
          <div className={styles.header}>
            <p>Place Order Result</p>
          </div>
          <div className={styles.content}>
            {query === 'progress' && <DelayingAppearance />}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {createOrderResult.isSuccess && (
                <>
                  <div className={styles.succMsg}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleCheck} size="2x" />
                    </span>
                    <span>Order Successfully Placed !</span>
                  </div>
                </>
              )}
              {createOrderResult.isError && <div className={styles.errMsg}>{errMsg}</div>}
            </div>
          </div>
          <div className={styles.footer}>
            {query === 'success' ? (
              <>
                <IconButton
                  icon={faAdd}
                  content="New order"
                  onClick={handleResult}
                  reversed={true}
                />
                <IconButton
                  icon={faEye}
                  content="Track order"
                  onClick={() => {
                    navigate('/deliveries');
                  }}
                  reversed={true}
                />
              </>
            ) : query === 'failure' ? (
              <IconButton
                  icon={faRotateRight}
                  content="Try again"
                  onClick={handleResult}
                  reversed={true}
                />
            ) : null}
          </div>
        </div>
      )}
    </Modal>
  );
}
