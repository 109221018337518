import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

import { useAppDispatch } from '@/app/hooks';
import { login } from '@/app/mainSlice';

import { ReactComponent as ADLCLogo } from '../../../media/adlc_logo_wing_w.svg';
import { updateActivePage } from '../../sharedComponents/Sidebar/sidebarSlice';
import { useSignIn } from '@/hooks/useSignIn';
import { faCircleInfo} from '@fortawesome/free-solid-svg-icons'

// Styles
import styles from './Auth.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Auth() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [
    signIn,
    {
      isLoading: signInLoading,
      isSuccess: signInSuccess,
      isError: signInError,
      successMessage: signInSuccessMsg,
      // errorMessage: signInErrorMsg,
    },
  ] = useSignIn();

  // Update active page state on the 1st render
  useEffect(() => {
    dispatch(updateActivePage('Auth'));
  }, [dispatch]);

  // Callback function for the signIn api call
  useEffect(() => {
    if (signInSuccess && signInSuccessMsg) {
      dispatch(login());
      navigate('/');
      dispatch(updateActivePage('Dashboard'));
    }
  }, [dispatch, navigate, signInSuccess, signInSuccessMsg]);

  // Define IconButton props

  if (!signInLoading && !signInSuccess) {
    return (
      <div className={styles.auth}>
        <div className={styles.intro}>
            <div className={styles.logo_container}>
              <ADLCLogo width="200" height="content-fit" />
            </div>
            <div className={styles.title}> Safe, reliable & integrated drone logistics.</div>
        </div>
        <div className={styles.content_wrapper}>
          <div className={styles.header}>
            <div className={styles.title_login}> Log in</div>
            
          </div>
          <div className={styles.content}>
            {signInError && (
              <div className={styles.text_error}>
                Sorry, the email and password you entered did not match our
                records. Please try again.
              </div>
            )}
            <div className={styles.login_button}> Log in </div>
            <div className={styles.signin_button}> Create account </div>
            {/* <Button
              type="submit"
              variant="contained"
              className={styles.logInButton}
              fullWidth
              onClick={signIn}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in with Azure Auth
            </Button> */}
          </div>
          <div className={styles.footer}>
            <div className={styles.infoIcon}>i</div>
            {/* <FontAwesomeIcon icon={faCircleInfo} /> */}
            <div className={styles.infoText}>Creating an account is free</div>
          <div/>
        </div>
      </div>
    </div>
    );
  } else {
    return (
      <div className={styles.auth}>
        <div className={styles.content_wrapper}>
          <div className={styles.progress_wrapper}>
            <CircularProgress sx={{ color: 'black' }} />
          </div>
        </div>
      </div>
    );
  }
}
