import * as fs from 'fs';
import * as https from 'https';

export async function downloadPdf(url: string | undefined): Promise<void> {
    // const pdfFileName = 'sample.pdf';
    const relativePath = `${process.env.PUBLIC_URL}/`;
    if (url != undefined) {
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}