import Cookies from 'js-cookie';
const ServerPublicKey = 'BEUZ6aSaqMGlaM1OrVc4is1GDHksbJnKBMOLwpWWCmhUdBpIf0NQbCTgJVyV_p3WDSmQjRupPgSeyCPohttO64Q'; // Can be found on Azure Notification Hubs 

export const subscribeToPushNotifications = () => {
  navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
    serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(ServerPublicKey)
    }).then(subscription => {
      const subscriptionDetails = {
        endpoint: subscription.endpoint,
        //expirationTime: subscription.expirationTime ?? 3600,
        keys: {
          p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
          auth: arrayBufferToBase64(subscription.getKey('auth'))
        }
      };
      console.log('subscriptionDetails: ', subscriptionDetails);
      // Define the URL endpoint
      const url = process.env.REACT_APP_PROXY+'/notifications/push-subscribe';
      const accessToken = Cookies.get('accessToken');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+accessToken,
    };

// Make the POST request
fetch(url, {
    method: 'POST', // Specify the method
    headers: headers, // Include the headers
    body: JSON.stringify(subscriptionDetails), // Convert the JavaScript object to a JSON string
})
.then(response => response.json()) // Parse the JSON response
.then(data => console.log(data)) // Handle the response data
.catch(error => console.error('Error:', error)); // Handle any errors

      // if (auth) {
      //   // Send subscription details to your backend for registration with Azure Notification Hubs
      //   sendSubscriptionDetailsToServer(subscription);
      // }
      //console.log('keys: ', keys);
      //console.log('auth: ', auth);
    }).catch(error => {
      console.error('Error subscribing to push notifications:', error);
    });
  })
};

export const unsubscribeFromPushNotifications = () => {
  
      // Define the URL endpoint
      const url = process.env.REACT_APP_PROXY+'/notifications/push-unsubscribe';
      const accessToken = Cookies.get('accessToken');
      const headers = {
        'Authorization': 'Bearer '+accessToken,
    };

    // Make the POST request
    fetch(url, {
        method: 'POST', // Specify the method
      headers: headers, // Include the headers
    }) .then(response => response.json()) // Parse the JSON response
       .then(data => console.log(data)) // Handle the response data
        .catch(error => console.error('Error:', error)); // Handle any errors
}

     
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function arrayBufferToBase64(buffer: any) {
let binary = '';
const bytes = new Uint8Array(buffer);
const len = bytes.byteLength;
for (let i = 0; i < len; i++) {
  binary += String.fromCharCode(bytes[i]);
}
return window.btoa(binary);
}