import * as React from 'react';
import { v4 as uuidv4 } from "uuid";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { ActionItem } from '@/app/stores/dataSlice/groundHandlingSlice';
import { useAppSelector } from '@/app/hooks';
import productMapping from '@/features/functions/productMapping';
import { BatteriesRefBody, BatteriesRefParams, MassBody, MassParams, usePostBatteriesRefMutation, usePostMassMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { useGetFillingPatternQuery } from '@/features/api/copApiEndpoints/groundHandlingAPI';

import styles from './ListCard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faPaperPlane, faPen, faTriangleExclamation, faVial, faXmark } from '@fortawesome/free-solid-svg-icons';
import palletLeft from '@/media/pallet_2.svg';
import palletRight from '@/media/pallet_right.svg';
import palletLabelsRight from '@/media/pallet_labels_right.svg';
import palletLabelsLeft from '@/media/pallet_labels_left.svg';
import Stack from '@mui/material/Stack';
import Typography from '@mui/joy/Typography';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Modal from '@mui/joy/Modal/Modal';
import EditPayload from '@/features/pages/Deliveries/components/editPayload/EditPayload';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';

// Labels icons 
import class_1_1 from '@/media/labels/class_1-1.png';
import class_1_2 from '@/media/labels/class_1-2.png';
import class_1_3 from '@/media/labels/class_1-3.png';
import class_1_4 from '@/media/labels/class_1-4.png';
import class_1_5 from '@/media/labels/class_1-5.png';
import class_1_6 from '@/media/labels/class_1-6.png';
import class_2_1 from '@/media/labels/class_2-1.png';
import class_2_2 from '@/media/labels/class_2-2.png';
import class_2_3 from '@/media/labels/class_2-3.png';
import class_3 from '@/media/labels/class_3.png';
import class_4_1 from '@/media/labels/class_4-1.png';
import class_4_2 from '@/media/labels/class_4-2.png';
import class_4_3 from '@/media/labels/class_4-3.png';
import class_5_1 from '@/media/labels/class_5-1.png';
import class_5_2 from '@/media/labels/class_5-2.png';
import class_6_1 from '@/media/labels/class_6-1.png';
import class_8 from '@/media/labels/class_8.png';
import { productType_ogc } from '@/app/stores/productSlice';
import { Button, Input, Snackbar, Tooltip } from '@mui/joy';
import Zoom from '@mui/material/Zoom';
import { height } from '@mui/system';


type ListCardProps = {
    items: ActionItem[],
    orderId: string,
    disableCardButton: () => void,
    activateCardButton: () => void,
};

function ListCard (props: ListCardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false); // API calls
  const [postMass, postMassResult] = usePostMassMutation(); 
  const [postBatteriesRef, postBatteriesRefResult] = usePostBatteriesRefMutation(); 
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);
  const [errorWeight, setErrorWeight] = useState(false); // State to track input error
  const [showRight, setShowRight] = useState(false); // pattern & label variable
  const groundHandlingState = useAppSelector((state) => state.groundHandling);
  const deliveryState = useAppSelector((state) => state.delivery);
  const productState = useAppSelector((state) => state.product);
  const orderId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId;
  const droneId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.droneId;

  const shipment = deliveryState.deliveryList?.find(item => item.id === orderId)?.payload;
  const productLocation = groundHandlingState.fillingPattern;

  const productDetails = productMapping(shipment);
  const [showProductEditButton, setShowProductEditButton] = useState<boolean>(false);
  const [showProductEditModal, setShowProductEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (props.items.some(element => element?.details == 'weightbalance_check')) {
      props.disableCardButton();
    }
    if (props.items.some(element => element?.details == 'battery_log')) {
      props.disableCardButton();
    }
  }, [])

  // Define left or right view 
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  const handleSwitch = () => { setShowRight((prevChecked) => !prevChecked) }
  const handleClose = () => { setShowProductEditModal(false) };

  // Allocate colors to product items
  type productColorsType = {name: string, color: string};
  let productColors: productColorsType[] = [];
  const newProductLocation = [...productLocation]; // Make a copy of the original array
  productDetails.forEach((item, index) => {
      productColors.push({name: item.name, color: `color${index+1}`});
      newProductLocation.forEach((bottle, bottleIndex) => {
          if (bottle?.id == item.productId) {
              newProductLocation[bottleIndex] = {...bottle, color: `color${index+1}`};
          } 
      });
  });

  // Function to chunk the list into subarrays of 3 items each
  function chunkArray(arr: any[] | undefined, chunkSize: number) {
    if (arr != undefined) {
      const chunkedArray = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunkedArray.push(arr.slice(i, i + chunkSize));
      }
      return chunkedArray;
    }
  }  
  // Allocate labels to packagings
  let labels1: string[]= []; let labels2: string[] = [];
  newProductLocation.forEach((item, index) => {
    const product = productState.productList.find((obj: productType_ogc) => obj.id == item.id);
    const productLabels = product?.hazards;
    let productLabelsUrl: string[] = [];
    productLabels?.forEach(label => {
        switch (label) {
          case '1.1':
            productLabelsUrl.push(class_1_1);
            break;
          case '1.2':
            productLabelsUrl.push(class_1_2);
            break;
          case '1.3':
            productLabelsUrl.push(class_1_3);
            break;
          case '1.4':
            productLabelsUrl.push(class_1_4);
            break;
          case '1.5':
            productLabelsUrl.push(class_1_5);
            break;
          case '1.6':
            productLabelsUrl.push(class_1_6);
            break;
          case '2.1':
            productLabelsUrl.push(class_2_1);
            break;
          case '2.2':
            productLabelsUrl.push(class_2_2);
            break;
          case '2.3':
            productLabelsUrl.push(class_2_3);
            break;
          case '3':
            productLabelsUrl.push(class_3);
            break;
          case '4.1':
            productLabelsUrl.push(class_4_1);
            break;
          case '4.2':
            productLabelsUrl.push(class_4_2);
            break;
          case '4.3':
            productLabelsUrl.push(class_4_3);
            break;
          case '5.1':
            productLabelsUrl.push(class_5_1);
            break;
          case '5.2':
            productLabelsUrl.push(class_5_2);
            break;
          case '6.1':
            productLabelsUrl.push(class_6_1);
            break;
          case '8':
            productLabelsUrl.push(class_8);
            break;
          default:
            break;
        }
    })
    switch (true) {
      case index < 8:
        if (productLabels && Array.isArray(productLabels) && labels1 && Array.isArray(labels1)) {
          const missingLabels1 = productLabelsUrl?.filter(str => !labels1.includes(str)) ?? [];
          missingLabels1.forEach(missingLabel => {
            if ( !labels1.includes(missingLabel) ) { labels1.push(missingLabel) }
          })
        }
        break;
      case index >= 8:
        if (productLabels && Array.isArray(productLabels) && labels2 && Array.isArray(labels2)) {
          const missingLabels2 = productLabelsUrl?.filter(str => !labels2.includes(str)) ?? [];
          missingLabels2.forEach(missingLabel => {
            if ( !labels2.includes(missingLabel) ) { labels2.push(missingLabel) }

          })
        }
        break;
      default:
        break;
    }
  });
  while (labels1.length < 6) { labels1.push('') }; while (labels2.length < 6) { labels2.push('') };
  const chunkedLabels1 = chunkArray(labels1, 3); // Chunk the list into subarrays with three items each
  const chunkedLabels2 = chunkArray(labels2, 3); // Chunk the list into subarrays with three items each
  const chunkedArray = chunkArray(productColors, 2); // Chunk the list into subarrays with two items each

  // Submit weight
  const [inputWeight, setInputWeight] = useState<number>(Number(shipment?.mass.toFixed(2))); // Weight variable
  useEffect(() => {
    if(postMassResult.isLoading) {setIsLoading(true)}
    else if(postMassResult.isSuccess) {setIsLoading(false); props.activateCardButton(); setSnackbar(true);}
    else if(postMassResult.isError) {setIsLoading(false); setSnackbarError(true);}
  },[postMassResult])
  const handleSubmit = (currentWeight: number) => {
    if(currentWeight != null){
      const params: MassParams = {orderId: props.orderId};
      const body: MassBody = {mass: currentWeight};
      postMass({ params, body }); // Use the mutation here
    }
  }

  // Submit batteries serial numbers
  const batteryNb = 2;
  const [inputBatteriesStatus, setInputBatteriesStatus] = useState<boolean>(false)
  const [inputBatteries, setInputBatteries] = useState<string[] | undefined>(Array.from({ length: batteryNb })); 
  const inputBatteriesChunked = chunkArray(inputBatteries, 4);
  useEffect(() => {
    if(postBatteriesRefResult.isLoading) {setIsLoading(true)}
    else if(postBatteriesRefResult.isSuccess) {setIsLoading(false); props.activateCardButton(); setSnackbar(true); setInputBatteriesStatus(true);}
    else if(postBatteriesRefResult.isError) {setIsLoading(false); setSnackbarError(true);}
  },[postBatteriesRefResult])
  const handleSubmitBattery = (batteries: string[]) => {
    if(batteries != undefined){
      const params: BatteriesRefParams = {droneRef: droneId};
      const body: BatteriesRefBody = {batteries};
      postBatteriesRef({ params, body }); // Use the mutation here
    }
  }

  return (
    <>
      {<Modal className={styles.modalWrapper} // Product edit modal
        aria-labelledby="update-shipment-modal"
        aria-describedby="update-shipment-modal"
        open={showProductEditModal}
        onClose={handleClose}
      >
        <div className={styles.cardModal}>
          <div className={styles.header}>
            <div> Update your shipment... </div>
            <div className={styles.close_icon} onClick={handleClose}> <FontAwesomeIcon size='1x' icon={faXmark}/> </div>
          </div>
          <div className={styles.editPayload_wrapper}> <EditPayload orderId={props.orderId}/> </div>
        </div> 
      </Modal>}
      <div key={uuidv4()}>
          <List key={uuidv4()} aria-labelledby="decorated-list-demo" >
          {props.items.map((element) => (
                <ListItem key={uuidv4()}>
                  <div className={styles.listWrapper}>
                    <div className={styles.listItem} style={{gap:'10px'}}> 
                      <ListItemDecorator> {element.icon} </ListItemDecorator> {element.description} 
                      {element?.details == 'weightbalance_check' && <div style={{color: 'rgb(0, 110, 255)'}}> {Number(shipment?.mass.toFixed(2))} kg </div>}
                    </div>
                    {element?.details == 'weightbalance_check' && <div className={styles.weightWrapper}>
                        <div className={styles.weightTitle}> 
                          <div> <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth /> Report the verified mass </div>
                          <div style={{color: 'rgb(0, 110, 255)'}}> 
                          <form
                            onSubmit={(event) => {
                              event.preventDefault();
                              const formData = new FormData(event.currentTarget);
                              const inputFieldValue = formData.get('inputField'); // Get the value of the input field
                              setInputWeight(Number(inputFieldValue));
                              const currentWeight = Number(inputFieldValue);
                              handleSubmit(currentWeight);
                            }}
                          >
                            <Stack spacing={1}>
                              <Input
                                required
                                name="inputField" 
                                error={errorWeight}
                                slotProps={{input: {min: 0.00, max: 1000.00, step: 0.01}}}
                                type='number'
                                defaultValue={inputWeight}
                                sx={{width: '140px'}}
                                size='sm'
                                endDecorator={<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}> kg <Button  type="submit" loading={isLoading}><FontAwesomeIcon icon={faPaperPlane}/></Button> </div>} // onClick={() => handleSubmit()}
                              /> 
                            </Stack>
                          </form>
                            
                          </div>
                        </div>
                      </div>
                    }
                    {element?.details == 'battery_log' && <div className={styles.batteryWrapper}>
                            <form
                              onSubmit={(event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                let inputBatteriesForm: string[] = [];
                                inputBatteries?.forEach((item, index) => {
                                  const data = formData.get(`inputBattery${index+1}`)
                                  if (data != null) {inputBatteriesForm.push(data instanceof File ? data.name : data)}
                                });
                                setInputBatteries(inputBatteriesForm);
                                const batteries = inputBatteriesForm;
                                handleSubmitBattery(batteries);
                              }}
                            >
                              <div className={styles.title}> 
                                <div style={inputBatteriesStatus ? {pointerEvents: 'none', filter: 'opacity(50%)'} : {}}> <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth /> Report batteries ID </div>
                                {!inputBatteriesStatus && <Button  type="submit" loading={isLoading} size='sm'><FontAwesomeIcon icon={faPaperPlane}/></Button>}
                                {inputBatteriesStatus && <Button size='sm' onClick={() => {setInputBatteriesStatus(false); props.disableCardButton();}} style={{gap: '5px'}}><FontAwesomeIcon icon={faPen} size='sm'/> Edit</Button>}
                              </div>
                              <Stack spacing={1} direction='column' style={inputBatteriesStatus ? {pointerEvents: 'none', filter: 'opacity(50%)'} : {}}>
                                {inputBatteriesChunked?.map((row: number[], rowIndex: number) => (
                                  <Stack key={rowIndex} spacing={1} direction='row'>
                                      {row.map((item: number, itemIndex: number) => (
                                        <Input
                                          key={itemIndex}
                                          required
                                          defaultValue={inputBatteries != undefined ? inputBatteries[rowIndex * inputBatteriesChunked[0].length + itemIndex] : ''}
                                          name={`inputBattery${rowIndex * inputBatteriesChunked[0].length + itemIndex + 1}`}
                                          error={errorWeight}
                                          slotProps={{input: {min: 0}}}
                                          type='string'
                                          sx={{width: '110px'}}
                                          startDecorator={<div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px'}}>  🔋 {rowIndex * inputBatteriesChunked[0].length + itemIndex + 1} </div>}
                                          size='sm'
                                        /> 
                                      ))}
                                  </Stack>
                                ))}
                              </Stack>
                            </form>
                      </div>
                    }
                    {element?.details == 'product_check' && <div className={styles.productWrapper} onMouseEnter={() => setShowProductEditButton(true)} onMouseLeave={() => setShowProductEditButton(false)}>
                        <div className={styles.productTitle_wrapper}> 
                          <div className={styles.productTitle}> <FontAwesomeIcon icon={faVial} fixedWidth /> Products </div> 
                          {showProductEditButton && 
                            <div className={styles.editButton} onClick={() => setShowProductEditModal(true)}>
                              <FontAwesomeIcon icon={faPen} size='xs'/>
                            </div>}
                        </div>
                        {productDetails.map((item, index) => (
                          <div key={uuidv4()} className={styles.productItem}>
                            <>
                              <div key={uuidv4()}>{item.count}x </div>
                              <div key={uuidv4()}>{item.name}</div>
                            </>
                            <div className={styles.containerItem}> in {item.container} </div>
                          </div>
                        ))}
                      </div>
                    }
                    {element?.details == 'product_insertion' && <div className={styles.insertionWrapper}>
                        <div className={styles.header}>
                          <div className={styles.insertionTitle}> <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth /> Insertion pattern </div>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <div style={{fontSize: '14px'}}>Left</div>
                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={handleSwitch} checked={showRight}/>
                            <div>Right</div>
                          </Stack>
                        </div>
                        <div className={styles.imageWrapper}>
                          {showRight == false ? (
                            <>                      
                              <img className={styles.img} src={palletLeft} alt=''/>

                              <div className={`${styles.bottle} ${styles.bottleA1} ${!newProductLocation[0].empty ? styles[newProductLocation[0].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA2} ${!newProductLocation[1].empty ? styles[newProductLocation[1].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA3} ${!newProductLocation[2].empty ? styles[newProductLocation[2].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA4} ${!newProductLocation[3].empty ? styles[newProductLocation[3].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA5} ${!newProductLocation[4].empty ? styles[newProductLocation[4].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA6} ${!newProductLocation[5].empty ? styles[newProductLocation[5].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA7} ${!newProductLocation[6].empty ? styles[newProductLocation[6].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA8} ${!newProductLocation[7].empty ? styles[newProductLocation[7].color] : ''}`}/>

                              <div className={`${styles.bottle} ${styles.bottleB1} ${!newProductLocation[8].empty ? styles[newProductLocation[8].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB2} ${!newProductLocation[9].empty ? styles[newProductLocation[9].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB3} ${!newProductLocation[10].empty ? styles[newProductLocation[10].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB4} ${!newProductLocation[11].empty ? styles[newProductLocation[11].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB5} ${!newProductLocation[12].empty ? styles[newProductLocation[12].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB6} ${!newProductLocation[13].empty ? styles[newProductLocation[13].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB7} ${!newProductLocation[14].empty ? styles[newProductLocation[14].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB8} ${!newProductLocation[15].empty ? styles[newProductLocation[15].color] : ''}`}/>
                            </>
                          ):(
                            <>                     
                              <img className={styles.img} src={palletRight} alt=''/>

                              <div className={`${styles.bottle} ${styles.bottleA1_right} ${!newProductLocation[0].empty ? styles[newProductLocation[0].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA2_right} ${!newProductLocation[1].empty ? styles[newProductLocation[1].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA3_right} ${!newProductLocation[2].empty ? styles[newProductLocation[2].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA4_right} ${!newProductLocation[3].empty ? styles[newProductLocation[3].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA5_right} ${!newProductLocation[4].empty ? styles[newProductLocation[4].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA6_right} ${!newProductLocation[5].empty ? styles[newProductLocation[5].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA7_right} ${!newProductLocation[6].empty ? styles[newProductLocation[6].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleA8_right} ${!newProductLocation[7].empty ? styles[newProductLocation[7].color] : ''}`}/>

                              <div className={`${styles.bottle} ${styles.bottleB1_right} ${!newProductLocation[8].empty ? styles[newProductLocation[8].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB2_right} ${!newProductLocation[9].empty ? styles[newProductLocation[9].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB3_right} ${!newProductLocation[10].empty ? styles[newProductLocation[10].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB4_right} ${!newProductLocation[11].empty ? styles[newProductLocation[11].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB5_right} ${!newProductLocation[12].empty ? styles[newProductLocation[12].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB6_right} ${!newProductLocation[13].empty ? styles[newProductLocation[13].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB7_right} ${!newProductLocation[14].empty ? styles[newProductLocation[14].color] : ''}`}/>
                              <div className={`${styles.bottle} ${styles.bottleB8_right} ${!newProductLocation[15].empty ? styles[productLocation[15].color] : ''}`}/>
                            </>
                          )}
                        </div>
                        <div className={styles.footer}>
                          {chunkedArray?.map((row: productColorsType[], rowIndex: number) => (
                              <div key={uuidv4()} style={{ display: 'flex', gap: '20px' }}>
                                  {row.map((item: productColorsType, itemIndex: number) => (
                                      <div key={uuidv4()} className={styles.footerItem}> <div className={`${styles.legend} ${styles[item.color]}`}/> {item.name} </div>
                                  ))}
                              </div>
                          ))}
                        </div>
                      </div>
                    }
                    {element?.details == 'product_labelling' && <div className={styles.labellingWrapper}>
                        <div className={styles.header}>
                          <div className={styles.insertionTitle}> <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth /> Labelling instructions </div>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <div style={{fontSize: '14px'}}>Left</div>
                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={handleSwitch} checked={showRight}/>
                            <div>Right</div>
                          </Stack>
                        </div>
                        <div className={styles.imageWrapper}>
                          {showRight == false ? (
                            <>                      
                              <img className={styles.img} src={palletLabelsLeft} alt=''/>
                                <div className={`${styles.labels} ${styles.left1} ${styles.column}`}>
                                  {chunkedLabels1?.map((chunk, index) => (
                                    <div key={index} className={styles.row}>
                                      {chunk?.map((label, index) => {
                                        return (
                                          <Tooltip
                                            key={index}
                                            title={<img key={index} src={label} width="120px" alt="" />}
                                            sx={{backgroundColor: 'rgba(255,255,255, 1)', padding: '10px', boxShadow: '3px 3px 10px rgba(0,0,0,0.5)'}}
                                            placement="bottom"
                                          >
                                            <img key={index} src={label} width="50px" alt="" />
                                          </Tooltip>
                                        );
                                      })}
                                    </div>
                                  ))}
                                </div>
                                <div className={`${styles.labels} ${styles.right1} ${styles.column}`}>
                                  {chunkedLabels2?.map((chunk, index) => (
                                    <div key={index} className={styles.row}>
                                      {chunk?.map((label, index) => {
                                        return (
                                          <Tooltip
                                            key={index}
                                            title={<img key={index} src={label} width="120px" alt="" />}
                                            sx={{backgroundColor: 'rgba(255,255,255, 1)', padding: '10px', boxShadow: '3px 3px 10px rgba(0,0,0,0.5)'}}
                                            placement="bottom"
                                          >
                                            <img key={index} src={label} width="50px" alt="" />
                                          </Tooltip>
                                        );
                                      })}
                                    </div>
                                  ))}
                                </div>
                            </>
                          ):(
                            <>                     
                              <img className={styles.img} src={palletLabelsRight} alt=''/>
                              <div className={`${styles.labels} ${styles.left2} ${styles.column}`}>
                                  {chunkedLabels2?.map((chunk, index) => (
                                    <div key={index} className={styles.row}>
                                      {chunk?.map((label, index) => {
                                        return (
                                          <Tooltip
                                            title={<img key={index} src={label} width="120px" alt="" />}
                                            sx={{backgroundColor: 'rgba(255,255,255, 1)', padding: '10px', boxShadow: '3px 3px 10px rgba(0,0,0,0.5)'}}
                                            placement="bottom"
                                          >
                                            <img key={index} src={label} width="50px" alt="" />
                                          </Tooltip>
                                        );
                                      })}
                                    </div>
                                  ))}
                                </div>
                              <div className={`${styles.labels} ${styles.right2} ${styles.column}`}>
                                  {chunkedLabels1?.map((chunk, index) => (
                                    <div key={index} className={styles.row}>
                                      {chunk?.map((label, index) => {
                                        return (
                                          <Tooltip
                                            title={<img key={index} src={label} width="120px" alt="" />}
                                            sx={{backgroundColor: 'rgba(255,255,255, 1)', padding: '10px', boxShadow: '3px 3px 10px rgba(0,0,0,0.5)'}}
                                            placement="bottom"
                                          >
                                            <img key={index} src={label} width="50px" alt="" />
                                          </Tooltip>
                                        );
                                      })}
                                    </div>
                                  ))}
                                </div>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  </div>
                </ListItem>
          ))}       
        </List>  
        <Snackbar // Display api call SUCCESS result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbar}
          variant='soft'
          color='success'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setSnackbar(false);
          }}
          endDecorator={
            <Button
              onClick={() => setSnackbar(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleCheck}/> Succesfully submitted !
        </Snackbar>
        <Snackbar // Display api call ERROR result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbarError}
          variant='soft'
          color='danger'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setSnackbarError(false);
          }}
          endDecorator={
            <Button
              onClick={() => setSnackbarError(false)}
              size="sm"
              variant="soft"
              color="danger"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleExclamation}/> The mass has not been succesfully submitted !
        </Snackbar>
      </div>
    </>
  );
}

export default ListCard