// This optional code is used to register a service worker.
// register() is not called by default.

import { subscribeToPushNotifications } from "./pushNotif/pushNotifications";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  console.log('In register')
  
  // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('./service-worker.js').then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }, function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      });
      //subscribeToPushNotifications();
    });
    // console.log('In condition if')
    // // The URL constructor is available in all browsers that support SW.
    // const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    // if (publicUrl.origin !== window.location.origin) {
    //   // Our service worker won't work if PUBLIC_URL is on a different origin
    //   // from what our page is served on. This might happen if a CDN is used to
    //   // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    //   return;
    // }

    // window.addEventListener('load', () => {
    //   const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    //   if (isLocalhost) {
    //     console.log('In localhost')
    //     // This is running on localhost. Let's check if a service worker still exists or not.
    //     checkValidServiceWorker(swUrl, config);
    //     console.log('In localhost, after checkValidServiceWorker')
    //     // Add some additional logging to localhost, pointing developers to the
    //     // service worker/PWA documentation.
    //     navigator.serviceWorker.ready.then(() => {
    //       console.log(
    //         'This web app is being served cache-first by a service ' +
    //           'worker. To learn more, visit https://cra.link/PWA'
    //       );
    //     });
    //   } else {
    //     // Is not localhost. Just register service worker
    //     registerValidSW(swUrl, config);
    //   }
    // });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      console.log('In checkValidServiceWorker')
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        console.log('No service worker found')
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}









// import Cookies from 'js-cookie';

// export default function serviceWorkerRegistration() {

//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', function() {
//       navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
//         // Registration was successful
//         console.log('ServiceWorker registration successful with scope: ', registration.scope);
//       }, function(err) {
//         // registration failed :(
//         console.log('ServiceWorker registration failed: ', err);
//       });
//     });
//   }
  
//   const enableNotifications = document.getElementById('enableNotifications');
  
//   // Check for service worker and push notification support
//   if ('serviceWorker' in navigator && 'PushManager' in window) {
//   enableNotifications.style.display = 'block';
  
//   enableNotifications.addEventListener('click', function() {
//   navigator.serviceWorker.ready.then(function(registration) {
//     // Request permission from the user
//     Notification.requestPermission().then(function(permission) {
//       if (permission === 'granted') {
//         registration.pushManager.subscribe({
//           userVisibleOnly: true, // Pushes are always shown to the user
//           applicationServerKey: urlBase64ToUint8Array('BEUZ6aSaqMGlaM1OrVc4is1GDHksbJnKBMOLwpWWCmhUdBpIf0NQbCTgJVyV_p3WDSmQjRupPgSeyCPohttO64Q')
//         }).then(function(subscription) {
//           // Subscription was successful
//           console.log('User is subscribed:', subscription);
  
//           // Extract subscription object details
//           console.log('Endpoint:', subscription);
//           const subscriptionDetails = {
//             endpoint: subscription.endpoint,
//             expirationTime: subscription.expirationTime,
//             keys: {
//               p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
//               auth: arrayBufferToBase64(subscription.getKey('auth'))
//             }
//           };
          
//           // Send the subscription details to your server
//           notifApiCall('notifications/push-subscribe', 'POST', JSON.stringify(subscriptionDetails));
          
//         }).catch(function(err) {
//           console.log('Failed to subscribe the user: ', err);
//         });
//       }
//     });
//   });
//   });
//   } else {
//   console.log('Push messaging is not supported');
//   enableNotifications.style.display = 'none';
//   }
//   document.getElementById('testPush').addEventListener('click', function() {
//   console.log("testPush")
//   if (navigator.serviceWorker.controller) {
//     console.log('Service worker is active');
//     setTimeout(() => {
//       navigator.serviceWorker.controller.postMessage({
//         type: 'simulatePush'
//       });
//     }, 5000);
//   } else {
//     console.log('Service worker is not active');
//   }
//   });
  
//   // Utility function to convert the VAPID public key to a Uint8Array
//   function urlBase64ToUint8Array(base64String) {
//   const padding = '='.repeat((4 - base64String.length % 4) % 4);
//   const base64 = (base64String + padding)
//   .replace(/\-/g, '+')
//   .replace(/_/g, '/');
//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);
//   for (let i = 0; i < rawData.length; ++i) {
//   outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
//   }
  
//   function arrayBufferToBase64(buffer) {
//   let binary = '';
//   const bytes = new Uint8Array(buffer);
//   const len = bytes.byteLength;
//   for (let i = 0; i < len; i++) {
//   binary += String.fromCharCode(bytes[i]);
//   }
//   return window.btoa(binary);
//   }
// }


// async function notifApiCall(endpoint, method, body) {
//   try {
//     const token = Cookies.get('accessToken');
//     const response = await fetch(`https://api.com/${endpoint}`, {
//       method: method,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//       body: body,
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch data');
//     }

//     const data = await response.json();
//     console.log('Data from API:', data);
//     return data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// }
