import React from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';

export function Users() {
  return (
    <MainLayout>
      <p>Users page</p>
    </MainLayout>
  );
}
