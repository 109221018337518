import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

// Labels icons 
import class_1_1 from '@/media/labels/class_1-1.png';
import class_1_2 from '../../../../../media/labels/class_1-2.png';
import class_1_3 from '../../../../../media/labels/class_1-3.png';
import class_1_4 from '../../../../../media/labels/class_1-4.png';
import class_1_5 from '../../../../../media/labels/class_1-5.png';
import class_1_6 from '../../../../../media/labels/class_1-6.png';
import class_2_1 from '../../../../../media/labels/class_2-1.png';
import class_2_2 from '../../../../../media/labels/class_2-2.png';
import class_2_3 from '../../../../../media/labels/class_2-3.png';
import class_3 from '@/media/labels/class_3.png';
import class_4_1 from '../../../../../media/labels/class_4-1.png';
import class_4_2 from '../../../../../media/labels/class_4-2.png';
import class_4_3 from '../../../../../media/labels/class_4-3.png';
import class_5_1 from '../../../../../media/labels/class_5-1.png';
import class_5_2 from '../../../../../media/labels/class_5-2.png';
import class_6_1 from '../../../../../media/labels/class_6-1.png';
import class_8 from '../../../../../media/labels/class_8.png';

export function productOgcColTable(props_width: number): GridColDef[] {
  let width = props_width;

  // const labelsRenderCell = (params: GridRenderCellParams<{ prim_label: string, sec_label: string }>) => {
  //   // console.log(params.row.labels)
  //   var prim_icon: string | undefined;
  //   var sec_icon: string | undefined;
  //   console.log('params : ', params)
  //   if (params.row.primary_class != '---' && params.row.primary_class != undefined) {
  //     switch (params.row.primary_class) {
  //       case '1.1':
  //         prim_icon = class_1_1;
  //         break;
  //       case '1.2':
  //         prim_icon = class_1_2;
  //         break;
  //       case '1.3':
  //         prim_icon = class_1_3;
  //         break;
  //       case '1.4':
  //         prim_icon = class_1_4;
  //         break;
  //       case '1.5':
  //         prim_icon = class_1_5;
  //         break;
  //       case '1.6':
  //         prim_icon = class_1_6;
  //         break;
  //       case '2.1':
  //         prim_icon = class_2_1;
  //         break;
  //       case '2.2':
  //         prim_icon = class_2_2;
  //         break;
  //       case '2.3':
  //         prim_icon = class_2_3;
  //         break;
  //       case '3':
  //         prim_icon = class_3;
  //         break;
  //       case '4.1':
  //         prim_icon = class_4_1;
  //         break;
  //       case '4.2':
  //         prim_icon = class_4_2;
  //         break;
  //       case '4.3':
  //         prim_icon = class_4_3;
  //         break;
  //       case '5.1':
  //         prim_icon = class_5_1;
  //         break;
  //       case '5.2':
  //         prim_icon = class_5_2;
  //         break;
  //       case '6.1':
  //         prim_icon = class_6_1;
  //         break;
  //       case '8':
  //         prim_icon = class_8;
  //         break;
  //       default:
  //         break;
  //     }
  //     if (params.row.subsidiary_class != '---' && params.row.subsidiary_class != undefined) {
  //       console.log(params.row.subsidiary_class)
  //       switch (params.row.subsidiary_class) {
  //         case '1.1':
  //           sec_icon = class_1_1;
  //           break;
  //         case '1.2':
  //           sec_icon = class_1_2;
  //           break;
  //         case '1.3':
  //           sec_icon = class_1_3;
  //           break;
  //         case '1.4':
  //           sec_icon = class_1_4;
  //           break;
  //         case '1.5':
  //           sec_icon = class_1_5;
  //           break;
  //         case '1.6':
  //           sec_icon = class_1_6;
  //           break;
  //         case '2.1':
  //           sec_icon = class_2_1;
  //           break;
  //         case '2.2':
  //           sec_icon = class_2_2;
  //           break;
  //         case '2.3':
  //           sec_icon = class_2_3;
  //           break;
  //         case '3':
  //           sec_icon = class_3;
  //           break;
  //         case '4.1':
  //           sec_icon = class_4_1;
  //           break;
  //         case '4.2':
  //           sec_icon = class_4_2;
  //           break;
  //         case '4.3':
  //           sec_icon = class_4_3;
  //           break;
  //         case '5.1':
  //           sec_icon = class_5_1;
  //           break;
  //         case '5.2':
  //           sec_icon = class_5_2;
  //           break;
  //         case '6.1':
  //           sec_icon = class_6_1;
  //           break;
  //         case '8':
  //           sec_icon = class_8;
  //           break;
  //         default:
  //           break;
  //       }
  //       console.log('prim_icon: ', prim_icon)
  //       return (
  //         <Box display="flex" gap="5px">
  //           <img src={prim_icon} width="35px" alt="" />
  //           <img src={sec_icon} width="35px" alt="" />
  //         </Box>
  //       );
  //     } else {
  //       return (
  //         <Box display="flex">
  //           <img src={prim_icon} width="35px" alt="" />
  //         </Box>
  //       );
  //     }
  //   } else {
  //     return (<div> {'---'} </div>);
  //   }
  // };

  const labelsRenderCell = (params: GridRenderCellParams<string[]>) => {
    // console.log(params.row.labels)
    var prim_icon: string | undefined;
    var sec_icon: string | undefined;
    let labels: string[] = [];
    // let length: number = params.row.hazards.length;
    if(params.row.hazards != undefined) {
      for (let i = 0; i < params.row.hazards.length; i++) {
        switch (params.row.hazards[i]) {
          case '1.1':
            labels.push(class_1_1);
            break;
          case '1.2':
            labels.push(class_1_2);
            break;
          case '1.3':
            labels.push(class_1_3);
            break;
          case '1.4':
            labels.push(class_1_4);
            break;
          case '1.5':
            labels.push(class_1_5);
            break;
          case '1.6':
            labels.push(class_1_6);
            break;
          case '2.1':
            labels.push(class_2_1);
            break;
          case '2.2':
            labels.push(class_2_2);
            break;
          case '2.3':
            labels.push(class_2_3);
            break;
          case '3':
            labels.push(class_3);
            break;
          case '4.1':
            labels.push(class_4_1);
            break;
          case '4.2':
            labels.push(class_4_2);
            break;
          case '4.3':
            labels.push(class_4_3);
            break;
          case '5.1':
            labels.push(class_5_1);
            break;
          case '5.2':
            labels.push(class_5_2);
            break;
          case '6.1':
            labels.push(class_6_1);
            break;
          case '8':
            labels.push(class_8);
            break;
          default:
            break;
        }
      }
      return (
        <Box display="flex" gap="5px">
          {labels.map((label, index) => {
            return <img key={index} src={label} width="35px" alt="" />
          })}
        </Box>
      );
    } else {
      return (<div> {'---'} </div>);
    }
  };

  const subsidiaryRenderCell = (params: GridRenderCellParams<string[]>) => {
    if (params.row.subsidiary_class != '---') {
      return (
        <Box display="flex" gap="5px">
          {params.row.subsidiary_class.map((item: any, index: number) => {
            return <div key={index}> {item} {params.row.subsidiary_class.length > 1 ? ', ' : ''} </div>
          })}
        </Box>
      );
    } else {
      return (<div> {'---'} </div>);
    }
  }
    

  if (width >= 920) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'name', headerName: 'Name', flex: 1.2, sortable: true },
      { field: 'un', headerName: 'UN n°', flex: 0.5, sortable: true },
      { field: 'psn', headerName: 'Proper Shipping Name', flex: 2, sortable: true },
      { field: 'primary_class', headerName: 'Primary class', flex: 0.8, sortable: true },
      { field: 'subsidiary_class', headerName: 'Subsidiary class(es)', flex: 0.8, sortable: true, renderCell: subsidiaryRenderCell },
      { field: 'packing_group', headerName: 'Packing group', flex: 0.8, sortable: true },
      { field: 'labels', headerName: 'Hazard labels', flex: 1, sortable: false, renderCell: labelsRenderCell },
    ];
  } else if (width >= 675) {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'name', headerName: 'Name', flex: 1.2, sortable: true },
      { field: 'un', headerName: 'UN n°', flex: 0.5, sortable: true },
      { field: 'psn', headerName: 'Proper Shipping Name', flex: 2, sortable: true },
      { field: 'primary_class', headerName: 'Primary class', flex: 0.8, sortable: true },
      { field: 'packing_group', headerName: 'Packing group', flex: 0.8, sortable: true },
      { field: 'labels', headerName: 'Hazard labels', flex: 1, sortable: false, renderCell: labelsRenderCell },
    ];
  } else {
    return [
      { field: 'id', headerName: 'ID' },
      { field: 'name', headerName: 'Name', flex: 1.2, sortable: true },
      { field: 'un', headerName: 'UN n°', flex: 0.5, sortable: true },
      { field: 'primary_class', headerName: 'Primary class', flex: 0.8, sortable: true },
      { field: 'packing_group', headerName: 'Packing group', flex: 0.8, sortable: true },
      { field: 'labels', headerName: 'Hazard labels', flex: 1, sortable: false, renderCell: labelsRenderCell },
    ];
  }
}