import React from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';

import { GoodsList } from './components/goodsList/GoodsList';
import { NewOrder } from './components/newOrder/NewOrder';

import styles from './Ordering.module.scss';

export function Ordering() {
  return (
    <MainLayout>
      <div className={styles.body}>
        <div id={styles.bloc1}>
          <GoodsList />
        </div>
        <div id={styles.bloc2}>
          <NewOrder />
        </div>
      </div>
    </MainLayout>
  );
}
