import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';

import { mapOperationStatus, mapOperationType } from '@/utils/hubMapping';
import { ReactComponent as ArrowUpRight } from '../../../../../media/icons/fluent-arrow.svg';
import { type ExtendedGroundOperation } from './DetailedHubCard';

import styles from './DetailedHubCard.module.scss';
import { updateOngoingGroundHandlingOperation, updateOngoingGroundHandlingOperationStatus } from '@/app/stores/dataSlice/groundHandlingSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

type DeliveryTabProps = {
  props_width: number;
};

function compareDateTime(oldDate: Date, newDate: Date): [number, boolean] {
  // Return the absolut difference + true if in advance (negative) or false if late (positive)
  const deltaTime = Math.round((newDate.getTime() - oldDate.getTime()) / 1000 / 60);
  let advance = false;
  if(deltaTime >= 0) {advance = false};
  if(deltaTime < 0) {advance = true};

  return [Math.abs(deltaTime), advance];
}

export function hubDeliveryColTab(props: DeliveryTabProps): GridColDef[] {
  let width = props.props_width;
  // TODO: using w to handle responsive design after finishing with the functionality

  const TimeRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    const localDate = new Date(params.row?.scheduledGroundHandlingTime ?? '');
    const latestDate = new Date(params.row?.groundHandlingStartTime ?? '');
    const deltaTime = compareDateTime(localDate, latestDate);
    if (isNaN(localDate.getTime())) return <Box display="flex">---</Box>;
    return (
      <Box display="flex" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }} className={styles.textCell}>
        {/* {deltaTime[0] < 60 ? (
          <>
            {localDate.toLocaleTimeString([], {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </>
        ):(
          <div className={deltaTime[1] ? styles.timeAdvance : styles.timeLate}>
            {latestDate.toLocaleTimeString([], {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        )} */}
        {localDate.toLocaleTimeString([], {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit',
            })}
        {deltaTime[0] != 0 && deltaTime[0] < 60 && 
          <div className={deltaTime[1] ? styles.inAdvance : styles.late}>
            {deltaTime[1] && <> -{deltaTime[0].toString()}</>}
            {!deltaTime[1] && <> +{deltaTime[0].toString()}</>}
          </div>
        }
        {/* {props.props_width} */}
      </Box>
    );
  };

  const FromRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    return (
      <Box display="flex" className={styles.textCell}>
        {params.row?.otherHubDetail?.name ?? '---'}
      </Box>
    );
  };

  const OperationTypeRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    return (
      <Box display="flex" className={styles.textCell}>
        {mapOperationType(params.row?.groundHandlingType)}
      </Box>
    );
  };

  const OperationStatusRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    const localDate = new Date(params.row?.scheduledGroundHandlingTime ?? '');
    const latestDate = new Date(params.row?.groundHandlingStartTime ?? '');
    const deltaTime = compareDateTime(localDate, latestDate);
    const startTime = new Date(
          params.row?.groundHandlingStartTime ?? ''
        ).toLocaleTimeString([], {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });
    switch (params.row?.groundHandlingStatus) {
      case 'READY':
      case 'ADAPTED': {
        return (
          <Box
            display="flex"
            className={styles.textCell}
            sx={{gap: '5px'}}
          >
            {/* Now  */}
            {mapOperationStatus(params.row?.groundHandlingStatus)}
            {params.row?.groundHandlingStatus == 'READY' && deltaTime[0] < 60 ? (
              <></>
            ):(
              <div className={deltaTime[0] >= 60 ? (deltaTime[1] ? styles.timeAdvance : styles.timeLate) : ''}>
                {startTime}
              </div>
            )}
          </Box>
        );
      }
      default:
        return (
          <Box display="flex" className={styles.textCell}>
            {mapOperationStatus(params.row?.groundHandlingStatus)}
          </Box>
        );
    }
  };

  const DroneRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    return <Box display="flex">{params.row?.droneData?.callSign ?? '---'}</Box>;
  };

  const GroundActionRenderCell = (
    params: GridRenderCellParams<any, ExtendedGroundOperation>
  ) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleStartFlightPreparation = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        dispatch(
          updateOngoingGroundHandlingOperation({
            type: params.row?.groundHandlingType,
            startTime: params.row?.groundHandlingStartTime,
            scheduledTime: params.row?.scheduledGroundHandlingTime,
            orderId: params.row?.orderId,
            orderRef: params.row?.orderReference,
            droneId: params.row?.drone,
            packages: params.row?.packages,
            departureHubId: params.row?.otherHub,
          })
        )
        dispatch(
          updateOngoingGroundHandlingOperationStatus([undefined, 'ONGOING'])
        )
        e.stopPropagation();
        navigate(`/deliveries/groundHandling/${params.row?.groundHandlingType}`);
      },
      [dispatch, navigate, params.row?.groundHandlingType]
    );
    const handleContinueFlightPreparation = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        dispatch(
          updateOngoingGroundHandlingOperation({
            type: params.row?.groundHandlingType,
            startTime: params.row?.groundHandlingStartTime,
            scheduledTime: params.row?.scheduledGroundHandlingTime,
            orderId: params.row?.orderId,
            orderRef: params.row?.orderReference,
            droneId: params.row?.drone,
            packages: params.row?.packages,
            departureHubId: params.row?.otherHub,
          })
        )
        dispatch(
          updateOngoingGroundHandlingOperationStatus(['ONGOING', 'ONGOING'])
        )
        e.stopPropagation();
        navigate(`/deliveries/groundHandling/${params.row?.groundHandlingType}`);
      },
      [dispatch, navigate, params.row?.groundHandlingType]
    );
    
    let actionTitle: string|undefined = 'None';
    if (params.row?.groundHandlingStatus == 'READY') {actionTitle = 'Start'}
    else if (params.row?.groundHandlingStatus == 'ADAPTED') {actionTitle = 'Start'}
    else if (params.row?.groundHandlingStatus == 'STARTED') {actionTitle = 'Continue'}
    else if (params.row?.groundHandlingStatus == 'CANCELED') {actionTitle = 'Continue'}
    else if (params.row?.groundHandlingStatus == 'ON_TIME') {actionTitle = 'Start'}
    else if (params.row?.groundHandlingStatus == 'DONE') {actionTitle = 'None'}
    return (
      <Box display="flex">
        {params.row?.groundHandlingStatus === 'READY' && 
          <div
            className={`${styles.actionBtn} ${styles.active}`}
            onClick={handleStartFlightPreparation}
          >
            <span className={styles.textCell}> Start </span>
            <ArrowUpRight />
          </div>
        }
        {params.row?.groundHandlingStatus === 'STARTED' && 
          <div
            className={`${styles.actionBtn} ${styles.active}`}
            onClick={handleContinueFlightPreparation}
          >
            <span className={styles.textCell}> Continue </span>
            <ArrowUpRight />
          </div>
        }
        {(params.row?.groundHandlingStatus != 'READY' && params.row?.groundHandlingStatus != 'STARTED') && 
          <div
            className={`${styles.actionBtn} ${styles.inactive}`}
            onClick={()=>{}}
          >
            <span className={styles.textCell}> None </span>
            <ArrowUpRight />
          </div>
        }
        
        {/* <div
          className={`${styles.actionBtn} ${
            params.row?.groundHandlingStatus === 'READY' ||
            params.row?.groundHandlingStatus === 'STARTED'||
            params.row?.groundHandlingStatus === 'ADAPTED'||
            params.row?.groundHandlingStatus === 'ON_TIME'
              ? styles.active
              : styles.inactive
          }`}
          onClick={handlePrepareFlight}
        >
          <span className={styles.textCell}> {actionTitle} </span>
          <ArrowUpRight />
        </div> */}
      </Box>
    );
  };

  if (width > 850) {
    return [
      {
        field: 'groundHandlingStartTime',
        headerName: 'Time',
        renderCell: TimeRenderCell,
        flex: 0.8,
      },
      {
        field: 'from',
        headerName: 'From',
        renderCell: FromRenderCell,
        flex: 1,
      },
      {
        field: 'orderReference',
        headerName: 'No',
        flex: 1,
        sortable: true,
      },
      {
        field: 'drone',
        flex: 0.8,
        headerName: 'Aircraft',
        sortable: true,
        renderCell: DroneRenderCell,
      },
      {
        field: 'groundHandlingType',
        flex: 1.5,
        headerName: 'Ground operation type',
        sortable: true,
        renderCell: OperationTypeRenderCell,
      },
      {
        field: 'groundHandlingStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: OperationStatusRenderCell,
        sortable: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: GroundActionRenderCell,
      },
    ];
  } else if (width > 750) {
    return [
      {
        field: 'groundHandlingStartTime',
        headerName: 'Time',
        renderCell: TimeRenderCell,
        flex: 0.7,
      },
      {
        field: 'orderReference',
        headerName: 'No',
        flex: 1,
        sortable: true,
      },
      {
        field: 'drone',
        flex: 0.8,
        headerName: 'Aircraft',
        sortable: true,
        renderCell: DroneRenderCell,
      },
      {
        field: 'groundHandlingType',
        flex: 1.3,
        headerName: 'Ground operation type',
        sortable: true,
        renderCell: OperationTypeRenderCell,
      },
      {
        field: 'groundHandlingStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: OperationStatusRenderCell,
        sortable: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: GroundActionRenderCell,
      },
    ];
  } else if (width > 600) {
    return [
      {
        field: 'groundHandlingStartTime',
        headerName: 'Time',
        renderCell: TimeRenderCell,
        flex: 0.8,
      },
      {
        field: 'orderReference',
        headerName: 'No',
        flex: 0.8,
        sortable: true,
      },
      {
        field: 'groundHandlingType',
        flex: 1.3,
        headerName: 'Ground operation type',
        sortable: true,
        renderCell: OperationTypeRenderCell,
      },
      {
        field: 'groundHandlingStatus',
        headerName: 'Status',
        flex: 0.8,
        renderCell: OperationStatusRenderCell,
        sortable: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: GroundActionRenderCell,
      },
    ];
  } else {
    return [
      {
        field: 'groundHandlingStartTime',
        headerName: 'Time',
        renderCell: TimeRenderCell,
        flex: 0.8,
      },
      {
        field: 'groundHandlingType',
        flex: 1.7,
        headerName: 'Ground operation type',
        sortable: true,
        renderCell: OperationTypeRenderCell,
      },
      {
        field: 'groundHandlingStatus',
        headerName: 'Status',
        flex: 0.8,
        renderCell: OperationStatusRenderCell,
        sortable: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: GroundActionRenderCell,
      },
    ];
  }
}
