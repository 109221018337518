import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { containersAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { containerAPI } from '@/features/api/copApiEndpoints/containerAPI';

type Container = {
  id: string;
  name: string;
};

export type ContainerSlice = {
  containerList: Container[];
};

const initialState: ContainerSlice = {
  containerList: [],
};

const containerSlice = createSlice({
  name: 'containers',
  initialState,
  reducers: {
    resetContainerState: (state) => {
      state = initialState;
    },
    updateContainerList: (state, action: PayloadAction<Container[]>) => {
      state.containerList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      containerAPI.endpoints.getContainers.matchFulfilled,
      (state, action) => {
        state.containerList = containersAPI(action.payload);
      }
    );
  },
});

export const { resetContainerState, updateContainerList } = containerSlice.actions;

export default containerSlice.reducer;
