import React, { useState } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

// import { useAppSelector } from '@/app/hooks';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import OrderConfirmModal from '@/features/sharedComponents/Modal/OrderConfirmModal/OrderConfirmModal';

import { OrderHubPicker } from './components/orderHubPicker/OrderHubPicker';
import { OrderTimePicker } from './components/orderTimePicker/OrderTimePicker';

// Styles
import styles from './NewOrder.module.scss';

export function NewOrder() {
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  // const orderingState = useAppSelector((state => state.ordering));

  // Define IconButton props
  const handleClick = () => {
    // to be defined
    setIsShowConfirm(true);
  };

  const handleClose = () => {
    setIsShowConfirm(false);
  };

  return (
    <div className={`${styles.card_section} ${styles.newOrder}`}>
      <div className={styles.header_wrapper}>
        <div>Plan your delivery...</div>
        <IconButton
          icon={faPaperPlane}
          content="Place order"
          onClick={handleClick}
          // disabled={!orderingState.newOrder.payload}
        />
      </div>
      <div className={styles.body_wrapper}>
        <div className={styles.hub_picker}>
          <OrderHubPicker id="departure" />
          <OrderHubPicker id="arrival" />
        </div>
        <OrderTimePicker />
      </div>
      {isShowConfirm && (
        <OrderConfirmModal
          open={isShowConfirm}
          handleClose={handleClose}
          ordertype="normalOrder"
        />
      )}
    </div>
  );
}
