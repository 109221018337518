import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

// import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/joy';
import Modal from '@mui/material/Modal';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateError } from '@/app/mainSlice';
import { useGetContainersQuery } from '@/features/api/copApiEndpoints/containerAPI';
import { useGetHubsQuery } from '@/features/api/copApiEndpoints/hubAPI';
import { useGetOrdersQuery } from '@/features/api/copApiEndpoints/orderAPI';
import { useGetProductsQuery } from '@/features/api/copApiEndpoints/productAPI';

import { Sidebar } from '../Sidebar/Sidebar';
import { Topbar } from '../Topbar/Topbar';

import styles from './MainLayout.module.scss';
import { MobileSidebar } from '../Sidebar/MobileSidebar';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  // Fetch shared data to saved to redux state, tbd whether the fetching should be move to each page
  const { isLoading: productLoading, isError: productErr } = useGetProductsQuery({});
  const { isLoading: hubLoading, isError: hubErr } = useGetHubsQuery({});
  const { isLoading: containerLoading, isError: containerErr } = useGetContainersQuery({});
  const { isLoading: orderLoading, isError: orderErr } = useGetOrdersQuery({ limit: '100' });

  useEffect(() => {
    if (productErr || hubErr || containerErr || orderErr) {
      dispatch(updateError({ id: '1', value: false }));
    }
  }, [containerErr, dispatch, hubErr, orderErr, productErr]);
  
  const props3 = useSpring({
    // visibility: show ? 'visible' : 'hidden',
  });

  const mainState = useAppSelector((state) => state.mobileSidebar);


  return (
    <div>
      <div className={styles.main_layout} style={{display: mainState.showMobileMenu ? 'none' : ''}}>
        <Sidebar />
        <div className={styles.content_wrapper}>
          <div className={styles.header}>
            <Topbar />
          </div>
            <div className={styles.body}>{children}</div>
        </div>
      </div>
      {mainState.showMobileMenu && (
        <animated.div style={props3}>
          <MobileSidebar />
        </animated.div> 
      )}
      <Modal
        className={styles.modalWrapper}
        open={productLoading || hubLoading || orderLoading || containerLoading}
        aria-labelledby="isloading-modal"
        aria-describedby="isloading-modal"
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
          <CircularProgress variant='solid' color="primary" sx={{ '--CircularProgress-size': '50px' }} />
        </Box>
      </Modal>
    </div>
  );
};
