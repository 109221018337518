import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { useAppSelector } from '@/app/hooks';
import { hubType } from '@/app/stores/hubSlice';
import { useUpdateUserMutation } from '@/features/api/copApiEndpoints/userAPI';
import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { CompactHubCard } from './components/compactHubCard/CompactHubCard';
import { DetailedHubCard } from './components/detailedHubCard/DetailedHubCard';

import styles from './Hubs.module.scss';
import { updateDefaultHubId } from '@/app/mainSlice';
import { ReportIssueButton } from '@/features/sharedComponents/Buttons/ReportIssueButton/ReportIssueButton';

export function Hubs() {
  const hubState = useAppSelector((state) => state.hub);
  const whoAmI = useAppSelector((state) => state.main.whoAmI);
  const groundHandlingState = useAppSelector((state) => state.groundHandling);

  // TODO: update default pinned hub from user info (next deployment of the API)
  const [pinnedHub, setPinnedHub] = useState(() => {
    if (whoAmI?.defaultHubId) {
      const toPinHub = hubState.hubList.find((hub) => hub.id == whoAmI?.defaultHubId);
      if (toPinHub == undefined) {
        return hubState.hubList[0]
      } else {
        return toPinHub
      }
    } else {
      return hubState.hubList[0]
    }
    // whoAmI?.defaultHubId
    //   ? hubState.hubList.find((hub) => hub.id == whoAmI?.defaultHubId) ?? hubState.hubList[0]
    //   : hubState.hubList[0]
  });
  // console.log('pinnedHub: ', pinnedHub.id)
  // console.log(Date.now())

  useEffect(() => {
    if (whoAmI?.defaultHubId) {
      const toPinHub = hubState.hubList.find((hub) => hub.id == whoAmI?.defaultHubId);
      if (toPinHub == undefined) {
        setPinnedHub(hubState.hubList[0])
      } else {
        setPinnedHub(toPinHub)
      }
    } else {
      setPinnedHub(hubState.hubList[0])
    }
  }, [whoAmI]);

  const [updateCurrentUser] = useUpdateUserMutation({});

  const compactHubs = hubState.hubList.filter((hub) => hub.id !== pinnedHub.id);
  const updatePinnedHub = (selectedHub: hubType) => {
    if (!whoAmI) return;
    setPinnedHub(selectedHub);
    updateDefaultHubId(selectedHub.id);
    updateCurrentUser({
      userId: whoAmI.id,
      updateUserInfo: {
        defaultHubId: selectedHub.id,
      }
    });
  };

  return (
    <MainLayout>
      <div className={styles.body}>
        <div className={styles.functionBar}>
          <ReportIssueButton type='Hub'/>
        </div>
        {pinnedHub && <DetailedHubCard hub={pinnedHub} />}
        {compactHubs.length > 0 ? (
          <motion.div id={styles.bloc2} layout>
            {compactHubs.map((compactHub) => (
              <CompactHubCard
                key={compactHub.id}
                hub={compactHub}
                setPinnedHub={updatePinnedHub}
              />
            ))}
          </motion.div>
        ) : null}
        {/* TODO: Add an empty hub screen here */}
      </div>
    </MainLayout>
  );
}
