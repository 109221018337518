import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './OverviewCard.module.scss';
import { ReactComponent as LogoIcon } from '@/media/adlc_logo_icon_w.svg';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles,
  } from 'react-circular-progressbar';
  import "react-circular-progressbar/dist/styles.css";


type OverviewCardProps = {
  title: string;
  currentTask: number;
  amountTasks: number;
  status: 'previous' | 'current' | 'next';
  onClick: () => void;
};

const OverviewCard: React.FC<OverviewCardProps> = ({ title, currentTask, amountTasks, status, onClick }) => {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? 'scale(1.03)' : 'scale(1)',
    boxShadow: show
      ? '0 10px 25px rgb(0 0 0 / 40%)'
      : '0 2px 10px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
  });

  return (
    <animated.div
      className={`
        ${styles.card} 
        ${status === 'current' ? styles.card_current : (status === 'previous' ? styles.card_previous : styles.card_next)}`}
      style={status === 'current' ? props3 : undefined}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
      onClick={onClick}
    >
        <div className={styles.element_header}>
            {status === 'current' && <LogoIcon width='20px' style={{position: 'relative', top: '-106px', marginRight: '10px'}}/>}
            {title}
        </div>
          <>
            {status === 'current' ? (
              <div className={styles.element_body}>
                <> Ongoing </>
                <div className={styles.progress}>
                  <CircularProgressbar
                      value={currentTask > 1 ? Math.round(((currentTask-1)/amountTasks)*100) : 0}
                      text={`${currentTask > 1 ? Math.round(((currentTask-1)/amountTasks)*100) : 0}%`}
                      styles={buildStyles({
                          textColor: "white",
                          pathColor: "white",
                          trailColor: "rgb(93, 140, 207)",
                      })}
                  />
                </div>
              </div>
            ):(
              <>
                {status === 'previous' ? (
                  <div className={styles.element_body}>
                    <> Completed </>
                    <div className={styles.progress}>
                      <CircularProgressbar
                        value={100}
                        text={`100%`}
                        styles={buildStyles({
                            textColor: "white",
                            pathColor: "white",
                            trailColor: "rgb(93, 140, 207)",
                        })}
                      />
                    </div>
                  </div>
                ):(
                  <div className={styles.element_body}>
                    <> Next </>
                    <div className={styles.progress}>
                      <CircularProgressbar
                        value={0}
                        text={`0%`}
                        styles={buildStyles({
                            textColor: "black",
                            pathColor: "rgb(6, 95, 217)",
                            trailColor: "rgb(162, 162, 162)",
                        })}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
    </animated.div>
  );
};

export default OverviewCard;
