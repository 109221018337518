import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';



// InitialState type
export type mobileSidebarState = {
  showMobileMenu: boolean,
}

const initialState: mobileSidebarState = {
  showMobileMenu: false,
};


export const mobileSidebarSlice = createSlice({
  name: 'mobileSidebar',
  initialState,
  reducers: {
    updateShowMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.showMobileMenu = action.payload;
    },
  }
});

export const { updateShowMobileMenu } = mobileSidebarSlice.actions;

export default mobileSidebarSlice.reducer;