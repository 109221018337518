import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY || '',
    knownAuthorities: [process.env.REACT_APP_AUTHORITYDOMAIN || ''],
    redirectUri: process.env.REACT_APP_REDIRECT_URL || '',
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL || '',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPE || ''],
};

export const msalInstance = new PublicClientApplication(msalConfig);
