import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong,
  faLocationDot,
  faCircleDot,
} from '@fortawesome/free-solid-svg-icons';

// Styles
import styles from './DeliveryCard.module.scss';

export type DeliveryCardProps = null | {
  id: string;
  no: string[];
  departure: string; // max 14 characters
  arrival: string; // max 14 characters
  remaining_time: string | undefined;
  progress: number;
  status: string | undefined;
};

export function DeliveryCard(props: DeliveryCardProps) {
  // Get component width dynamically
  const ref = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);

  useEffect(() => {
    if (ref) {
      if (ref.current != null) {
        const handleResize = () => {
          setW(ref.current!.offsetWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }
  }, []);

  if (props === null) {
    return <div className={styles.card_del}>No delivery !</div>;
  } else {
    const { no, departure, arrival, remaining_time, progress } = props;

    const progress_px: number = progress * (w / 100); // TO ADAPT IF PROGRESS BAR SIZE RELATIVE TO PARENT ELEMENT CHANGES

    let displayRemainingTime = true;
    if(props.status == 'Landed') { displayRemainingTime = false};
    if(props.status == 'Unloading') { displayRemainingTime = false};


    return (
      <div className={styles.card_del}>
        <div className={styles.header_wrapper}>
          <div className={styles.header_reference}>{no != undefined && no[0]}</div>
          <div className={styles.header_eta}>
            {displayRemainingTime ? (
              <>
                <div className={styles.field_name}>Arrive in</div>
                <div className={styles.field_content}>
                  {remaining_time
                    ? 
                    <>
                      {Math.floor(Number(remaining_time)) < 60
                        ? `${Math.round(Number(remaining_time) % 60)}min`
                        : `${Math.floor(Number(remaining_time) / 60)}h${Math.round(Number(remaining_time) % 60)}min`}
                    </>
                    : 'Unknown'}
                </div>
              </>
            ):(
              <div className={styles.landed}>Landed</div>
            )}
          </div>
        </div>
        <div className={styles.body_wrapper}>
          <div className={styles.body_item}>
            <div className={styles.field_name}>
              <FontAwesomeIcon icon={faCircleDot} size="sm" />
            </div>
            <div className={styles.field_content}>
              <div className={styles.content_name}>{departure}</div>
            </div>
          </div>
          <FontAwesomeIcon icon={faArrowRightLong} size="xs" />
          <div className={styles.body_item}>
            <div className={styles.field_name}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <div className={styles.field_content}>
              <div className={styles.content_name}>{arrival}</div>
            </div>
          </div>
        </div>
        <div className={styles.footer_wrapper}>
          <div ref={ref} className={styles.progressbar_wrapper}>
            <div
              className={
                styles.progressbar_content
              } /*onLoad={() => progressbarUpdate(progress)}*/
            >
              <div style={{ width: progress_px }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
