import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  removeGoodsListItem,
  updateGoodsListItem,
} from '@/app/stores/dataSlice/goodsListSlice';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import AutocompleteSI, {
  InputWrapper,
} from '../../../../../sharedComponents/Inputs/AutocompleteSI/AutocompleteSI';

// Styles
import styles from './GoodsCard.module.scss';

export type GoodsCardProps = {
  id: number;
  title: string;
};

export function GoodsCard(props: GoodsCardProps) {
  const productState = useAppSelector((state) => state.product);
  const containerState = useAppSelector((state) => state.container);

  const goodsListState = useAppSelector((state) => state.goodsList);
  const dispatch = useAppDispatch();

  const { id, title } = props;

  const deleteCard = () => {
    dispatch(removeGoodsListItem(id));
  };

  const goodsCardList = goodsListState.productCardList;

  return (
    <div className={styles.card_goods}>
      {goodsCardList.length > 1 && (
        <div className={styles.card_menu}>
          <div onClick={deleteCard} className={styles.button_card_delete}>
            <FontAwesomeIcon icon={faMinus} size="xs" />
          </div>
        </div>
      )}
      <div
        className={`${
          goodsCardList.length > 1
            ? styles.card_wrapper
            : styles.first_card_wrapper
        }`}
      >
        <div className={styles.header_wrapper}>{title}</div>
        <div className={styles.body_wrapper}>
          <div className={styles.body_item}>
            <div>Name</div>
            <AutocompleteSI
              autocompleteParam={{
                id: 'customized-hook-single-name-input',
                multiple: false,
                options: productState.productList,
                getOptionLabel: (option) => option.name || '',
                getOptionDisabled: undefined,
              }}
              setInputValue={(inputValue: productType_ogc) => {
                dispatch(
                  updateGoodsListItem({
                    id: id,
                    payloadType: 'product',
                    payloadItem: inputValue,
                  })
                );
              }}
            />
          </div>
          <div className={styles.body_item}>
            <div>Container</div>
            <AutocompleteSI
              autocompleteParam={{
                id: 'customized-hook-single-dimension-input',
                multiple: false,
                options: containerState.containerList,
                getOptionLabel: (option) => option.name,
                getOptionDisabled: undefined,
              }}
              setInputValue={(inputValue: containerType_ogc) => {
                dispatch(
                  updateGoodsListItem({
                    id: id,
                    payloadType: 'container',
                    payloadItem: inputValue,
                  })
                );
              }}
            />
          </div>
          <div className={styles.body_item}>
            <div>Quantity</div>
            <InputWrapper>
              <input
                type="number"
                min="0"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.target.valueAsNumber < 0 ||
                    Number.isNaN(e.target.valueAsNumber)
                  ) {
                    e.target.value = '';
                    dispatch(
                      updateGoodsListItem({
                        id: id,
                        payloadType: 'quantity',
                        payloadItem: 0,
                      })
                    );
                  } else {
                    dispatch(
                      updateGoodsListItem({
                        id: id,
                        payloadType: 'quantity',
                        payloadItem: e.target.valueAsNumber,
                      })
                    );
                  }
                }}
              />
            </InputWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}
