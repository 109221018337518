import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { productType_ogc } from '@/app/stores/productSlice';

import styles from './ProductCardModal.module.scss';
// Labels icons 
import class_1_1 from '@/media/labels/class_1-1.png';
import class_1_2 from '@/media/labels/class_1-2.png';
import class_1_3 from '@/media/labels/class_1-3.png';
import class_1_4 from '@/media/labels/class_1-4.png';
import class_1_5 from '@/media/labels/class_1-5.png';
import class_1_6 from '@/media/labels/class_1-6.png';
import class_2_1 from '@/media/labels/class_2-1.png';
import class_2_2 from '@/media/labels/class_2-2.png';
import class_2_3 from '@/media/labels/class_2-3.png';
import class_3 from '@/media/labels/class_3.png';
import class_4_1 from '@/media/labels/class_4-1.png';
import class_4_2 from '@/media/labels/class_4-2.png';
import class_4_3 from '@/media/labels/class_4-3.png';
import class_5_1 from '@/media/labels/class_5-1.png';
import class_5_2 from '@/media/labels/class_5-2.png';
import class_6_1 from '@/media/labels/class_6-1.png';
import class_8 from '@/media/labels/class_8.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function ProductCardModal({
    product,
    open,
    handleClose,
  }: {
    product: productType_ogc;
    open: boolean;
    handleClose: () => void;
  }) {

    let labels: string[] = [];
    if(product.hazards != undefined) {
        for (let i = 0; i < product.hazards.length; i++) {
          switch (product.hazards[i]) {
            case '1.1':
              labels.push(class_1_1);
              break;
            case '1.2':
              labels.push(class_1_2);
              break;
            case '1.3':
              labels.push(class_1_3);
              break;
            case '1.4':
              labels.push(class_1_4);
              break;
            case '1.5':
              labels.push(class_1_5);
              break;
            case '1.6':
              labels.push(class_1_6);
              break;
            case '2.1':
              labels.push(class_2_1);
              break;
            case '2.2':
              labels.push(class_2_2);
              break;
            case '2.3':
              labels.push(class_2_3);
              break;
            case '3':
              labels.push(class_3);
              break;
            case '4.1':
              labels.push(class_4_1);
              break;
            case '4.2':
              labels.push(class_4_2);
              break;
            case '4.3':
              labels.push(class_4_3);
              break;
            case '5.1':
              labels.push(class_5_1);
              break;
            case '5.2':
              labels.push(class_5_2);
              break;
            case '6.1':
              labels.push(class_6_1);
              break;
            case '8':
              labels.push(class_8);
              break;
            default:
              break;
          }
        }
    }

    const generalInfo = (({ id, un, psn, primary_class, subsidiary_class, packing_group }) => ({
        id,
        un,
        psn,
        primary_class,
        subsidiary_class,
        packing_group,
    }))(product);

    return (
        <Modal
            className={styles.modalWrapper}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={handleClose}
        >
            <div className={styles.cardModal}>
                <div className={styles.header}>
                    <div> {product.name} </div>
                    <div className={styles.close_icon} onClick={handleClose} >
                        <FontAwesomeIcon size='1x' icon={faXmark}/>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.labelWrapper}>
                        {labels.map((label, index) => {
                            return <img key={index} src={label} width="100px" alt="" />
                        })}
                    </div>
                    <div className={styles.info_card}>
                        {/* <div className={styles.info_card_header}>
                            Product information
                        </div> */}
                        <div className={styles.info_card_content}>
                        {Object.entries(generalInfo).map(([field, value]) => (
                            <ProductDetails key={field} field={field} value={value} />
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


function ProductDetails({ field, value }: { field: string; value: string | string[] }) {
    let displayedField = field;
    if (field =='psn') {displayedField = 'Proper Shipping Name'}
    else if (field == 'un') {displayedField = 'UN #'}
    else if (field == 'primary_class') {displayedField = 'Primary class'}
    else if (field == 'subsidiary_class') {displayedField = 'Subsidiary class'}
    else if (field == 'packing_group') {displayedField = 'Packing Group'}
    else if (field == 'product3') {displayedField = 'product 3'}
  
      return (
        <div className={styles.itemDetail}>
          <div className={styles.itemKey}>{displayedField} </div>
          <div className={styles.itemValue}>{value}</div>
        </div>
      );
  }