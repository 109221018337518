import { useEffect, useState } from 'react';

import { ReactComponent as Clock } from '@/media/icons/clock.svg';

import styles from './DigitalClock.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export function DigitalClock() {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      if (now.getMinutes() !== time.getMinutes()) {
        setTime(now);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [time]);

  return (
    <div className={styles.digital_clock_wrapper}>
      <div className={styles.clock_title}>
        <FontAwesomeIcon icon={faClock} />
        <p>TIME</p>
      </div>
      <div className={styles.clock_time}>
        {time.getHours().toString().padStart(2, '0')}:
        {time.getMinutes().toString().padStart(2, '0')}
      </div>
    </div>
  );
}
