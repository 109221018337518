import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { DeliveryCard } from '@/features/sharedComponents/Cards/DeliveryCard/DeliveryCard';
import { sortComingDelivery } from '@/features/functions/sortComingDelivery';

// Styles
import styles from './CardList.module.scss';

export function CardList() {
  const deliveryState = useAppSelector((state) => state.delivery);

  const cardList = sortComingDelivery(deliveryState.comingDeliveries);

  return (
    <div className={styles.card_list}>
      {cardList.slice(0, 5).map((comingDelivery, idx) => (
        <div key={comingDelivery.id} className={styles[`bloc${idx + 1}`]}>
          <DeliveryCard {...comingDelivery} />
        </div>
      ))}
    </div>
  );
}