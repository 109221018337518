import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import styles from './DateTimePicker.module.scss';

export type BasicDateTimePickerProps = {
  styleClass: {
    color: string,
    backgroundColor: string,
  } | {},
  setSelectedOption: () => void,
  setInputValue: (inputValue: any) => void | null,
}

export default function BasicDateTimePicker(props: BasicDateTimePickerProps) {
  const { styleClass, setSelectedOption, setInputValue } = props;

  const style = {
    ...styleClass,
    fontFamily: styles.fontFamily
  };

  const [value, setValue] = React.useState<Dayjs | null>(dayjs(Date()));

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isValid()) {
      setInputValue(newValue);
      setValue(newValue);
    }
  };

  return (
    <div onClick={setSelectedOption}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          value={value}
          onChange={handleChange}
          inputFormat="DD/MM/YYYY HH:mm"
          InputProps={{ style }}
          disablePast
          autoFocus
        />
      </LocalizationProvider>
    </div>
  );
}